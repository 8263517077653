import React from "react";
import { ListItem, ListItemIcon, ListItemText, Collapse, List, makeStyles } from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PeopleIcon from "@material-ui/icons/People";
import BarChartIcon from "@material-ui/icons/BarChart";
import LayersIcon from "@material-ui/icons/Layers";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import StarsIcon from "@material-ui/icons/Stars";
import CommentIcon from "@material-ui/icons/Comment";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { ExpandLess, ExpandMore, StarBorder } from "@material-ui/icons";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
export const SideMenuItems = () => {
  const classes = useStyles();
  const [masterDataOpen, setMasterDataOpen] = React.useState(true);
  const [commentsOpen, setCommentsOpen] = React.useState(true);
  const [curationOpen, setCurationOpen] = React.useState(true);

  const handleMasterDataClick = () => {
    setMasterDataOpen(!masterDataOpen);
  };
  const handleCommentsClick = () => {
    setCommentsOpen(!commentsOpen);
  };
  return (
    <div>
      <Link to="/users">
        <ListItem button>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
      </Link>
      <Link to="/orders">
        <ListItem button>
          <ListItemIcon>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText primary="Orders" />
        </ListItem>
      </Link>
      <Link to="/refunds">
        <ListItem button>
          <ListItemIcon>
            <KeyboardReturnIcon />
          </ListItemIcon>
          <ListItemText primary="Refunds" />
        </ListItem>
      </Link>
      <Link to="/products">
        <ListItem button>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary="Products" />
        </ListItem>
      </Link>
      <Link to="/ratings">
        <ListItem button>
          <ListItemIcon>
            <StarsIcon />
          </ListItemIcon>
          <ListItemText primary="Ratings" />
        </ListItem>
      </Link>
      <ListItem button onClick={handleCommentsClick}>
        <ListItemIcon>
          <CommentIcon />
        </ListItemIcon>
        <ListItemText primary="Comments" />
        {commentsOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={commentsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/profile-comments">
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Profile Comments" />
            </ListItem>
          </Link>
          <Link to="/product-comments">
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Product Comments" />
            </ListItem>
          </Link>
        </List>
      </Collapse>

      <ListItem button onClick={() => setCurationOpen(!curationOpen)}>
        <ListItemIcon>
          <CommentIcon />
        </ListItemIcon>
        <ListItemText primary="Curation" />
        {curationOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={curationOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/feed-page-curation">
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Product Feed Page" />
            </ListItem>
          </Link>
          <Link to="/feed-search-curation">
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Product Search Page" />
            </ListItem>
          </Link>
          <Link to="/stream-page-curation">
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Stream Feed Page" />
            </ListItem>
          </Link>
          <Link to="/search-page-curation">
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Search Page Content" />
            </ListItem>
          </Link>

         
        </List>
      </Collapse>
      <ListItem button onClick={handleMasterDataClick}>
        <ListItemIcon>
          <CommentIcon />
        </ListItemIcon>
        <ListItemText primary="Master Data" />
        {masterDataOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={masterDataOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to="/brands">
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Brands" />
            </ListItem>
          </Link>
          <Link to="/cities">
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Cities" />
            </ListItem>
          </Link>
          <Link to="/colours">
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Colours" />
            </ListItem>
          </Link>
          <Link to="/conditions">
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Conditions" />
            </ListItem>
          </Link>
          <Link to="/categories">
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Categories" />
            </ListItem>
          </Link>
          <Link to="/sub-categories">
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Sub Categories" />
            </ListItem>
          </Link>
        </List>
      </Collapse>
      <Link to="/discount-codes">
        <ListItem button>
          <ListItemIcon>
            <LayersIcon />
          </ListItemIcon>
          <ListItemText primary="Discount Codes" />
        </ListItem>
      </Link>
      <Link to="/seller-analytics">
        <ListItem button>
          <ListItemIcon>
            <LayersIcon />
          </ListItemIcon>
          <ListItemText primary="Seller Analytics" />
        </ListItem>
      </Link>
      <Link to="/admin-users">
        <ListItem button>
          <ListItemIcon>
            <SupervisorAccountIcon />
          </ListItemIcon>
          <ListItemText primary="Admin Users and Role" />
        </ListItem>
      </Link>
      <Link to="/users-role">
        <ListItem button>
          <ListItemIcon>
            <SupervisorAccountIcon />
          </ListItemIcon>
          <ListItemText primary="Users and Role" />
        </ListItem>
      </Link> 
      <Link to="/settings">
        <ListItem button>
          <ListItemIcon>
            <SupervisorAccountIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
      </Link>
    </div>
  );
};
