import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useServicesStore } from "../../hooks";
import moment from "moment";
import { FormDialog, SelectInputField } from "../../components";
import { toast } from "react-toastify";
import { Formik } from "formik";
import { OrderStates } from "../../types";

const OrderStateSelections: { [key: string]: string } = {
  dispatched: "Dispatched",
  delivered: "Received/Delivered",
  completed: "Completed",
  refund: "Refund",
};
interface IProps {
  match: {
    params: { id: string };
  };
}
interface IFormValues {
  id: string;
  orderState: string;
}

const OrderDetail = ({
  match: {
    params: { id },
  },
}: IProps) => {
  const { orderService } = useServicesStore();
  const [openDialog, setOpenDialog] = React.useState(false);

  const { data, isLoading, refetch } = useQuery("orders-details", () => orderService.getOrderById(id), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const { mutate: updateStatus } = useMutation(
    ({ id, orderState }: IFormValues) => orderService.updateOrder(id, { orderState }),
    {
      onSuccess: () => {
        toast.success("Order status successfully changed!");
        refetch();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );

  const UpdateForm = () => (
    <Formik
      initialValues={{ id, orderState: { label: "", value: "" } }}
      onSubmit={values => {
        setOpenDialog(false);
        updateStatus({ id, orderState: values.orderState.value });
      }}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit} style={{ margin: "10px 0" }}>
          <Box mb={15}>
            <SelectInputField
              name="orderState"
              queryKey="orderState"
              queryFunction={() => Promise.resolve(Object.entries(OrderStateSelections))}
              selectFunction={data => {
                return data.map((item: any) => ({
                  label: item[1],
                  value: item[0],
                }));
              }}
              queryFilter={val => ({ $or: [{ orderState: { $cont: val } }] })}
              selectProps={{ isMulti: false, placeholder: "Order Status..." }}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Box mr={2}>
              <Button variant="contained" color="primary" onClick={() => setOpenDialog(false)}>
                Cancel
              </Button>
            </Box>
            <Box mr={2}>
              <Button variant="contained" color="primary" type="submit" disabled={!dirty}>
                Confirm
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );

  const order = data?.item;
  return !isLoading ? (
    <>
      <FormDialog open={openDialog} onClose={() => setOpenDialog(false)}>
        Please select the status to change
        <UpdateForm />
      </FormDialog>
      <Box display="flex">
        <Box display="flex" flexDirection="column" width="50%">
          <Box display="flex">
            <Typography>Order Number: </Typography>
            <h3>{order?.orderNumber}</h3>
          </Box>

          <Box display="flex">
            <Typography>Amount: </Typography>
            <h3>{order?.orderAmountBrutto}</h3>
          </Box>

          <Box display="flex">
            <Typography>Seller: </Typography>
            <h3>{order?.sellerUserId}</h3>
          </Box>
          <Box display="flex">
            <Typography>Buyer: </Typography>
            <h3>{order?.buyerUserId}</h3>
          </Box>
          <Box display="flex">
            <Typography>Order Date: </Typography> <h3> {moment(order?.createdAt).format("DD.MM.YYYY")}</h3>
          </Box>
          <Box display="flex">
            <Typography>Status: </Typography>
            <h3>{OrderStates[order?.orderState as string]}</h3>
          </Box>
          <Box display="flex">
            <Typography>Product Name: </Typography>
            <Link to={`/products/productId`}>
              <h3>{order?.productName}</h3>
            </Link>
          </Box>
          <Box display="flex">
            <Typography>Product Description: </Typography>
            <h3>{order?.productDescription}</h3>
          </Box>
          <Box display="flex">
            <Typography>Product Image: </Typography>
            <img
              src={order?.productImage}
              alt="Order"
              style={{ width: "100%", maxHeight: 500, objectFit: "contain" }}
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Link to="/orders">
          <Box mr={2}>
            <Button variant="contained" color="primary">
              Cancel
            </Button>
          </Box>
        </Link>
        <Box mr={2}>
          <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
            Change Status
          </Button>
        </Box>
      </Box>
    </>
  ) : (
    <Box display="flex" justifyContent="center">
      <CircularProgress size="6rem" />
    </Box>
  );
};

export default OrderDetail;
