import { Button, Grid } from "@material-ui/core";
import { RequestQueryBuilder } from "@oktein/crud-request";
import React, { useEffect } from "react";
import { UseMutateFunction, useMutation } from "react-query";
import { toast } from "react-toastify";
import { ConfirmDialog } from "../../../../components";
import { useServicesStore } from "../../../../hooks";
import { DataGrid, GridColDef, GridSortModel, GridCellEditCommitParams } from "@material-ui/data-grid";
import { CurationStreams } from "../../../../types";

interface IProps {
  data?: CurationStreams | any | any[];
  isLoading: boolean;
  formLoading: () => void;
  refetchStreamCuration: () => void;
  updateStreamCurationSortOrder: UseMutateFunction<
    CurationStreams,
    unknown,
    { streamId: string | any; sortOrder: number | any },
    unknown
  >;
  qb: RequestQueryBuilder;
}
const StreamTable = ({ data, qb, isLoading, formLoading, refetchStreamCuration, updateStreamCurationSortOrder }: IProps) => {
  const { streamCurationService } = useServicesStore();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [tableLoad, setTableLoad] = React.useState(true);
  const [openDialogAll, setOpenDialogAll] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const { meta, items } = data || {};
  const columns: GridColDef[] = [
    {
      field: "itemName",
      headerName: "Item Name",
      minWidth: 150,
      flex: 1,
      renderCell: (prm: any) => {
        return <div>{prm.row.stream.itemName}</div>;
      },
    },
    {
      field: "streamDate",
      headerName: "Stream Date",
      minWidth: 150,
      flex: 1,
      renderCell: (prm: any) => {
        return (
          <span>
            {prm.row.stream.streamDate
              ? new Date(prm.row.stream.streamDate.toString()).toLocaleDateString() +
                " " +
                new Date(prm.row.stream.streamDate.toString()).toLocaleTimeString()
              : "-"}
          </span>
        );
      },
    },
    {
      field: "streamState",
      headerName: "Stream State",
      minWidth: 150,
      flex: 1,
      renderCell: (prm: any) => {
        return <span>{prm.row.stream.streamState}</span>;
      },
    },
    {
      field: "scheduleDate",
      headerName: "Schedule Date",
      minWidth: 150,
      flex: 1,
      renderCell: (prm: any) => {
        return <span>{prm.row.scheduleDate ? ( new Date(prm.row.scheduleDate.toString()).toLocaleDateString() +
          " " +
          new Date(prm.row.scheduleDate.toString()).toLocaleTimeString()): "-"}</span>;
      },
    },
    { field: "sortOrder", headerName: "Order",minWidth: 100, editable: true },
    {
      field: "id",
      headerName: "Actions",
      renderCell: (prm: any) => {
        return  <div className="blog-comments__avatar mr-3">
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setSelectedRowId(prm.value);
                setOpenDialog(true);
              }}
            >
              Remove
            </Button>
          </div>;
         
      }
    },
  ];
  const { mutate: deleteSearchStreamCuration } = useMutation(
    (id: string) => streamCurationService.deleteFeedStreamCuration(id),
    {
      onSuccess: () => {
        toast.success("Search stream curation deleted!");
        refetchStreamCuration();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);

  const [selectionModel, setSelectionModel] = React.useState<any[]>([]);

  const onDelete = () => {
    setOpenDialogAll(true);
  };
  const onReset = () => {
    setSelectionModel([]);
  };
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: "sortOrder",
      sort: "asc",
    },
  ]);

  useEffect(() => {
    qb.sortBy({ field: "sortOrder", order: "ASC" });
    qb.setPage(page).setLimit(pageSize);
    refetchStreamCuration();
  }, [pageSize, page, qb, refetchStreamCuration, items]);

  const [rowCountState, setRowCountState] = React.useState(meta?.totalItems || 0);
  React.useEffect(() => {
    setRowCountState((prevRowCountState: any) =>
      meta?.totalItems !== undefined ? meta?.totalItems : prevRowCountState,
    );
  }, [meta?.totalItems, setRowCountState]);

  const handleSortChange = (model: GridSortModel) => {
    if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
      setSortModel(model);
      delete qb.queryObject[qb.options.paramNamesMap?.sort as string];
      model.forEach(value => {
        qb.sortBy({ field: value.field, order: value.sort === "asc" ? "ASC" : "DESC" });
      });
      refetchStreamCuration();
    }
  };
  const handleCellEditCommit = ({ id, field, value }: GridCellEditCommitParams) => {
    updateStreamCurationSortOrder({ streamId: id, sortOrder: value });
  };
 
  return (
    <>
      <ConfirmDialog
        open={openDialog}
        onSave={() => {
          console.log(1);
          deleteSearchStreamCuration(selectedRowId);
          setOpenDialog(false);
          refetchStreamCuration();
          formLoading();
        }}
        onClose={() => setOpenDialog(false)}
        confirmText="Yes"
      >
        Are you sure you want to delete the stream curation?
      </ConfirmDialog>
      <ConfirmDialog
        open={openDialogAll}
        onSave={() => {
          streamCurationService.deleteAllFeedStreamCuration(selectionModel).then(res => {
            setTableLoad(false);
            refetchStreamCuration();
            toast.info(res.item.affected + " rows deleted");
            setOpenDialogAll(false);
            setTableLoad(true);

          });
        }}
        onClose={() => setOpenDialogAll(false)}
        confirmText="Yes"
      >
        Are you sure you want to delete all the stream curation?
      </ConfirmDialog>
      {!isLoading ? (
        <Grid>
          <Grid>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" color="primary" onClick={onDelete}>
                Delete selected rows
              </Button>
              <Button variant="contained" color="primary" onClick={onReset}>
                Reset selected rows
              </Button>
            </Grid>
          </Grid>
          <Grid>
            <div style={{ height: "1000px", width: "auto" }}>
            {tableLoad ? (
              <DataGrid
              rows={items}
              columns={columns}
              pageSize={pageSize}
              rowsPerPageOptions={[5, 25, 50, 100]}
              onSelectionModelChange={setSelectionModel}
              checkboxSelection
              selectionModel={selectionModel}
              rowCount={rowCountState}
              loading={isLoading}
              pagination
              page={page}
              paginationMode="server"
              onPageChange={newPage => setPage(newPage)}
              onPageSizeChange={newPageSize => setPageSize(newPageSize)}
              sortModel={sortModel}
              onSortModelChange={model => handleSortChange(model)}
              rowHeight={40}
              onCellEditCommit={handleCellEditCommit}
              autoHeight
            />
            ):(<div>Loading..</div>)}
            
              
            </div>
          </Grid>
        </Grid>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default StreamTable;
