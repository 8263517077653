import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Formik } from "formik";
import validationSchema from "./validationSchema";
import { AuthenticationService } from "../../services";
import { LoginRequest } from "../../types";
import { useMutation } from "react-query";
import { Redirect } from "react-router";
import packageJson from '../../../package.json';


const useStyles = makeStyles(theme => ({
  container: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logoImage: {
    width: "100px",
    marginBottom: theme.spacing(2),
  },
}));

const Login = () => {
  const classes = useStyles();
  const service = new AuthenticationService();
  const { mutate, isSuccess } = useMutation((values: LoginRequest) => service.login(values));

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <CssBaseline />
      <div className={classes.paper}>
        <img src="/logo.svg" alt="company logo" className={classes.logoImage} />
        {packageJson.version}
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={(values: LoginRequest, { setSubmitting }) => {
            mutate(values);
            setSubmitting(false);
          }}
          validateOnMount
        >
          {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, isValid }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                color="secondary"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                color="secondary"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting || !isValid}
              >
                Sign In
              </Button>
            </form>
          )}
        </Formik>
        {isSuccess && <Redirect to="/" />}
      </div>
    </Container>
  );
};

export default Login;
