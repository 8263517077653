import { Button, Grid } from "@material-ui/core";
import { RequestQueryBuilder } from "@oktein/crud-request";
import React, { useEffect } from "react";
import { UseMutateFunction, useMutation } from "react-query";
import { toast } from "react-toastify";
import { ConfirmDialog } from "../../../../components";
import { useServicesStore } from "../../../../hooks";
import { CurationProducts } from "../../../../types";
import { DataGrid, GridColDef, GridSortModel, GridCellEditCommitParams, } from '@material-ui/data-grid';




interface IProps {
  data?: CurationProducts | any | any[];
  isLoading: boolean;
  refetchProductCuration: () => void;
  updateFeedProductSortOrder: UseMutateFunction<
    CurationProducts,    unknown,
    { productId: string | any; sortOrder: number | any },
    unknown
  >;
  qb: RequestQueryBuilder;
}
const ProductTable = ({ data, qb, isLoading, refetchProductCuration, updateFeedProductSortOrder }: IProps) => {
  const { feedCurationService } = useServicesStore();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogAll, setOpenDialogAll] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const { meta, items } = data || {};
 
  const columns: GridColDef[] = [
    { field: "productId", headerName: "Prd Id",minWidth: 100, flex: 1  },
    { field: "itemName", headerName: "Product Name",minWidth: 150, flex: 1,
    renderCell: (prm: any) => (
      <span>{prm.row.product.itemName}</span>
      ),  
    },
    { field: "sellerFullName", headerName: "Seller Name",minWidth: 150,flex: 1,
    renderCell: (prm: any) => (
      <span>{prm.row.product.user.firstName} {prm.row.product.user.lastName} 
   </span>
      ),  
    },
    {
      field: "createdAt",
      width: 130,
      headerName: "Creation Date",
      renderCell: (prm: any) => (
      <span>{prm.value ? new Date(prm.value.toString()).toLocaleDateString() : "-"}</span>
      ),
    },
    { field: "productState", headerName: "Status",minWidth: 130,flex: 1,renderCell: (prm: any) => (
      <span>{prm.row.product.productState}</span>
      ),  },
    { field: "scheduleDate", headerName: "Publish Date",minWidth: 130,flex: 1,
    renderCell: (prm: any) => (
      <span>{prm.value ? new Date(prm.value.toString()).toLocaleDateString() : "-"}</span>
      ),  },
    {
      field: "productGifUrl",
      headerName: "Product Image",
      width: 165,
      renderCell: (prm: any) => {
        return (
          <div className="blog-comments__avatar mr-3">
            <img height="220" width="140" src={prm.row.product.productGifUrl} alt={prm.row.product.productGifUrl} />
          </div>)
      },
    },
    { field: "sortOrder", headerName: "Order",minWidth: 100, editable: true },
    {
      field: "id",
      headerName: "Actions",
      renderCell: (prm: any) => (
      <div className="blog-comments__avatar mr-3">
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => {
          setSelectedRowId(prm.value);
          setOpenDialog(true);
        }}
      >
        Remove
      </Button>
    </div>),
    },
  ];
  const { mutate: deleteSearchProductCuration } = useMutation(
    (id: string) => feedCurationService.deleteFeedProductCuration(id),
    {
      onSuccess: () => {
        toast.success("Search product curation deleted!");
        refetchProductCuration();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);

  const [selectionModel, setSelectionModel] = React.useState<any[]>([]);

  const onDelete = () => {
    setOpenDialogAll(true);
  };
  const onReset = () => {
    setSelectionModel([]);
  };
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'sortOrder',
      sort: 'asc',
    },
  ]);
  

  useEffect(() => {
    qb.sortBy({field: 'sortOrder', order: 'ASC'});
    qb.setPage(page).setLimit(pageSize);
    refetchProductCuration();
}, [pageSize, page, qb, refetchProductCuration, items]);

  const [rowCountState, setRowCountState] = React.useState(meta?.totalItems || 0);
  React.useEffect(() => {
    setRowCountState((prevRowCountState: any) =>
      meta?.totalItems !== undefined ? meta?.totalItems : prevRowCountState,
    );
  }, [meta?.totalItems, setRowCountState]);

  const handleSortChange = (model: GridSortModel) => {
      if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
          console.log(model);
          setSortModel(model);
          delete qb.queryObject[qb.options.paramNamesMap?.sort as string];
          model.forEach(value => {
          qb.sortBy({field:value.field,order: value.sort ===  'asc' ? 'ASC':'DESC'});
          });
          refetchProductCuration();
      
      }
  };
  const handleCellEditCommit = ({ id, field, value }: GridCellEditCommitParams) => {
        updateFeedProductSortOrder({ productId: id, sortOrder: value});
  };
  return (
    <>
      <ConfirmDialog
        open={openDialog}
        onSave={() => {
          deleteSearchProductCuration(selectedRowId);
          setOpenDialog(false);
          refetchProductCuration();
        }}
        onClose={() => setOpenDialog(false)}
        confirmText="Yes"
      >
        Are you sure you want to delete the product curation?
      </ConfirmDialog>
      <ConfirmDialog
        open={openDialogAll}
        onSave={() => {
          feedCurationService.deleteAllFeedProductCuration(selectionModel).then(res => {
            refetchProductCuration();
            toast.info(res.item.affected + " rows deleted");
            setOpenDialogAll(false);
          });
        }}
        onClose={() => setOpenDialogAll(false)}
        confirmText="Yes"
      >
        Are you sure you want to delete all the product curation?
      </ConfirmDialog>
      {!isLoading ? (
          <Grid>
            <Grid>
              <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button variant="contained" color="primary" onClick={onDelete}>
                  Delete selected rows
                </Button>
                <Button variant="contained" color="primary" onClick={onReset}>
                  Reset selected rows
                </Button>
              </Grid>
            </Grid>
            <Grid>
            <div style={{ height: "1000px", width: "auto" }}>
            <DataGrid
                    rows={items}
                    columns={columns}
                    pageSize={pageSize}
                    rowsPerPageOptions={[5,25,50,100]}
                    onSelectionModelChange={setSelectionModel}
                    checkboxSelection
                    selectionModel={selectionModel}
                    rowCount={rowCountState}
                    loading={isLoading}
                    pagination
                    page={page}
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    sortModel={sortModel}
                    onSortModelChange={(model) => handleSortChange(model)}
                    rowHeight={200}
                    onCellEditCommit={handleCellEditCommit}
                  />
              </div>
            </Grid>
          </Grid>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default ProductTable;
