import React from "react";
import { RequestQueryBuilder } from "@oktein/crud-request";
import { useMutation, useQuery } from "react-query";
import { useServicesStore } from "../../../hooks";
import { toast } from "react-toastify";
import StreamTable from "./Tables/StreamTable";
import StreamForm from "./Forms/StreamForm";

const useSearchProductCuration = () => {
  const qb = React.useMemo(() => RequestQueryBuilder.create({ page: 1, limit: 5 }), []);
  const { streamCurationService } = useServicesStore();

  const query = useQuery("search-stream-curation", () => streamCurationService.getFeedStreamCuration(qb), {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { mutate: deleteStreamCuration } = useMutation(
    (id: string) => streamCurationService.deleteSearchStreamCuration(id),
    {
      onSuccess: () => {
        toast.success("Search stream curation deleted!");
        query.refetch();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );

  const { mutate: updateStreamCurationOrder } = useMutation(
    (payload: { streamId: string; sortOrder: number }) =>
      streamCurationService.updateStreamSortOrder(payload.streamId, { sortOrder: payload.sortOrder }),
    {
      onSuccess: () => {
        toast.success("Search stream curation order updated!");
        query.refetch();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );

  const { mutate: saveStreamCuration } = useMutation(
    (payload: { streamId: string; sortOrder: number }) => streamCurationService.saveSearchStreamCuration(payload),
    {
      onSuccess: () => {
        query.refetch();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );

  return { query, queryBuilder: qb, deleteStreamCuration, updateStreamCurationOrder, saveStreamCuration };
};

const Stream = () => {
  const { query, queryBuilder, deleteStreamCuration, updateStreamCurationOrder, saveStreamCuration } =
    useSearchProductCuration();
  const [streamFormLoading, setStreamFormLoading] = React.useState(true);
 
  return (
    <>
      {!streamFormLoading && <div>Loading...</div>}
      {streamFormLoading && (
        <StreamForm
          onSubmit={payload => {
            saveStreamCuration(payload);
            setStreamFormLoading(false);
            setTimeout(() => {
              setStreamFormLoading(true);
            }, 2000);
          }}
        />
      )}
      <StreamTable
        data={query.data}
        isLoading={query.isLoading}
        onRowDelete={(id: string) => {
          deleteStreamCuration(id);
          setStreamFormLoading(false);
          setTimeout(() => {
            setStreamFormLoading(true);
          }, 2000);
        }}
        onRowUpdate={payload => updateStreamCurationOrder(payload)}
        onPaginationChange={({ pageSize, pageIndex }) => {
          queryBuilder.setPage(pageIndex).setLimit(pageSize);
          query.refetch();
        }}
        onSortChange={sortOptions => {
          delete queryBuilder.queryObject[queryBuilder.options.paramNamesMap?.sort as string];
          queryBuilder.sortBy(sortOptions);
          query.refetch();
        }}
      />
    </>
  );
};

export default Stream;
