import React from "react";
import { Services } from "../services/initializeServices";
interface IServicesContainer {
  children: React.ReactNode;
  data: Services;
}

const ServicesContext = React.createContext<Services | undefined>(undefined);

const _ServicesProvider = ({ children, data }: IServicesContainer) => {
  const memoizedData = React.useMemo(() => {
    return data;
  }, [data]);

  return <ServicesContext.Provider value={memoizedData}>{children}</ServicesContext.Provider>;
};

function useServices(): Services {
  const ctx = React.useContext(ServicesContext);
  if (!ctx) {
    throw new Error("useServices must be used within a ModulesContainer");
  }
  return ctx;
}

const ServicesProvider = React.memo(_ServicesProvider);

export { ServicesProvider, useServices as useServicesStore };
