import React from "react";
import { Column, Row } from "react-table";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import { Box, IconButton, TextField } from "@material-ui/core";
interface Props {
  value: any;
  row: Row;
  column: Column;
  onCellValueChange: (row: Row, column: Column, value: any) => void;
}

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row,
  column,
  onCellValueChange, // This is a custom function that we supplied to our table instance
}: Props) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);
  const [editMode, setEditMode] = React.useState(false);

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = (e: any) => {
    const inputValue = e.target.value < 0 ? (e.target.value = 1) : e.target.value;

    setValue(inputValue);
  };

  const onSubmit = () => {
    onCellValueChange(row, column, value);
    setEditMode(false);
  };

  const onCancel = () => {
    setValue(initialValue);
    setEditMode(false);
  };

  return !editMode ? (
    <span onClick={() => setEditMode(true)}>{value}</span>
  ) : (
    <Box display="flex">
      <TextField
        value={value}
        onChange={onChange}
        type="number"
        inputProps={{
          min: 1,
        }}
        color="secondary"
        style={{ width: "60px" }}
        onKeyPress={(event: React.KeyboardEvent) => {
          if (event.key === "Enter") {
            onSubmit();
          }
        }}
      />
      <IconButton color="secondary" onClick={onSubmit} style={{ padding: 0 }}>
        <CheckCircleOutlineIcon />
      </IconButton>
      <IconButton color="secondary" onClick={onCancel} style={{ padding: 0 }}>
        <CancelIcon />
      </IconButton>
    </Box>
  );
};

export default EditableCell;
