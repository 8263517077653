import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { isAuthenticated } from "../../utils/auth.utils";

export type IProps = Omit<RouteProps, "component"> & {
  component: React.ElementType;
};

const PrivateRoute = (props: IProps): React.ReactElement => {
  const { component: Component, ...rest } = props;

  const render = (props: any) => {
    if (!isAuthenticated()) {
      return <Redirect to={"/login"} />;
    }

    return <Component {...props} />;
  };

  return <Route {...rest} render={render} />;
};

export default PrivateRoute;
