import React from "react";
import { Button, Grid } from "@material-ui/core";
import { Formik } from "formik";
import { RequestQueryBuilder } from "@oktein/crud-request";
import { SelectInputField } from "../../../../components";
import { useServicesStore } from "../../../../hooks";
import { Products } from "../../../../types";

const ProductForm = ({
  onSubmit,
}: {
  onSubmit: ({ productId, sortOrder }: { productId: string; sortOrder: number }) => void;
}) => {
  const { productService } = useServicesStore();

  return (
    <Formik
      initialValues={{ product: null }}
      onSubmit={(values: any, { setSubmitting, resetForm }) => {
        setSubmitting(false);
        onSubmit({ productId: values.product.value, sortOrder: 1 });
        resetForm();
      }}
    >
      {({ handleSubmit, isValid, resetForm, submitForm, setFieldValue, values }) => (
        <form onSubmit={handleSubmit} style={{ marginBottom: "8px" }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SelectInputField
                name="product"
                queryKey="product"
                queryFunction={(qb: RequestQueryBuilder) => productService.getProducts(qb)}
                queryFilter={val => ({ $or: [{ itemName: { $cont: val } }] })}
                selectProps={{ isMulti: false, placeholder: "Select Product" }}
                selectFunction={(data: Products) => {
                  const { items = [] } = data || {};
                  const result = items.map(item => ({
                    label: item.itemName,
                    value: item.id,
                    item,
                  }));
                  return result;
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" type="submit" color="primary" disabled={!values.product}>
                Add
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default ProductForm;
