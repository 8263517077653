import { Button, DialogActions, makeStyles, TextField } from "@material-ui/core";
import { Formik } from "formik";
import { UseMutateFunction } from "react-query/types/react/types";
import { EditValues } from "..";
import { Color, CreateColor } from "../../../types";
import validationSchema from "./validationSchema";

const useStyles = makeStyles(theme => ({
  cancelButton: {
    textTransform: "none",
  },
  saveButton: {
    fontSize: "1.1em",
    paddingLeft: "1.2em",
    paddingRight: "1.2em",
    textTransform: "none",
  },
}));
interface IProps {
  createColour: UseMutateFunction<Color, unknown, CreateColor, unknown>;
  updateColour: UseMutateFunction<Color, unknown, EditValues, unknown>;
  dialogMode: string;
  selectedRow: Color;
  openDialog: React.Dispatch<React.SetStateAction<boolean>>;
}
const AddOrCreateColourForm = ({ createColour, updateColour, dialogMode, selectedRow, openDialog }: IProps) => {
  const initialValues = selectedRow?.id
    ? { name: selectedRow.name, sortOrder: selectedRow.sortOrder, hexCode: selectedRow.hexCode || "#000000" }
    : { name: "", sortOrder: 1, hexCode: "#262626" };
  const cs = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={values => {
        dialogMode === "create" ? createColour(values) : updateColour({ id: selectedRow.id, body: values });
        openDialog(false);
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Colour Name"
            name="name"
            color="secondary"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Sort Order"
            name="sortOrder"
            color="secondary"
            type="number"
            value={values.sortOrder}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Hex Code"
            name="hexCode"
            color="secondary"
            type="color"
            value={values.hexCode}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <DialogActions>
            <Button onClick={() => openDialog(false)} color="default" className={cs.cancelButton}>
              Cancel
            </Button>
            <Button
              className={cs.saveButton}
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting || !isValid || !dirty}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      )}
    </Formik>
  );
};

export default AddOrCreateColourForm;
