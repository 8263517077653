import React from "react";
import { Typography, Button, ButtonGroup, Box } from "@material-ui/core";
import { QuerySort, RequestQueryBuilder } from "@oktein/crud-request";
import { useMutation, useQuery } from "react-query";
import { useServicesStore } from "../../hooks";
import { Column } from "react-table";
import { FindsTable, ConfirmDialog, FormDialog } from "../../components";
import { toast } from "react-toastify";
import { City, CreateCity, UpdateCity } from "../../types/City";
import AddOrCreateCityForm from "./form";

const qb = RequestQueryBuilder.create({ page: 1, limit: 5 });
export interface EditValues {
  id: string;
  body: UpdateCity;
}
const CitiesPage = () => {
  const { cityService } = useServicesStore();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openCreateOrEditDialog, setOpenCreateOrEditDialog] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<City>();
  const [dialogMode, setDialogMode] = React.useState("create");

  const { data, isLoading, refetch } = useQuery("cities", () => cityService.getCities(qb), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const { mutate: createCity } = useMutation((body: CreateCity) => cityService.createCity(body), {
    onSuccess: () => {
      toast.success("City successfully created!");
      refetch();
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });
  const { mutate: updateCity } = useMutation((values: EditValues) => cityService.updateCity(values.id, values.body), {
    onSuccess: () => {
      toast.success("City successfully updated!");
      refetch();
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });
  const { mutate: deleteCity } = useMutation((id: string) => cityService.deleteCity(id), {
    onSuccess: () => {
      toast.success("City successfully deleted!");
      refetch();
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });

  const { meta, items } = data || {};

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "City Id",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Sort Order",
        accessor: "sortOrder",
      },

      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <ButtonGroup color="secondary" aria-label="outlined secondary button group">
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setDialogMode("update");
                setSelectedRow(original as City);
                setOpenCreateOrEditDialog(true);
              }}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                setSelectedRow(original as City);
                setOpenDeleteDialog(true);
              }}
            >
              Delete
            </Button>
          </ButtonGroup>
        ),
        disableSortBy: true,
      },
    ],
    [],
  );

  const handlePaginationChange = ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
    qb.setPage(pageIndex).setLimit(pageSize);
    refetch();
  };

  const handleSortChange = (sortOptions: QuerySort[]) => {
    delete qb.queryObject[qb.options.paramNamesMap?.sort as string];
    sortOptions.forEach(value => {
        qb.sortBy(value);
    });
    refetch();
  };

  return (
    <div>
      <ConfirmDialog
        open={openDeleteDialog}
        onSave={() => {
          deleteCity(selectedRow?.id as string);
          setOpenDeleteDialog(false);
        }}
        onClose={() => {
          setSelectedRow({} as City);
          setOpenDeleteDialog(false);
        }}
      >
        Are you sure to delete this city?
      </ConfirmDialog>
      <FormDialog
        open={openCreateOrEditDialog}
        onClose={() => {
          setDialogMode("create");
          setSelectedRow({} as City);
        }}
      >
        {dialogMode === "create" ? "Add a new City" : "Update City"}
        <AddOrCreateCityForm
          createCity={createCity}
          updateCity={updateCity}
          dialogMode={dialogMode}
          selectedRow={selectedRow as City}
          openDialog={setOpenCreateOrEditDialog}
        />
      </FormDialog>
      <Box display="flex" justifyContent="space-between" mb={2} onClick={() => setOpenCreateOrEditDialog(true)}>
        <Typography variant="h5">Cities</Typography>
        <Button color="secondary" variant="contained">
          Add New City
        </Button>
      </Box>

      {!isLoading && (
        <FindsTable
          columns={columns}
          data={items}
          meta={meta}
          onPaginationChange={handlePaginationChange}
          onSortChange={handleSortChange}
        />
      )}
    </div>
  );
};

export default CitiesPage;
