import React from "react";
import { Typography, Grid, Paper, makeStyles, createStyles, Theme } from "@material-ui/core";
import { StreamTable } from "./Tables";
import { StreamForm } from "./Forms";
import { useServicesStore } from "../../../hooks";
import { useMutation, useQuery } from "react-query";
import { RequestQueryBuilder } from "@oktein/crud-request";
import { toast } from "react-toastify";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100%",
      alignItems: "center",
    },
    root: {
      flexDirection: "column",
    },
    title: {
      padding: `${theme.spacing(1)}px 0;`,
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
  }),
);
const qb = RequestQueryBuilder.create({ page: 1, limit: 25 });

const StreamPageCuration = () => {
  const classes = useStyles();
  const { streamCurationService } = useServicesStore();
  const {
    data: streamData,
    isLoading: streamIsLoading,
    refetch: refetchStreamCuration,
  } = useQuery("stream-curation", () => streamCurationService.getStreamPageCuration(qb), {
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  const { mutate: updateStreamCurationSortOrder } = useMutation(
    (payload: { streamId: string; sortOrder: number }) =>
      streamCurationService.updateStreamCurationSortOrder(payload.streamId, { sortOrder: payload.sortOrder }),
    {
      onSuccess: () => {
        toast.success("Stream curation updated!");
        refetchStreamCuration();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );
  const [streamFormLoading, setStreamFormLoading] = React.useState(true);
  const formLoading = () => {
    setStreamFormLoading(false);
    setTimeout(() => {
      setStreamFormLoading(true);
    }, 2000);
  };

  return (
    <Grid item container>
      <Typography variant="h5" className={classes.title}>
        Stream Page Content
      </Typography>
      <Grid style={{ width: "130%" }}>
        <Grid item md="auto" component={Paper} className={classes.paper}>
          {!streamFormLoading && <div>Loading...</div>}
          {streamFormLoading && (
            <StreamForm refetchStreamCuration={refetchStreamCuration} streamFormLoading={streamFormLoading} />
          )}
            <StreamTable
              qb={qb}
              data={streamData}
              isLoading={streamIsLoading}
              formLoading={formLoading}
              refetchStreamCuration={refetchStreamCuration}
              updateStreamCurationSortOrder={updateStreamCurationSortOrder}
            />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StreamPageCuration;
