import { Button, DialogActions, makeStyles, TextField } from "@material-ui/core";
import { Formik } from "formik";
import { UseMutateFunction } from "react-query/types/react/types";
import { EditValues } from "..";
import { Brand, CreateBrand } from "../../../types/Brand";
import validationSchemaBrand from "../../../utils/validationSchemaBrand";

const useStyles = makeStyles(theme => ({
  cancelButton: {
    textTransform: "none",
  },
  saveButton: {
    fontSize: "1.1em",
    paddingLeft: "1.2em",
    paddingRight: "1.2em",
    textTransform: "none",
  },
}));
interface IProps {
  createBrand: UseMutateFunction<Brand, unknown, CreateBrand, unknown>;
  updateBrand: UseMutateFunction<Brand, unknown, EditValues, unknown>;
  dialogMode: string;
  selectedRow: Brand;
  openDialog: React.Dispatch<React.SetStateAction<boolean>>;
}
const AddOrCreateBrandForm = ({ createBrand, updateBrand, dialogMode, selectedRow, openDialog }: IProps) => {
  const initialValues = selectedRow?.id
    ? { name: selectedRow.name, sortOrder: selectedRow.sortOrder }
    : { name: "", sortOrder: 1 };
  const cs = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaBrand}
      validateOnMount
      onSubmit={values => {
        dialogMode === "create" ? createBrand(values) : updateBrand({ id: selectedRow.id, body: values });
        openDialog(false);
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Brand Name"
            name="name"
            color="secondary"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Sort Order"
            name="sortOrder"
            color="secondary"
            type="number"
            value={values.sortOrder}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <DialogActions>
            <Button onClick={() => openDialog(false)} color="default" className={cs.cancelButton}>
              Cancel
            </Button>
            <Button
              className={cs.saveButton}
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting || !isValid || !dirty}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      )}
    </Formik>
  );
};

export default AddOrCreateBrandForm;
