import {
  CurationCategories,
  CurationCategory,
  CurationProduct,
  CurationProducts,
  CurationUser,
  CurationUsers,
} from "../types";
import { ApiClient } from "./api-client.service";
import { AxiosResponse } from "axios";
import { RequestQueryBuilder } from "@oktein/crud-request";

export class SearchCurationService extends ApiClient {
  async getSearchUserCuration(queryBuilder: RequestQueryBuilder): Promise<CurationUsers> {
    const result: AxiosResponse<CurationUsers> = await this.get(`/search-user-curation?${queryBuilder.query(false).replace("sort[0]","sort")}`);
    return result.data;
  }

  async saveSearchUserCuration(body: { userId: string; sortOrder: number }): Promise<CurationUser> {
    const result: AxiosResponse<CurationUser> = await this.post("/search-user-curation", body);
    return result.data;
  }

  async deleteSearchUserCuration(id: string): Promise<CurationUser> {
    const result: AxiosResponse<CurationUser> = await this.delete(`/search-user-curation/${id}`);
    return result.data;
  }

  async getSearchCategoryCuration(queryBuilder: RequestQueryBuilder): Promise<CurationCategories> {
    const result: AxiosResponse<CurationCategories> = await this.get(
      `/search-category-curation?${queryBuilder.query(false).replace("sort[0]","sort")}`,
    );
    return result.data;
  }

  async deleteSearchCategoryCuration(id: string): Promise<CurationCategory> {
    const result: AxiosResponse<CurationCategory> = await this.delete(`/search-category-curation/${id}`);
    return result.data;
  }

  async saveSearchCategoryCuration(body: { productId: string; sortOrder: number }): Promise<CurationCategory> {
    const result: AxiosResponse<CurationCategory> = await this.post("/search-category-curation", body);
    return result.data;
  }

  async saveSearchCategoryWithFile(body: {
    productCategoryId: string;
    sortOrder: string;
    categoryImage: File;
  }): Promise<CurationCategory> {
    const formData = new FormData();
    formData.append("productCategoryId", body.productCategoryId);
    formData.append("sortOrder", body.sortOrder);
    formData.append("file", body.categoryImage);
    const result: AxiosResponse<CurationCategory> = await this.post(
      "/search-category-curation/createWithFile",
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      },
    );
    return result.data;
  }

  async getSearchProductCuration(queryBuilder: RequestQueryBuilder): Promise<CurationProducts> {
    const result: AxiosResponse<CurationProducts> = await this.get(
      `/search-product-curation?${queryBuilder.query(false).replace("sort[0]","sort")}`,
    );
    return result.data;
  }
 

  async deleteSearchProductCuration(id: string): Promise<CurationProduct> {
    const result: AxiosResponse<CurationProduct> = await this.delete(`/search-product-curation/${id}`);
    return result.data;
  }

  async saveSearchProductCuration(body: { productId: string; sortOrder: number }): Promise<CurationProduct> {
    const result: AxiosResponse<CurationProduct> = await this.post("/search-product-curation", body);
    return result.data;
  }

  async saveSearchStreamCuration(body: { streamId: string; sortOrder: number }): Promise<CurationProduct> {
    const result: AxiosResponse<CurationProduct> = await this.post("/search-product-curation", body);
    return result.data;
  }
  async updateProductSortOrder(id: string, body: { sortOrder: number }): Promise<CurationProduct> {
    const result: AxiosResponse<CurationProduct> = await this.post(`/search-product-curation/sortOrder/${id}`, body);
    return result.data;
  }

  async updateCategorySortOrder(id: string, body: { sortOrder: number }): Promise<CurationCategory> {
    const result: AxiosResponse<CurationCategory> = await this.post(`/search-category-curation/sortOrder/${id}`, body);
    return result.data;
  }

  async updateUserSortOrder(id: string, body: { sortOrder: number }): Promise<CurationUser> {
    const result: AxiosResponse<CurationUser> = await this.post(`/search-user-curation/sortOrder/${id}`, body);
    return result.data;
  }
}
