import {
    InstantSearch,
    SearchBox, Highlight, InfiniteHits,
} from 'react-instantsearch-dom';
import { RefinementList } from 'react-instantsearch-dom';
import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";
import './app.css';

const SearchListing = () => {
    const searchClient = new TypesenseInstantsearchAdapter({
        server: {
            apiKey: "Qq9VdQSsPEXfoSS43xXMXEbyTeknB7w87yLKuMs9HM4Y4REP", // Be sure to use an API key that only allows search operations
            nodes: [
                {
                    host: "localhost",
                    port: 8108,
                    protocol: "http",
                },
            ],
            cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
        },
        // The following parameters are directly passed to Typesense's search API endpoint.
        //  So you can pass any parameters supported by the search endpoint below.
        //  query_by is required.
        additionalSearchParameters: {
            query_by: "description,itemName",
            maxFacetValues: 14
        },
    });

    function Hit(props: Object) {
        let results = props as any;
        return (
            <article>
                <h1>
                    <Highlight attribute="itemName" hit={results.hit} />
                </h1>
                <p>
                    <Highlight attribute="description" hit={results.hit} />
                </p>
            </article>
        );
    }

    return (
        <div className="container">
            <InstantSearch indexName="productSpec" searchClient={searchClient.searchClient}>
                <div className="search-panel">
                    <div className="search-panel__filters">
                        <RefinementList attribute="brandName" />
                    </div>
                    <div className="search-panel__results">
                        <SearchBox
                            className="searchbox"
                            translations={{
                                placeholder: '',
                            }}
                        />
                        <InfiniteHits hitComponent={Hit} />
                    </div>
                </div>
            </InstantSearch>
        </div>
    );
}

export default SearchListing;
