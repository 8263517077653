import React from "react";
import { Button, ButtonGroup, Chip, Typography } from "@material-ui/core";
import { RequestQueryBuilder, QuerySort, QueryFilter } from "@oktein/crud-request";
import { Column, Cell } from "react-table";
import { useQuery } from "react-query";
import moment from "moment";
import { useServicesStore } from "../../../../hooks";
import { Product } from "../../../../types";
import { ConfirmDialog, FindsTable } from "../../../../components";
import Filters from "./Filters";
import { toast } from "react-toastify";

const qb = RequestQueryBuilder.create({ page: 1, limit: 30 });
let filterForm ={
  "productSizes":[],
  "category":[],
  "sellers":[],
  "subCategory":[],
  "priceMin":0,
  "priceMax":100,
  "productInterestedCategories":[]
}
const SearchProductFeed = () => {
  const { productService,feedCurationService } = useServicesStore();

  const { data, isLoading, refetch } = useQuery("products", () => productService.getProductsFeed(qb,filterForm), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openSaveDialog, setOpenSaveDialog] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<Product>();

  const { meta, items } = data || {};

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "itemName",
      },
      {
        Header: "Category-SubCategory",
        Cell: ({ row: { original } }: Cell<any>) => (
          <div style={{ wordBreak: "break-all" }}>
           <b>{original?.productCategory?.name}</b>-
          <b>{original?.productSubCategory?.name}</b>
            </div>
        ),
      },
      {
        Header: "Size",
        Cell: ({ row: { original } }: Cell<any>) => (
          <div style={{ wordBreak: "break-all" }}>{original?.productSize?.name}</div>
        ),
      },
      {
        Header: "Seller",
        Cell: ({ row: { original } }: Cell<any>) => (
          <div style={{ wordBreak: "break-all" }}>@{original?.user?.userName}</div>
        ),
      },
      {
        Header: "Style Tags",
        maxWidth: 50,
        Cell: ({ row: { original } }: Cell<any>) => (
          <div>
            {original?.productInterestedCategories?.map((item: any) => (
              <Chip key={item.id} label={item.interestedCategory.categoryName} color="primary" />
            ))}
          </div>
        ),
      },
      
      {
        Header: "Price",
        accessor: "price",
        maxWidth: 50,
      },
     
      {
        Header: "Product Image",
        Cell: ({
          row: {
            original: { productGifUrl },
          },
        }: Cell<Product>) => (
          <>
            <div className="blog-comments__avatar mr-3">
              <img height="220" width="140" src={productGifUrl!} alt={productGifUrl!} />
            </div>
          </>
        ),
        disableSortBy: true,      },
   
      {
        Header: "Creation Date",
        accessor: "createdAt",
        Cell: ({ row: { original } }: Cell<Product>) => moment(original.createdAt).format("DD.MM.YYYY"),
      },
      {
        Header: "Actions",
        Cell: ({
          row: {
            original
          },
        }: Cell<Product>) => (
          <>
            <ButtonGroup color="secondary" aria-label="outlined secondary button group">
              
                <Button variant="outlined" color="secondary"
                 onClick={() => {
                
                  setSelectedRow(original as Product);
                  setOpenDialog(true);
                }}>
                  Add Curation
                </Button>
             
            </ButtonGroup>
          </>
        ),
        disableSortBy: true,
      },
    ],
    [],
  );

  const handlePaginationChange = ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
    qb.setPage(pageIndex).setLimit(pageSize);
    refetch();
  };

  const handleSortChange = (sortOptions: QuerySort[]) => {
    delete qb.queryObject[qb.options.paramNamesMap?.sort as string];
    sortOptions.forEach(value => {
        qb.sortBy(value);
    });
    refetch();
  };

  const handleFiltersChange = ({ filters, search,newSearchForm }: { filters: QueryFilter[]; search: string,newSearchForm:any }) => {
    filterForm = newSearchForm;
    delete qb.queryObject[qb.options.paramNamesMap?.or as string];
    delete qb.queryObject[qb.options.paramNamesMap?.search as string];
    qb.setPage(1);

    if (filters.length !== 0) {
      try{
        qb.setOr(filters);

      }catch(error){
        console.log(error);

      }
    }

    if (search) {
      qb.search({
        $and: [
          { productInterestedCategories: { $cont: search } },
          ...filters.map(filter => {
            return { [filter.field]: { [filter.operator]: filter.value } };
          }),
        ],
      });
    }
    else {
      /*
        qb.search({
                $or: [{id: {$cont: value}}, {discountCode: {$cont: value}}],
            });
            */
        qb.search({
            $or: [
                ...filters.map(filter => {
                    return { [filter.field]: { [filter.operator]: filter.value } };
                }),
            ],
        });
    }
    refetch();
  };

  return (
    <>
     <ConfirmDialog
        open={openSaveDialog}
        onSave={() => {
          if(selectedRow){
            feedCurationService
            .saveAgainFeedProductCuration({
              productId: selectedRow.id,
              scheduleDate:"1970-01-01T00:00:00.000Z",
              sortOrder:1
            })
            .then(() =>{
              refetch();
              toast.success("Added to product curation feed");
            }).catch((error)=>{
              setOpenSaveDialog(false);
            });
          }
          setOpenSaveDialog(false);
        }}
        onClose={() => {
          setOpenSaveDialog(false);
        }}
      >
      <b>This item has already been featured in the feed.</b>
      <br/>
      <br/> Are you sure you want to feature this item again?
      </ConfirmDialog>
     <ConfirmDialog
        open={openDialog}
        onSave={() => {
          if(selectedRow){
            feedCurationService
            .saveFeedProductCuration(
              {
                productId: selectedRow.id,
                scheduleDate:"1970-01-01T00:00:00.000Z",
                sortOrder:1
              })
            .then(() => {
              toast.success("Added to product curation feed");
              //toadt ile eklendi
            })
            .then(() => refetch()).catch((error)=>{
              setOpenDialog(false);
              setOpenSaveDialog(true);
            });
          }
          setOpenDialog(false);
        }}
        onClose={() => {
          setOpenDialog(false);
        }}
      >
        Are you sure you want to feature this item ?
      </ConfirmDialog>
      <Typography variant="h5">Products</Typography>
      <Filters onFiltersChange={handleFiltersChange} />
      {!isLoading && (
        <FindsTable
          defaultPageSize={30}
          columns={columns}
          data={items}
          meta={meta}
          onPaginationChange={handlePaginationChange}
          onSortChange={handleSortChange}
        />
      )}
    </>
  );
};

export default SearchProductFeed;
