import { Button } from "@material-ui/core";
import { QuerySort } from "@oktein/crud-request";
import React from "react";
import { Cell, Column, Row } from "react-table";
import { ConfirmDialog, FindsTable, EditableCell } from "../../../../components";
import { Category, CurationCategories } from "../../../../types";

const CategoryTable = ({
  data,
  isLoading,
  onPaginationChange,
  onSortChange,
  onRowDelete,
  onRowUpdate,
}: {
  data?: CurationCategories;
  isLoading: boolean;
  onRowDelete: (id: string) => void;
  onRowUpdate: (payload: { categoryId: string; sortOrder: number }) => void;
  onPaginationChange: ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => void;
  onSortChange: (sortOptions: QuerySort[]) => void;
}) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");

  const { meta, items } = data || {};

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Category Id",
        accessor: "id",
      },
      {
        Header: "Category Name",
        accessor: "productCategory.name",
      },
      {
        Header: "Order",
        accessor: "sortOrder",
        Cell: EditableCell,
      },
      {
        Header: "Actions",
        Cell: ({
          row: {
            original: { id },
          },
        }: Cell<Category>) => (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setSelectedRowId(id);
              setOpenDialog(true);
            }}
          >
            Remove
          </Button>
        ),
        disableSortBy: true,
      },
    ],
    [],
  );

  return (
    <>
      <ConfirmDialog
        open={openDialog}
        onSave={() => {
          onRowDelete(selectedRowId);
          setOpenDialog(false);
        }}
        onClose={() => setOpenDialog(false)}
        confirmText="Yes"
      >
        Are you sure you want to delete the category curation?
      </ConfirmDialog>
      {!isLoading ? (
        <FindsTable
          columns={columns}
          data={items}
          meta={meta}
          onPaginationChange={onPaginationChange}
          onSortChange={onSortChange}
          onCellValueChange={(row: Row<Category>, _column: Column, value: string) => {
            onRowUpdate({ categoryId: row.original.id, sortOrder: +value });
          }}
        />
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default CategoryTable;
