import React from "react";
import { ProductForm } from "./Forms";
import { ProductTable } from "./Tables";
import { RequestQueryBuilder } from "@oktein/crud-request";
import { useMutation, useQuery } from "react-query";
import { useServicesStore } from "../../../hooks";
import { toast } from "react-toastify";

const useSearchProductCuration = () => {
  const qb = React.useMemo(() => RequestQueryBuilder.create({ page: 1, limit: 5 }), []);
  const { searchCurationService } = useServicesStore();

  const query = useQuery("search-product-curation", () => searchCurationService.getSearchProductCuration(qb), {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { mutate: deleteProductCuration } = useMutation(
    (id: string) => searchCurationService.deleteSearchProductCuration(id),
    {
      onSuccess: () => {
        toast.success("Search product curation deleted!");
        query.refetch();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );

  const { mutate: updateProductCurationOrder } = useMutation(
    (payload: { productId: string; sortOrder: number }) =>
      searchCurationService.updateProductSortOrder(payload.productId, { sortOrder: payload.sortOrder }),
    {
      onSuccess: () => {
        toast.success("Search product curation order updated!");
        query.refetch();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );

  const { mutate: saveProductCuration } = useMutation(
    (payload: { productId: string; sortOrder: number }) => searchCurationService.saveSearchProductCuration(payload),
    {
      onSuccess: () => {
        query.refetch();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );

  return { query, queryBuilder: qb, deleteProductCuration, updateProductCurationOrder, saveProductCuration };
};

const Products = () => {
  const { query, queryBuilder, deleteProductCuration, updateProductCurationOrder, saveProductCuration } =
    useSearchProductCuration();
  return (
    <>
      <ProductForm
        onSubmit={payload => {
          saveProductCuration(payload);
        }}
      />

      <ProductTable
        data={query.data}
        isLoading={query.isLoading}
        onRowDelete={(id: string) => deleteProductCuration(id)}
        onRowUpdate={payload => updateProductCurationOrder(payload)}
        onPaginationChange={({ pageSize, pageIndex }) => {
          queryBuilder.setPage(pageIndex).setLimit(pageSize);
          query.refetch();
        }}
        onSortChange={sortOptions => {
          delete queryBuilder.queryObject[queryBuilder.options.paramNamesMap?.sort as string];
          queryBuilder.sortBy(sortOptions);
          query.refetch();
        }}
      />
    </>
  );
};

export default Products;
