import React from "react";
import { Typography, Grid, Paper, makeStyles, createStyles, Theme } from "@material-ui/core";
import { ProductTable } from "./Tables";
import { ProductForm } from "./Forms";
import { useServicesStore } from "../../../hooks";
import { useMutation, useQuery } from "react-query";
import { RequestQueryBuilder } from "@oktein/crud-request";
import { toast } from "react-toastify";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100%",
      alignItems: "center",
    },
    root: {
      flexDirection: "column",
    },
    title: {
      padding: `${theme.spacing(1)}px 0;`,
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
  }),
);
const qb = RequestQueryBuilder.create({ page: 1, limit: 150 });
qb.sortBy({field: 'sortOrder', order: 'ASC'});
const FeedPageCuration = () => {
  const classes = useStyles();
  const { feedCurationService } = useServicesStore();
  const {
    data: productData,
    isLoading: productIsLoading,
    refetch: refetchProductCuration,
  } = useQuery("feed-product-curation", () => feedCurationService.getFeedProductCuration(qb), {
  });
  const { mutate: updateFeedProductSortOrder } = useMutation(
    (payload: { productId: string; sortOrder: number }) =>
      feedCurationService.updateProductSortOrder(payload.productId, { sortOrder: payload.sortOrder }),
    {
      onSuccess: () => {
        toast.success("Feed product curation order updated!");
        refetchProductCuration();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );
  return (
    <Grid container >
      <Typography variant="h5" className={classes.title}>
        Feed Page Content
      </Typography>
      <Grid style={{ width: "130%" }}>
        <Grid item md="auto" component={Paper} className={classes.paper}>
          <ProductForm refetchProductCuration={refetchProductCuration} />
          <ProductTable
            qb={qb}
            data={productData}
            isLoading={productIsLoading}
            refetchProductCuration={refetchProductCuration}
            updateFeedProductSortOrder={updateFeedProductSortOrder}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FeedPageCuration;
