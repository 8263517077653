import React from "react";
import { Button, Grid } from "@material-ui/core";
import { Formik } from "formik";
import { RequestQueryBuilder } from "@oktein/crud-request";
import { SelectInputField } from "../../../../components";
import { useServicesStore } from "../../../../hooks";
import { format } from 'date-fns';

const ProductForm = ({
  onSubmit,
}: {
  onSubmit: ({ streamId, sortOrder }: { streamId: string; sortOrder: number }) => void;
}) => {
  const { streamCurationService } = useServicesStore();

  return (
    <Formik
      initialValues={{ stream: null }}
      onSubmit={(values: any, { setSubmitting, resetForm }) => {
        setSubmitting(false);
        onSubmit({ streamId: values.stream.value, sortOrder: 1 });
        resetForm();
      }}
    >
      {({ handleSubmit, isValid, resetForm, submitForm, setFieldValue, values }) => (
        <form onSubmit={handleSubmit} style={{ marginBottom: "8px" }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
            <SelectInputField
                name="stream"
                queryKey="stream"
                queryFunction={(qb: RequestQueryBuilder) => streamCurationService.getStreamsWitoutSearch(qb)}
                queryFilter={val => ({ filter:val })}
                selectProps={{ isMulti: false, placeholder: "Select Stream" }}
                selectFunction={(data: any) => {
                  const result = data.map((item: any) => ({
                    label:  item.itemName + ' [Schedule Date:'+format(new Date(item.streamDate),'MMM d, y, h:mm:ss a')+']',
                    value: item.id,
                    item,
                  }));
                  return result;
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" type="submit" color="primary" disabled={!values.stream}>
                Add
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default ProductForm;
