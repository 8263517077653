import React, { useEffect } from "react";
import {Box, Button, ButtonGroup, Grid, Typography} from "@material-ui/core";
import {RequestQueryBuilder} from "@oktein/crud-request";
import {useMutation, useQuery} from "react-query";
import {ConfirmDialog, FormDialog, Search} from "../../components";
import {useServicesStore} from "../../hooks";
import {CreateDiscount, Discount, UpdateDiscount} from "../../types";
import AddOrCreateDiscountForm from "./form";
import {toast} from "react-toastify";
import { DataGrid, GridColDef } from '@material-ui/data-grid';
 
const qb = RequestQueryBuilder.create({page: 1, limit: 50});

export interface EditValues {
    id: string;
    body: UpdateDiscount;
}

const DiscountsPage = () => {
    const {discountService} = useServicesStore();
    const [openCreateOrEditDialog, setOpenCreateOrEditDialog] = React.useState(false);
    const [dialogMode, setDialogMode] = React.useState("create");
    const [selectedRow, setSelectedRow] = React.useState<Discount>();
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

    const {data, isLoading, refetch} = useQuery("discounts", () => discountService.getDiscounts(qb), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
    const {meta, items} = data || {};
    const [page] = React.useState(1);
    const [pageSize] = React.useState(50);

    
    const [rowCountState, setRowCountState] = React.useState(meta?.totalItems || 0);
    React.useEffect(() => {
      setRowCountState((prevRowCountState: any) =>
        meta?.totalItems !== undefined ? meta?.totalItems : prevRowCountState,
      );
    }, [meta?.totalItems, setRowCountState]);
  

    const {mutate: createDiscount} = useMutation((body: CreateDiscount) => discountService.createDiscount(body), {
        onSuccess: () => {
            toast.success("Discount successfully created!");
            refetch();
        },
        onError: () => {
            toast.error("Something went wrong, please try again!");
        },
    });

    const {mutate: updateDiscount} = useMutation(
        (values: EditValues) => discountService.updateDiscount(values.id, values.body),
        {
            onSuccess: () => {
                toast.success("Discount successfully updated!");
                refetch();
            },
            onError: () => {
                toast.error("Something went wrong, please try again!");
            },
        },
    );
    const {mutate: deleteDiscount} = useMutation((id: string) => discountService.deleteDiscount(id), {
        onSuccess: () => {
            toast.success("Discount successfully deleted!");
            refetch();
        },
        onError: () => {
            toast.error("Something went wrong, please try again!");
        },
    });
    const [openDialogAll, setOpenDialogAll] = React.useState(false);
    /*
    const onDelete = () => {
        setOpenDialogAll(true);
    };
    */

    const columns: GridColDef[] = [
        { field: "id", headerName: "DiscountId",width: 120},
        
        {
            field: "userName",
            headerName: "UserName",
            width: 150,
            renderCell: prm => {
              return <div style={{wordBreak: "break-all"}}>{prm.row.user.userName}</div>;
            },
        },
        {
            field: "discountType",
            headerName: "Discount Type",
            width: 130,
            renderCell: prm => {
              return <div style={{wordBreak: "break-all"}}>{prm.row.discountType}</div>
            },
        },
        {
            field: "discountAmount",
            headerName: "Discount Amount",
            width: 130,

            renderCell: prm => {
              return <div style={{wordBreak: "break-all"}}>{prm.row.discountAmount}</div>
            },
        },
        { field: "discountCode", headerName: "Discount Code", width: 140},
        { field: "perUserUsageLimit", headerName: "Per User Limit", width: 150 },
        { field: "overallUsageLimit", headerName: "Overall Usage Limit", width: 180 },
        {
            field: "createdBy",
            headerName: "Created By",
            width: 180,

            renderCell: prm => {
              return <div style={{wordBreak: "break-all"}}>{prm.row.createdBy?.fullName}</div>
            },
        },
        {
            field: "actions",
            headerName: "Actions",
            width: 250,
            renderCell: prm => {
              return <div style={{wordBreak: "break-all"}}>
                  <ButtonGroup color="secondary" aria-label="outlined secondary button group">
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                                setDialogMode("update");
                                setSelectedRow(prm.row as Discount);
                                setOpenCreateOrEditDialog(true);
                            }}
                        >
                            Edit
                        </Button>
                        <Button
                            onClick={() => {
                                setSelectedRow(prm.row as Discount);
                                setOpenDeleteDialog(true);
                            }}
                        >
                            Delete
                        </Button>
                    </ButtonGroup>
                    </div>
            },
        },
    ];

    const handleSearch = (value: string) => {
        if (value.length > 0) {
            qb.search({
                $or: [{id: {$cont: value}}, {discountCode: {$cont: value}}],
            });
        } else {
            delete qb.queryObject[qb.options.paramNamesMap?.search as string];
        }
        qb.setPage(0).setLimit(5);
        refetch();
    };

    const [selectionModel, setSelectionModel] = React.useState<any[]>([]);
    useEffect(() => {
        qb.setPage(page).setLimit(pageSize);
        refetch();
      }, [pageSize, page, refetch]);
    
      /*
      const onReset = () => {
        setSelectionModel([]);
      };
      */
    return (
        <div>
            <ConfirmDialog
                open={openDeleteDialog}
                onSave={() => {
                    deleteDiscount(selectedRow?.id as string);
                    setOpenDeleteDialog(false);
                }}
                onClose={() => {
                    setSelectedRow({} as Discount);
                    setOpenDeleteDialog(false);
                }}
                confirmText="Yes"
            >
                Are you sure to delete this discount?
            </ConfirmDialog>
            <ConfirmDialog
                open={openDialogAll}
                onSave={() => {
                discountService.deleteAll(selectionModel).then(res => {
                    refetch();
                    toast.info(res.item.affected + " rows deleted");
                    setOpenDialogAll(false);
                });
                }}
                onClose={() => setOpenDialogAll(false)}
                confirmText="Yes"
            >
                Are you sure you want to delete all the discount ?
            </ConfirmDialog>
            <FormDialog
                open={openCreateOrEditDialog}
                onClose={() => {
                    setDialogMode("create");
                    setSelectedRow({} as Discount);
                }}
            >
                {dialogMode === "create" ? "Add a new Discount" : "Update Discount"}
                <AddOrCreateDiscountForm
                    createDiscount={createDiscount}
                    updateDiscount={updateDiscount}
                    dialogMode={dialogMode}
                    selectedRow={selectedRow as Discount}
                    openDialog={setOpenCreateOrEditDialog}
                    newRow={dialogMode === "create"}
                />
            </FormDialog>
            <Box display="flex" justifyContent="space-between" mb={2} onClick={() => setOpenCreateOrEditDialog(true)}>
                <Typography variant="h5">Discounts</Typography>
                <Button color="secondary" variant="contained">
                    Add New Discount
                </Button>
            </Box>
            <Search textFieldProps={{placeholder: "Search by id or discount"}} onSubmit={handleSearch}/>

            {!isLoading && (
                <div style={{ height: "600px", width: "auto" }}>
                     <Grid>
                <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
              {/*
                <Button variant="contained" color="primary" onClick={onDelete}>
                    Delete selected rows
                </Button>
                <Button variant="contained" color="primary" onClick={onReset}>
                    Reset selected rows
                </Button>
              */}
                </Grid>
            </Grid>
                <DataGrid
                    onSelectionModelChange={setSelectionModel}
                    selectionModel={selectionModel}
                    checkboxSelection
                    rows={items}
                    rowCount={rowCountState}
                    loading={isLoading}
                    rowsPerPageOptions={[50,100,200]}
                    pagination
                    paginationMode="server"
                    columns={columns}
                  />
                  </div>
            )}
        </div>
    );
};

export default DiscountsPage;
