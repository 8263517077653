import { Box, Button, Grid, Paper, Typography, createStyles, makeStyles, Theme } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";
import { useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";

import { ConfirmDialog } from "../../../components";
import { useServicesStore } from "../../../hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({ field: { margin: "0 0 10px" }, label: { margin: "0 5px 0 0", fontWeight: "bold" } }),
);

interface IProps {
  match: {
    params: { id: string };
  };
}
const Detail = ({
  match: {
    params: { id },
  },
}: IProps) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = React.useState(false);
  const { productService } = useServicesStore();
  const { data, isLoading, isError } = useQuery("users-details", () => productService.getProduct(id), {
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { mutate: deactiveProduct } = useMutation((id: string) => productService.deactivateProduct(id), {
    onSuccess: () => {
      toast.success("Product successfully deactivated!");
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });

  const renderProductImage = () => {
    const productImage = data?.item.mediaTypes.find(m => m.fileType === "image/gif");
    if (!productImage) return;
    return (
      <a href={productImage.realPath} target="_blank" rel="noreferrer">
        <img src={productImage.realPath} alt="" style={{ width: "100%", maxHeight: 500, objectFit: "contain" }} />
      </a>
    );
  };

  const renderProductVideo = () => {
    const productVideo = data?.item.mediaTypes.find(m => m.fileType === "video/mp4");
    if (!productVideo) return;
    return (
      <video controls src={productVideo.realPath} style={{ width: "100%", maxHeight: 500 }}>
        Sorry, your browser doesn't support embedded videos,{" "}
        <a href={productVideo.realPath} target="_blank" rel="noreferrer">
          click here
        </a>{" "}
        to view it.
      </video>
    );
  };

  return (
    <>
      <ConfirmDialog
        open={openDialog}
        confirmText="Yes"
        onSave={() => {
          deactiveProduct(id);
          setOpenDialog(false);
        }}
        onClose={() => setOpenDialog(false)}
      >
        Are you sure to deactivate the user?
      </ConfirmDialog>
      {isLoading ? (
        <div>Loading...</div>
      ) : isError ? (
        <Alert severity="error">
          <AlertTitle>
            <strong>Error</strong>
          </AlertTitle>
          An error occured when fetching product.
        </Alert>
      ) : (
        <Grid container spacing={3} component={Paper}>
          <Grid item md={6} sm={12}>
            <Box display="flex" flexDirection="column">
              <Box display="flex" className={classes.field}>
                <Typography variant="subtitle2" className={classes.label}>
                  Product Number:
                </Typography>
                <Typography variant="body2">{data?.item.id}</Typography>
              </Box>
              <Box display="flex" className={classes.field}>
                <Typography variant="subtitle2" className={classes.label}>
                  Product Name:
                </Typography>
                <Typography variant="body2">{data?.item.itemName}</Typography>
              </Box>
              <Box display="flex" className={classes.field}>
                <Typography variant="subtitle2" className={classes.label}>
                  Product Description:
                </Typography>
                <Typography variant="body2">{data?.item.description}</Typography>
              </Box>
              <Box display="flex" className={classes.field}>
                <Typography variant="subtitle2" className={classes.label}>
                  Location:
                </Typography>
                <Typography variant="body2">{data?.item.city?.name}</Typography>
              </Box>
              <Box display="flex" className={classes.field}>
                <Typography variant="subtitle2" className={classes.label}>
                  Category:
                </Typography>
                <Typography variant="body2">-/{data?.item?.productSubCategory?.name}</Typography>
              </Box>
              <Box display="flex" className={classes.field}>
                <Typography variant="subtitle2" className={classes.label}>
                  Condition:
                </Typography>
                <Typography variant="body2">{data?.item?.productCondition?.name}</Typography>
              </Box>
              <Box display="flex" className={classes.field}>
                <Typography variant="subtitle2" className={classes.label}>
                  Seller:
                </Typography>
                <Typography variant="body2">{data?.item?.userId}</Typography>
              </Box>
              <Box display="flex" className={classes.field}>
                <Typography variant="subtitle2" className={classes.label}>
                  Price:
                </Typography>
                <Typography variant="body2">{data?.item?.price}</Typography>
              </Box>
              <Box display="flex" className={classes.field}>
                <Typography variant="subtitle2" className={classes.label}>
                  Quantity:
                </Typography>
                <Typography variant="body2">{data?.item?.quantity}</Typography>
              </Box>
              <Box display="flex" className={classes.field}>
                <Typography variant="subtitle2" className={classes.label}>
                  State:
                </Typography>
                <Typography variant="body2">{data?.item.productState}</Typography>
              </Box>
              <Box display="flex" className={classes.field}>
                <Typography variant="subtitle2" className={classes.label}>
                  Tags:
                </Typography>
                <Typography variant="body2">{data?.item?.productHashTag?.join(", ")}</Typography>
              </Box>
              <Box display="flex" style={{ margin: "10px 0 0" }}>
                <Typography variant="subtitle2" className={classes.label}>
                  Shipping:
                </Typography>
                <Typography variant="body2">
                  {data?.item.shipmentFee} / {data?.item.trackingCarrier}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={3} sm={12}>
            <Typography variant="subtitle2" className={classes.label}>
              Product Video
            </Typography>
            <Typography variant="body2">{renderProductVideo()}</Typography>
          </Grid>
          <Grid item md={3} sm={12}>
            <Typography variant="subtitle2" className={classes.label}>
              Product Gif
            </Typography>
            {renderProductImage()}
          </Grid>
          <Grid item sm={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
                Deactivate
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Detail;
