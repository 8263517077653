import React from "react";
import { Container, Grid, Box, CssBaseline, makeStyles } from "@material-ui/core";
import { Header, SideMenu } from "../";

const useStyles = makeStyles(theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

interface IProps {
  children: React.ReactNode;
}

const Layout = ({ children }: IProps) => {
  const [open, setOpen] = React.useState(true);
  const classes = useStyles();

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box display="flex" style={{ height: "100vh" }}>
      <CssBaseline />
      <Header handleDrawerOpen={handleDrawerOpen} open={open} />
      <SideMenu handleDrawerClose={handleDrawerClose} open={open} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container className={classes.container} maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              {children}
            </Grid>
          </Grid>
        </Container>
      </main>
    </Box>
  );
};

export default Layout;
