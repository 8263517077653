import React from "react";
import { Typography, Button, ButtonGroup } from "@material-ui/core";
import { QueryFilter, QuerySort, RequestQueryBuilder } from "@oktein/crud-request";
import { useQuery } from "react-query";
import { Cell, Column } from "react-table";
import { Link } from "react-router-dom";
import { FindsTable } from "../../components";
import { useServicesStore } from "../../hooks";
import { Order, OrderStates } from "../../types";
import Filters from "./Filters";

const qb = RequestQueryBuilder.create({ page: 1, limit: 5 });

const OrdersPage = () => {
  const { orderService } = useServicesStore();

  const { data, isLoading, refetch } = useQuery("orders", () => orderService.getOrders(qb), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const { meta, items } = data || {};

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Order Number",
        accessor: "orderNumber",
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: "Seller",

        Cell: ({
          row: {
            original: { sellerName, sellerUserId },
          },
        }: Cell<Order>) => (
          <Link to={`/users/${sellerUserId}`}>
            <span>{sellerName}</span>
          </Link>
        ),
      },
      {
        Header: "Buyer",
        Cell: ({
          row: {
            original: { buyerUserId, buyerName },
          },
        }: Cell<Order>) => (
          <Link to={`/users/${buyerUserId}`}>
            <span>{buyerName}</span>
          </Link>
        ),
      },
      {
        Header: "Order Date",
        accessor: "createdAt",
        Cell: ({ value }) => <span>{value ? new Date(value).toLocaleDateString() : "-"}</span>,
      },
      {
        Header: "Status",
        accessor: "orderState",
        Cell: ({ value }: Cell<Order>) => <span>{OrderStates[value]}</span>,
      },
      {
        Header: "Actions",
        Cell: ({
          row: {
            original: { orderNumber: id },
          },
        }: Cell<Order>) => (
          <>
            <ButtonGroup color="secondary" aria-label="outlined secondary button group">
              <Link to={`/orders/${id}`}>
                <Button variant="outlined" color="secondary">
                  Details
                </Button>
              </Link>
            </ButtonGroup>
          </>
        ),
        disableSortBy: true,
      },
    ],
    [],
  );

  const handleFiltersChange = ({ filters, search }: { filters: QueryFilter[]; search: string }) => {
    // Reset all
    delete qb.queryObject[qb.options.paramNamesMap?.or as string];
    delete qb.queryObject[qb.options.paramNamesMap?.search as string];
    qb.setPage(1);

    if (filters.length !== 0) {
      qb.setOr(filters);
    }

    if (search) {
      qb.search({
        $and: [
          { orderNumber: { $cont: search } },
          ...filters.map(filter => {
            return { [filter.field]: { [filter.operator]: filter.value } };
          }),
        ],
      });
    }
    else {
        qb.search({
            $and: [
                ...filters.map(filter => {
                    return { [filter.field]: { [filter.operator]: filter.value } };
                }),
            ],
        });
    }
    refetch();
  };

  const handlePaginationChange = ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
    qb.setPage(pageIndex).setLimit(pageSize);
    refetch();
  };

  const handleSortChange = (sortOptions: QuerySort[]) => {
    delete qb.queryObject[qb.options.paramNamesMap?.sort as string];
    sortOptions.forEach(value => {
        qb.sortBy(value);
    })
    refetch();
  };

  return (
    <div>
      <Typography variant="h5">Orders</Typography>
      <Filters onFiltersChange={handleFiltersChange} />
      {!isLoading && (
        <FindsTable
          columns={columns}
          data={items}
          meta={meta}
          onPaginationChange={handlePaginationChange}
          onSortChange={handleSortChange}
        />
      )}
    </div>
  );
};

export default OrdersPage;
