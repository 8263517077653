import React from "react";
import { Button, Grid, InputLabel, TextField } from "@material-ui/core";
import { Formik } from "formik";
import { RequestQueryBuilder } from "@oktein/crud-request";
import { ConfirmDialog, SelectInputField } from "../../../../components";
import { useServicesStore } from "../../../../hooks";
import { useQuery } from "react-query";
import { Products } from "../../../../types";
import moment from "moment";

interface IProps {
  refetchProductCuration: () => void;
}
const ProductForm = ({ refetchProductCuration }: IProps) => {
  const { productService, feedCurationService } = useServicesStore();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [formValue, setFormaValue] = React.useState<any>();

  const qb = React.useMemo(() => RequestQueryBuilder.create({ page: 1, limit: 150 }), []);
  const { refetch } = useQuery("product", () => productService.getProducts(qb), {
    refetchOnWindowFocus: false,
  });
  return (
    <div>
      <ConfirmDialog
        open={openDialog}
        onSave={() => {
          feedCurationService
          .saveAgainFeedProductCuration(formValue)
          .then(() => refetchProductCuration())
          .then(() => refetch()).catch((error)=>{
            setOpenDialog(false);
          });
          setOpenDialog(false);
        }}
        onClose={() => {
          setOpenDialog(false);
        }}
      >
      <b>This item has already been featured in the feed.</b>
      <br/>
      <br/> Are you sure you want to feature this item again?
      </ConfirmDialog>
    <Formik
      initialValues={{ product: null, scheduleDate: null }}
      onSubmit={(values: any, { setSubmitting, resetForm }) => {
        setSubmitting(false);
        const form ={ productId: values.product.value, sortOrder: 1, scheduleDate: new Date(values.scheduleDate).toISOString()};
        setFormaValue(form);
        feedCurationService
          .saveFeedProductCuration(form)
          .then(() => refetchProductCuration())
          .then(() => refetch()).catch((error)=>{
            console.log(error);
            setOpenDialog(true);
          });
        resetForm();
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <form onSubmit={handleSubmit} style={{ marginBottom: "8px" }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SelectInputField
                name="product"
                captureMenuScroll={false}
                queryKey="product"
                queryFunction={(qb: RequestQueryBuilder) => productService.getProducts(qb)}
                queryFilter={val => ({ $or: [{ itemName: { $cont: val } }] })}
                selectProps={{ isMulti: false, placeholder: "Select Product" }}
                selectFunction={(data: Products) => {
                  const { items = [] } = data || {};
                  const result = items.map(item => ({
                    label: item.itemName,
                    value: item.id,
                    item,
                  }));
                  return result;
                }}
                limitDefault={100}
              />
            </Grid>
            <InputLabel style={{ marginTop: "15px" }}>Publish Date :</InputLabel>
            <Grid item sm={6} md={2}>
            <TextField
                id="scheduleDate"
                name="scheduleDate"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: moment().format("YYYY-MM-DD") } }}
                onChange={event => {
                  values.scheduleDate = event.target.value;
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" type="submit" color="primary" disabled={!values.product}>
                Add
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
    </div>

  );
};

export default ProductForm;
