import React from "react";
import { Typography, Button, ButtonGroup, Checkbox } from "@material-ui/core";
import { useMutation, useQuery } from "react-query";
import { Cell, Column } from "react-table";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { FindsTable, Search, ConfirmDialog } from "../../components";
import { useServicesStore } from "../../hooks";
import { User } from "../../types";
import { QuerySort, RequestQueryBuilder } from "@oktein/crud-request";

const qb = RequestQueryBuilder.create({ page: 1, limit: 5 });

const UsersPage = () => {
  const { userService } = useServicesStore();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogActive, setOpenDialogActive] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");

  const { data, isLoading, refetch } = useQuery("users", () => userService.getUsers(qb), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const { mutate: deactivateUser } = useMutation((id: string) => userService.deactivateUser(id), {
    onSuccess: () => {
      toast.success("User successfully deactivated!");
      refetch();
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });
  const { mutate: activateUser } = useMutation((id: string) => userService.activateUser(id), {
    onSuccess: () => {
      toast.success("User successfully activated!");
      refetch();
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });

  const { meta, items } = data || {};

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "User Id",
        accessor: "id",
      },
      {
        Header: "Full Name",
        Cell: ({ row: { original } }: Cell<User>) => (
          <div>
            {original.firstName} {original.lastName}
          </div>
        ),
      },
      {
        Header: "Username",
        accessor: "userName",
        maxWidth: 50,
        Cell: ({ row: { original } }: Cell<User>) => <div style={{ wordBreak: "break-all" }}>{original.userName}</div>,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ row: { original } }: Cell<User>) => <div style={{ wordBreak: "break-all" }}>{original.email}</div>,
      },
      {
        Header: "Registration Date",
        accessor: "createdAt",
        Cell: ({ value }) => <span>{value ? new Date(value).toLocaleDateString() : "-"}</span>,
      },
      {
        Header: "Status",
        accessor: "isActive",
        Cell: ({ value }) => <Checkbox checked={value} />,
      },
      {
        Header: "Actions",
        Cell: ({
          row: {
            original: { id, isActive },
          },
        }: Cell<User>) => (
          <>
            <ButtonGroup color="secondary" aria-label="outlined secondary button group">
              <Link to={`/users/${id}`}>
                <Button variant="outlined" color="secondary">
                  Details
                </Button>
              </Link>
              {(() => {
                if (isActive) {
                  return (
                    <Button
                    onClick={() => {
                      setSelectedRowId(id);
                      setOpenDialog(true);
                    }}
                  >
                    Deactivate
                  </Button>
                      )
                } else {
                  return (
                   
                    <Button
                    onClick={() => {
                      setSelectedRowId(id);
                      setOpenDialogActive(true);
                    }}
                  >
                    Activate 
                  </Button>
    
                  )
                }
                 })()}
            </ButtonGroup>
          </>
        ),
        disableSortBy: true,
      },
    ],
    [],
  );

  const handleSearch = (value: string) => {
    if (value.length > 0) {
     // qb.search({ $or: [{ email: { $cont: value } }, { userName: { $cont: value } }] });
      qb.search({ $or: [{ userName: { $cont: value } }] });
    } else {
      delete qb.queryObject[qb.options.paramNamesMap?.search as string];
    }
    qb.setPage(1).setLimit(5);
    refetch();
  };

  const handlePaginationChange = ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
    qb.setPage(pageIndex).setLimit(pageSize);
    refetch();
  };

  const handleSortChange = (sortOptions: QuerySort[]) => {
    delete qb.queryObject[qb.options.paramNamesMap?.sort as string];
    qb.sortBy(sortOptions);
    refetch();
  };

  return (
    <div>
      <ConfirmDialog
        open={openDialog}
        onSave={() => {
          deactivateUser(selectedRowId);
          setOpenDialog(false);
          refetch();
        }}
        onClose={() => setOpenDialog(false)}
      >
        Are you sure to deactivate the user?
      </ConfirmDialog>
      <ConfirmDialog
        open={openDialogActive}
        onSave={() => {
          activateUser(selectedRowId);
          setOpenDialogActive(false);
          refetch();
        }}
        onClose={() => setOpenDialogActive(false)}
      >
        Are you sure to activate the user?
      </ConfirmDialog>
      <Typography variant="h5">Users</Typography>
      <Search textFieldProps={{ placeholder: "Search by username, email or full name" }} onSubmit={handleSearch} />
      {!isLoading && (
        <FindsTable
          columns={columns}
          data={items}
          meta={meta}
          onPaginationChange={handlePaginationChange}
          onSortChange={handleSortChange}
        />
      )}
    </div>
  );
};

export default UsersPage;
