import { Avatar, Box, Button, Checkbox, CircularProgress, TextField, Typography } from "@material-ui/core";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useServicesStore } from "../../hooks";
import moment from "moment";
import { Formik } from "formik";
import { ConfirmDialog } from "../../components";
import validationCommisionRateSchema from "./validationCommisionRateSchema";

import { toast } from "react-toastify";

interface IProps {
  match: {
    params: { id: string };
  };
}
interface IFormValues {
  id: string;
  verified?: boolean;
  fee?: string;
  commissionRate?: number;
}
const UserDetail = ({
  match: {
    params: { id },
  },
}: IProps) => {
  const { userService } = useServicesStore();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openRestrictDialog, setRestrictDialog] = React.useState(false);
  const formRef = React.useRef(null);

  const { data, isLoading } = useQuery("users-details", () => userService.getUserById(id), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });
  /*
  const { mutate: updateUserMerchFee } = useMutation(
    ({ id, fee }: IFormValues) => userService.updateUserMerchFee(id, { fee }),
    {
      onSuccess: () => {
        toast.success("User Merch Fee successfully changed!");
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );
  */
  const { mutate: updatecommissionRate } = useMutation(
    ({ id, commissionRate }: IFormValues) => userService.updatecommissionRate(id, { commissionRate }),
    {
      onSuccess: () => {
        toast.success("User commission Rate successfully changed!");
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );
  const { mutate: toggleVerified } = useMutation(
    ({ id, verified }: IFormValues) => userService.toggleVerified(id, { verified }),
    {
      onSuccess: () => {
        toast.success("User Verified successfully changed!");
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );
  const { mutate: deactivateUser } = useMutation((id: string) => userService.deactivateUser(id), {
    onSuccess: () => {
      toast.success("User successfully deactivated!");
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });
  const { mutate: stripeAccountUser } = useMutation((id: string) => userService.stripeAccountUser(id), {
    onSuccess: () => {
      toast.success("Stripe User product's successfully activeted");
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });
  const user = data?.item?.user;

  return !isLoading ? (
    <>
      <ConfirmDialog
        open={openDialog}
        onSave={() => {
          deactivateUser(id);
          setOpenDialog(false);
        }}
        onClose={() => setOpenDialog(false)}
      >
        Are you sure to deactivate the user?
      </ConfirmDialog>
      <ConfirmDialog
        open={openRestrictDialog}
        onSave={() => {
          stripeAccountUser(id);
          setRestrictDialog(false);
        }}
        onClose={() => setRestrictDialog(false)}
      >
        Are you sure you want to reactivate Stripe account?
      </ConfirmDialog>
      <Box display="flex">
        <Box display="flex" flexDirection="column" width="50%">
          <Box display="flex" mb={2} alignItems="center">
            <Avatar alt={`${user?.firstName} + ${user?.lastName}`} src={user?.profileImage} />
            <h3 style={{ marginBottom: 0 }}>
              {user?.firstName} {user?.lastName}
            </h3>
          </Box>
          <Box display="flex">
            <Typography>User Id: </Typography>
            <h3>{user?.id}</h3>
          </Box>
          <Box display="flex">
            <Typography>Username: </Typography>
            <h3>{user?.userName}</h3>
          </Box>
          <Box display="flex">
            <Typography>Email: </Typography>
            <h3>{user?.email}</h3>
          </Box>
          <Box display="flex">
            <Typography>Registration Date: </Typography>
            <h3>{moment(user?.createdAt).format("DD.MM.YYYY")}</h3>
          </Box>
          <Box display="flex">
            <Typography>Last Activity Date: </Typography>{" "}
            <h3> {moment(user?.profileStats?.lastActiveTime).format("DD.MM.YYYY")}</h3>
          </Box>
          <Box display="flex">
            <Typography>Registration Type: </Typography>
            <h3>{user?.registrationType}</h3>
          </Box>
          {/*
  <Box display="flex" alignItems="center">
            <Typography>Merch Fee: </Typography>
            <Formik enableReinitialize={true}
              initialValues={{ merchFee: user?.merchendisingFee?.toString() ?? "" }}
              onSubmit={values => {
                updateUserMerchFee({ id, fee: values?.merchFee?.toString() });
                if (typeof user != "undefined") {
                  if(typeof values != "undefined" && typeof values?.merchFee != "undefined") {
                    user.merchendisingFee = Number.parseInt(values?.merchFee);
                  }
                }
              }}
              innerRef={formRef}
              validationSchema={validationSchema}
            >
              {({ values, handleChange, handleSubmit, isValid }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    type="number"
                    color="secondary"
                    value={values.merchFee}
                    name="merchFee"
                    onChange={handleChange}
                    style={{ marginLeft: "8px", marginRight: "16px" }}
                  />
                  <Button variant="contained" color="primary" type="submit" disabled={!isValid}>
                    Update Merch Fee
                  </Button>
                </form>
              )}
            </Formik>
          </Box>
         
        
          <Box display="flex">
            <Typography>Merch Fee: </Typography>
            <h3>{user?.merchendisingFee}</h3>
          </Box>
           */}
          <Box display="flex" alignItems="center">
            <Typography>Verified Profile: </Typography>
            <Checkbox
              defaultChecked={user?.isVerified}
              onChange={e => toggleVerified({ id, verified: e.target.checked })}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <Typography>Commission Rate: </Typography>
            <Formik enableReinitialize={true}
              initialValues={{ commissionRate: user?.commissionRate?.toString() ?? "" }}
              onSubmit={values => {
                updatecommissionRate({ id, commissionRate: Number(values?.commissionRate) });
                if (typeof user != "undefined") {
                  if (typeof values != "undefined" && typeof values?.commissionRate != "undefined") {
                    //user.commissionRate = Number.parseInt(values?.commissionRate);
                    user.commissionRate = Number(values?.commissionRate);
                  }
                }
              }}
              innerRef={formRef}
              validationSchema={validationCommisionRateSchema}
            >
              {({ values, handleChange, handleSubmit, isValid }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    type="number"
                    color="secondary"
                    value={values.commissionRate}
                    name="commissionRate"
                    onChange={handleChange}
                    style={{ marginLeft: "8px", marginRight: "16px" }}
                  />
                  <Button variant="contained" color="primary" type="submit" disabled={!isValid}>
                    Update Commission Rate
                  </Button>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
        <Box width="50%">
          <Box display="flex">
            <Typography>Phone number: </Typography>
            <h3>{user?.phoneNumber}</h3>
          </Box>
          <Box display="flex">
            <Typography>Average rating: </Typography>
            <h3>{user?.profileStats?.rating}</h3>
          </Box>
          <Box display="flex">
            <Typography>Bio: </Typography>
            <h3>{user?.bio}</h3>
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Link to="/users">
          <Box mr={2}>
            <Button variant="contained" color="primary">
              Cancel
            </Button>
          </Box>
        </Link>
        <Box mr={2}>
          <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
            Deactivate User
          </Button>
          <Button variant="contained" color="primary" onClick={() => setRestrictDialog(true)}>
            Check Stripe Restrict
          </Button>
        </Box>
      </Box>
    </>
  ) : (
    <Box display="flex" justifyContent="center">
      <CircularProgress size="6rem" />
    </Box>
  );
};

export default UserDetail;
