import React from "react";
import { CategoryForm } from "./Forms";
import { CategoryTable } from "./Tables";
import { RequestQueryBuilder } from "@oktein/crud-request";
import { useMutation, useQuery } from "react-query";
import { useServicesStore } from "../../../hooks";
import { toast } from "react-toastify";

const useSearchCategoryCuration = () => {
  const qb = React.useMemo(() => RequestQueryBuilder.create({ page: 1, limit: 5 }), []);
  const { searchCurationService } = useServicesStore();

  const query = useQuery("search-category-curation", () => searchCurationService.getSearchCategoryCuration(qb), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const { mutate: deleteSearchCategoryCuration } = useMutation(
    (id: string) => searchCurationService.deleteSearchCategoryCuration(id),
    {
      onSuccess: () => {
        toast.success("Search category curation deleted!");
        query.refetch();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );

  const { mutate: updateSearchCategoryCurationOrder } = useMutation(
    (payload: { categoryId: string; sortOrder: number }) =>
      searchCurationService.updateCategorySortOrder(payload.categoryId, { sortOrder: payload.sortOrder }),
    {
      onSuccess: () => {
        toast.success("Search category curation order updated!");
        query.refetch();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );

  const { mutate: saveSearchCategoryCuration } = useMutation(
    (payload: { productCategoryId: string; sortOrder: string; categoryImage: File }) =>
      searchCurationService.saveSearchCategoryWithFile(payload),
    {
      onSuccess: () => {
        query.refetch();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );

  return {
    query,
    queryBuilder: qb,
    deleteSearchCategoryCuration,
    saveSearchCategoryCuration,
    updateSearchCategoryCurationOrder,
  };
};

const Categories = () => {
  const {
    query,
    queryBuilder,
    deleteSearchCategoryCuration,
    updateSearchCategoryCurationOrder,
    saveSearchCategoryCuration,
  } = useSearchCategoryCuration();

  return (
    <>
      <CategoryForm
        onSubmit={payload => {
          saveSearchCategoryCuration(payload);
        }}
      />
      <CategoryTable
        data={query.data}
        isLoading={query.isLoading}
        onRowDelete={(id: string) => deleteSearchCategoryCuration(id)}
        onRowUpdate={payload => updateSearchCategoryCurationOrder(payload)}
        onPaginationChange={({ pageSize, pageIndex }) => {
          queryBuilder.setPage(pageIndex).setLimit(pageSize);
          query.refetch();
        }}
        onSortChange={sortOptions => {
          delete queryBuilder.queryObject[queryBuilder.options.paramNamesMap?.sort as string];
          queryBuilder.sortBy(sortOptions);
          query.refetch();
        }}
      />
    </>
  );
};

export default Categories;
