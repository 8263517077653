import { ApiClient } from "./api-client.service";
import { AxiosResponse } from "axios";
import { Categories, Category, CreateCategory, SubCategories, UpdateCategory } from "../types";
import { RequestQueryBuilder } from "@oktein/crud-request";

export class CategoryService extends ApiClient {
  async getCategories(queryBuilder: RequestQueryBuilder): Promise<Categories> {
    const result: AxiosResponse<Categories> = await this.get(`/categories?${queryBuilder.query(false).replace("sort[0]","sort")}`);
    return result.data;
  }

  async createCategory(body: CreateCategory): Promise<Category> {
    const result: AxiosResponse<Category> = await this.post("/categories", body);
    return result.data;
  }

  async updateCategory(id: string, body: UpdateCategory): Promise<Category> {
    const result: AxiosResponse<Category> = await this.patch(`/categories/${id}`, body);
    return result.data;
  }

  async deleteCategory(id: string): Promise<void> {
    const result: AxiosResponse<void> = await this.delete(`/categories/${id}`);
    return result.data;
  }

  async getSubCategories(id: string, queryBuilder: RequestQueryBuilder): Promise<SubCategories> {
    const result: AxiosResponse<SubCategories> = await this.get(
      `/categories/${id}/subcategories?${queryBuilder.query(false).replace("sort[0]","sort")}`,
    );
    return result.data;
  }
}
