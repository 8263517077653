import {
  TextField,
  Button,
  Grid,
  createStyles,
  makeStyles,
  Theme,
  InputAdornment,
  IconButton,
  TextFieldProps,
} from "@material-ui/core";
import { Field, FieldInputProps, FieldProps, Formik } from "formik";
import { Clear as ClearIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    container: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

const SearchComp = ({
  textFieldProps = {},
  onSubmit,
}: {
  textFieldProps?: TextFieldProps;
  onSubmit: (value: string) => void;
}) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{ search: "" }}
      onSubmit={(values: any, { setSubmitting }) => {
        setSubmitting(false);
        onSubmit(values.search);
      }}
    >
      {({ handleSubmit, isValid, resetForm, submitForm }) => (
        <form onSubmit={handleSubmit} className={classes.root}>
          <Grid className={classes.container} container spacing={1}>
            <Grid item xs={12} md={9}>
              <Field name="search">
                {({ field }: FieldProps) => (
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {field.value ? (
                            <IconButton
                              onClick={() => {
                                resetForm();
                                submitForm();
                              }}
                            >
                              <ClearIcon fontSize="small" />
                            </IconButton>
                          ) : (
                            <></>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    {...field}
                    {...textFieldProps}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                color="secondary"
                variant="contained"
                style={{ height: "100%" }}
                type="submit"
                fullWidth
                disabled={!isValid}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

type IFilterTextProps = {
  onClear: () => void;
} & FieldInputProps<any> &
  TextFieldProps;

export function FilterTextInput(props: IFilterTextProps) {
  return (
    <TextField
      variant="outlined"
      fullWidth
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {props.value ? (
              <IconButton onClick={props.onClear}>
                <ClearIcon fontSize="small" />
              </IconButton>
            ) : (
              <></>
            )}
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}

interface IFilterTextFieldProps {
  name: string;
  textFieldProps?: TextFieldProps;
}

export function FilterTextField({ name, textFieldProps = {} }: IFilterTextFieldProps) {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <FilterTextInput onClear={() => form.setFieldValue(name, "")} {...field} {...textFieldProps} />
      )}
    </Field>
  );
}

export default SearchComp;
