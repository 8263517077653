import React from "react";
import { Button, ButtonGroup, TextField, Typography } from "@material-ui/core";
import { QuerySort, RequestQueryBuilder } from "@oktein/crud-request";
import { useMutation, useQuery } from "react-query";
import { useServicesStore } from "../../hooks";
import { Cell, Column } from "react-table";
import { ConfirmDialog, FindsTable } from "../../components";
import { toast } from "react-toastify";
import moment from "moment";
import { Order, OrderState } from "../../types";

const qb = RequestQueryBuilder.create({ page: 1, limit: 5 });

const Refunds = () => {
  const { orderService } = useServicesStore();
  const [openRejectRefundDialog, setOpenRejectRefundDialog] = React.useState(false);
  const [openRefundNowDialog, setOpenRefundNowDialog] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [rejectReason, setRejectReason] = React.useState("");

  const { data, isLoading, refetch } = useQuery("refund-requests", () => orderService.getOrders(qb), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const { mutate: refundOrder } = useMutation((id: string) => orderService.changeOrderState(id, OrderState.Refund), {
    onSuccess: () => {
      toast.success("Order successfully refunded!");
      refetch();
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });
  const { mutate: rejectRefund } = useMutation(
    (id: string) => orderService.changeOrderState(id, OrderState.Cancelled, { reason: rejectReason }),
    {
      onSuccess: () => {
        toast.success("Order successfully rejected!");
        refetch();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );

  const { meta, items } = data || {};

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Order Number",
        accessor: "orderNumber",
      },

      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: "Seller",
        accessor: "sellerName",
        maxWidth: 50,
        Cell: ({ row: { original } }: Cell<Order>) => (
          <div style={{ wordBreak: "break-all" }}>{original?.sellerName}</div>
        ),
      },
      {
        Header: "Buyer",
        accessor: "buyerName",
        Cell: ({ row: { original } }: Cell<Order>) => (
          <div style={{ wordBreak: "break-all" }}>{original?.buyerName}</div>
        ),
      },
      {
        Header: "Status",
        accessor: "orderState",
        Cell: ({ row: { original } }: Cell<Order>) => (
          <div style={{ wordBreak: "break-all" }}>{original?.orderState}</div>
        ),
      },
      {
        Header: "Order Date",
        accessor: "orderDate",
        Cell: ({ row: { original } }: Cell<Order>) => moment(original?.createdAt).format("DD.MM.YYYY"),
      },
      {
        Header: "Actions",
        Cell: ({
          row: {
            original: { orderNumber },
          },
        }: Cell<Order>) => (
          <>
            <ButtonGroup color="secondary" aria-label="outlined secondary button group">
              <Button
                onClick={() => {
                  setSelectedRowId(orderNumber);
                  setOpenRefundNowDialog(true);
                }}
              >
                Refund Now
              </Button>
              <Button
                onClick={() => {
                  setSelectedRowId(orderNumber);
                  setOpenRejectRefundDialog(true);
                }}
              >
                Reject Refund
              </Button>
            </ButtonGroup>
          </>
        ),
        disableSortBy: true,
      },
    ],
    [],
  );

  const RefundDialog = () => {
    return (
      <ConfirmDialog
        open={openRefundNowDialog}
        onSave={() => {
          refundOrder(selectedRowId);
          setOpenRefundNowDialog(false);
          refetch();
        }}
        onClose={() => setOpenRefundNowDialog(false)}
      >
        Are you sure to refund this order?
      </ConfirmDialog>
    );
  };

  const RejectDialog = () => {
    return (
      <ConfirmDialog
        open={openRejectRefundDialog}
        onSave={() => {
          rejectRefund(selectedRowId);
          setOpenRejectRefundDialog(false);
          refetch();
        }}
        onClose={() => setOpenRejectRefundDialog(false)}
      >
        <p>Please enter reject reason</p>
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={2}
          style={{ justifyContent: "center" }}
          variant="outlined"
          onChange={({ target: { value } }) => setRejectReason(value)}
        />
      </ConfirmDialog>
    );
  };
  const handlePaginationChange = ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
    qb.setPage(pageIndex).setLimit(pageSize);
    refetch();
  };

  const handleSortChange = (sortOptions: QuerySort[]) => {
    delete qb.queryObject[qb.options.paramNamesMap?.sort as string];
    sortOptions.forEach(value => {
        qb.sortBy(value);
    });
    refetch();
  };

  return (
    <div>
      <RefundDialog />
      <RejectDialog />
      <Typography variant="h5">Refund Requests</Typography>
      {!isLoading && (
        <FindsTable
          columns={columns}
          data={items}
          meta={meta}
          onPaginationChange={handlePaginationChange}
          onSortChange={handleSortChange}
        />
      )}
    </div>
  );
};

export default Refunds;
