import React from "react";
import { Typography } from "@material-ui/core";

const Home = () => {
  return (
    <Typography component="h1" variant="h6" color="inherit" noWrap>
      Welcome to Finds Admin Panel. Please choose the appropriate action from the left menu.
    </Typography>
  );
};

export default Home;
