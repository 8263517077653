import { Button } from "@material-ui/core";
import { QuerySort } from "@oktein/crud-request";
import React from "react";
import { Cell, Column, Row } from "react-table";
import { ConfirmDialog, FindsTable, EditableCell } from "../../../../components";
import { CurationProducts, Product } from "../../../../types";

const ProductTable = ({
  data,
  isLoading,
  onPaginationChange,
  onSortChange,
  onRowDelete,
  onRowUpdate,
}: {
  data?: CurationProducts;
  isLoading: boolean;
  onRowDelete: (id: string) => void;
  onRowUpdate: (payload: { productId: string; sortOrder: number }) => void;
  onPaginationChange: ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => void;
  onSortChange: (sortOptions: QuerySort[]) => void;
}) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");

  const { meta, items } = data || {};

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Product Id",
        accessor: "product.id",
      },
      {
        Header: "Product Name",
        accessor: "product.itemName",
      },
      {
        Header: "Order",
        accessor: "sortOrder",
        Cell: EditableCell,
      },
      {
        Header: "Actions",
        Cell: ({
          row: {
            original: { id },
          },
        }: Cell<Product>) => (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setSelectedRowId(id);
              setOpenDialog(true);
            }}
          >
            Remove
          </Button>
        ),
        disableSortBy: true,
      },
    ],
    [],
  );

  const handleCellValueChange = (row: Row<Product>, _column: Column, value: string) => {
    onRowUpdate({ productId: row.original.id, sortOrder: +value });
  };

  return (
    <>
      <ConfirmDialog
        open={openDialog}
        onSave={() => {
          onRowDelete(selectedRowId);
          setOpenDialog(false);
        }}
        onClose={() => setOpenDialog(false)}
        confirmText="Yes"
      >
        Are you sure you want to delete the product curation?
      </ConfirmDialog>
      {!isLoading ? (
        <FindsTable
          columns={columns}
          data={items}
          meta={meta}
          onPaginationChange={onPaginationChange}
          onSortChange={onSortChange}
          onCellValueChange={handleCellValueChange}
        />
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default ProductTable;
