import { CurationStream, CurationStreams } from './../types/Stream';
import { ApiClient } from "./api-client.service";
import { AxiosResponse } from "axios";
import { RequestQueryBuilder } from "@oktein/crud-request";
import { CurationProducts } from "../types";

export class StreamCurationService extends ApiClient {
   
  async getFeedStreamCuration(queryBuilder: RequestQueryBuilder): Promise<CurationStreams | any> {
    const query = queryBuilder.query(false).replace("sort[0]","sort").split(',')[0];
    const result: AxiosResponse<CurationProducts | any> = await this.get(
      `/search-stream-curation/detail?${query}`,
    );
    const curationData = result.data;
    return curationData;
  }
  async getStreamPageCuration(queryBuilder: RequestQueryBuilder): Promise<CurationStreams | any> {
    const query = queryBuilder.query(false).replace("sort[0]","sort").split(',')[0];
    const result: AxiosResponse<CurationProducts | any> = await this.get(
      `/stream-curation/search?${query}`,
    );
    const curationData = result.data;
    return curationData;
  }

  async updateStreamCurationSortOrder(id: string, body: { sortOrder: number }): Promise<CurationStreams> {
    const result: AxiosResponse<CurationStreams> = await this.post(`/stream-curation/sortOrder/${id}`, body);
    return result.data;
  }
 
  async getStreams(queryBuilder: RequestQueryBuilder): Promise<CurationStreams> {
    const f = queryBuilder.query(false).replace("sort[0]","sort").replace('s={"filter":"','filter=').replace('"}','');
    const result: AxiosResponse<CurationStreams> = await this.get(`/stream-curation/streams?${f}`);
    return result.data;
  }
  async getStreamsWitoutSearch(queryBuilder: RequestQueryBuilder): Promise<CurationStreams> {
    const f = queryBuilder.query(false).replace("sort[0]","sort").replace('s={"filter":"','filter=').replace('"}','');
    const result: AxiosResponse<CurationStreams> = await this.get(`/search-stream-curation/streams?${f}`);
    return result.data;
  }
  async saveFeedStreamCuration(body: { streamId: string; sortOrder: number,scheduleDate: string }): Promise<CurationStream> {
    const result: AxiosResponse<CurationStream> = await this.post("/stream-curation", body);
    return result.data;
  }
  async deleteFeedStreamCuration(id: string): Promise<CurationStream> {
    const result: AxiosResponse<CurationStream> = await this.delete(`/stream-curation/${id}`);
    return result.data;
  }

  async deleteAllFeedStreamCuration(feedId: any): Promise<any> {
    const body = { feedIds:feedId  };
    const result: AxiosResponse<any> = await this.post("/stream-curation/delete-with-id", body);
    return result.data;
  }

  async saveSearchStreamCuration(body: { streamId: string; sortOrder: number }): Promise<CurationStream> {
    const result: AxiosResponse<CurationStream> = await this.post("/search-product-curation/stream", body);
    return result.data;
  }
  async updateStreamSortOrder(id: string, body: { sortOrder: number }): Promise<CurationStreams> {
    const result: AxiosResponse<CurationStreams> = await this.post(`/search-stream-curation/sortOrder/${id}`, body);
    return result.data;
  }
  async deleteSearchStreamCuration(id: string): Promise<CurationStream> {
    const result: AxiosResponse<CurationStream> = await this.delete(`/stream-curation/delete/${id}`);
    return result.data;
  }
}
