import { ApiClient } from "./api-client.service";
import { AxiosResponse } from "axios";
import { RequestQueryBuilder } from "@oktein/crud-request";
import {Discounts, CreateDiscount, Discount, UpdateDiscount, UserEntity} from "../types";

export class DiscountService extends ApiClient {
  async getDiscounts(queryBuilder: RequestQueryBuilder): Promise<Discounts | any> {
    const result: AxiosResponse<Discounts | any> = await this.get(`/user-discount-code?${queryBuilder.query(false).replace("sort[0]","sort")}`);
    const discountData = result.data;
    discountData.items.map((item: { user: UserEntity; }) => {
      if (!item.user) {
        item.user = new UserEntity();
      }
      return item;
    })
    return discountData;
  }

  async createDiscount(body: CreateDiscount) {
    const result: AxiosResponse<Discount> = await this.post("/user-discount-code", body);
    return result.data;
  }

  async updateDiscount(id: string, body: UpdateDiscount) {
    const result: AxiosResponse<Discount> = await this.patch(`/user-discount-code/${id}`, body);
    return result.data;
  }

  async deleteDiscount(id: string): Promise<void> {
    const result: AxiosResponse<void> = await this.delete(`/user-discount-code/${id}`);
    return result.data;
  }
  async deleteAll(dicsountId: any): Promise<any> {
    const body = { discountIds:dicsountId  };
    const result: AxiosResponse<any> = await this.post("/user-discount-code/delete-with-id", body);
    return result.data;
  }
}
