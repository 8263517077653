import React from "react";
import { Typography, Button, ButtonGroup, Box } from "@material-ui/core";
import { QuerySort, RequestQueryBuilder } from "@oktein/crud-request";
import { useMutation, useQuery } from "react-query";
import { useServicesStore } from "../../hooks";
import { Column } from "react-table";
import { FindsTable, ConfirmDialog, FormDialog } from "../../components";
import { toast } from "react-toastify";
import { Color, CreateColor, UpdateColor } from "../../types/Color";
import AddOrCreateColourForm from "./form";

const qb = RequestQueryBuilder.create({ page: 1, limit: 5 });
export interface EditValues {
  id: string;
  body: UpdateColor;
}
const ColoursPage = () => {
  const { colorService } = useServicesStore();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openCreateOrEditDialog, setOpenCreateOrEditDialog] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<Color>();
  const [dialogMode, setDialogMode] = React.useState("create");

  const { data, isLoading, refetch } = useQuery("colours", () => colorService.getColors(qb), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const { mutate: createColour } = useMutation((body: CreateColor) => colorService.createColor(body), {
    onSuccess: () => {
      toast.success("Colour successfully created!");
      refetch();
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });
  const { mutate: updateColour } = useMutation(
    (values: EditValues) => colorService.updateColor(values.id, values.body),
    {
      onSuccess: () => {
        toast.success("Colour successfully updated!");
        refetch();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );
  const { mutate: deleteColour } = useMutation((id: string) => colorService.deleteColor(id), {
    onSuccess: () => {
      toast.success("Colour successfully deleted!");
      refetch();
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });

  const { meta, items } = data || {};

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Colour Id",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Hex Code",
        accessor: "hexCode",
      },
      {
        Header: "Hex Color",
        Cell: ({ row: { original } }) => {
          const { hexCode } = original as Color;
          return (
            <input
              style={{
                backgroundColor: "white",
                border: "none",
                width: "100%",
              }}
              disabled
              name="hexCode"
              type="color"
              value={hexCode}
            />
          );
        },
      },
      {
        Header: "Sort Order",
        accessor: "sortOrder",
      },

      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <ButtonGroup color="secondary" aria-label="outlined secondary button group">
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setDialogMode("update");
                setSelectedRow(original as Color);
                setOpenCreateOrEditDialog(true);
              }}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                setSelectedRow(original as Color);
                setOpenDeleteDialog(true);
              }}
            >
              Delete
            </Button>
          </ButtonGroup>
        ),
        disableSortBy: true,
      },
    ],
    [],
  );

  const handlePaginationChange = ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
    qb.setPage(pageIndex).setLimit(pageSize);
    refetch();
  };

  const handleSortChange = (sortOptions: QuerySort[]) => {
    delete qb.queryObject[qb.options.paramNamesMap?.sort as string];
    sortOptions.forEach(value => {
        qb.sortBy(value);
    });
    refetch();
  };

  return (
    <div>
      <ConfirmDialog
        open={openDeleteDialog}
        onSave={() => {
          deleteColour(selectedRow?.id as string);
          setOpenDeleteDialog(false);
        }}
        onClose={() => {
          setSelectedRow({} as Color);
          setOpenDeleteDialog(false);
        }}
      >
        Are you sure to delete this colour?
      </ConfirmDialog>
      <FormDialog
        onClose={() => {
          setDialogMode("create");
          setSelectedRow({} as Color);
        }}
        open={openCreateOrEditDialog}
      >
        {dialogMode === "create" ? "Add a new Colour" : "Update Colour"}
        <AddOrCreateColourForm
          createColour={createColour}
          updateColour={updateColour}
          dialogMode={dialogMode}
          selectedRow={selectedRow as Color}
          openDialog={setOpenCreateOrEditDialog}
        />
      </FormDialog>
      <Box display="flex" justifyContent="space-between" mb={2} onClick={() => setOpenCreateOrEditDialog(true)}>
        <Typography variant="h5">Colours</Typography>
        <Button color="secondary" variant="contained">
          Add New Colour
        </Button>
      </Box>
      {!isLoading && (
        <FindsTable
          columns={columns}
          data={items}
          meta={meta}
          onPaginationChange={handlePaginationChange}
          onSortChange={handleSortChange}
        />
      )}
    </div>
  );
};

export default ColoursPage;
