import { ApiClient } from "./api-client.service";
import { Cities, City, CreateCity, UpdateCity } from "../types";
import { AxiosResponse } from "axios";
import { RequestQueryBuilder } from "@oktein/crud-request";

export class CityService extends ApiClient {
  async getCities(queryBuilder: RequestQueryBuilder): Promise<Cities> {
    const result: AxiosResponse<Cities> = await this.get(`/cities?${queryBuilder.query(false).replace("sort[0]","sort")}`);
    return result.data;
  }

  async getCityById(id: string): Promise<City> {
    const result: AxiosResponse<City> = await this.get(`/cities/${id}`);
    return result.data;
  }

  async createCity(body: CreateCity): Promise<City> {
    const result: AxiosResponse<City> = await this.post("/cities", body);
    return result.data;
  }

  async updateCity(id: string, body: UpdateCity): Promise<City> {
    const result: AxiosResponse<City> = await this.patch(`/cities/${id}`, body);
    return result.data;
  }

  async deleteCity(id: string): Promise<void> {
    const result: AxiosResponse<void> = await this.delete(`/cities/${id}`);
    return result.data;
  }
}
