import React from "react";
import { Button, Grid } from "@material-ui/core";
import { QueryFilter, RequestQueryBuilder } from "@oktein/crud-request";
import { Formik } from "formik";
import { FilterTextField, SelectInputField } from "../../../components";
import { useServicesStore } from "../../../hooks";

const Filters = ({ onFiltersChange }: any) => {
  const services = useServicesStore();
  return (
    <Formik
      initialValues={{ productName: "" }}
      onSubmit={(values: any, { setSubmitting }) => {
        setSubmitting(false);

        const filters: QueryFilter[] = [];
        if (values.brands) {
          filters.push(
            ...values.brands.map((v: any) => ({ operator: "eq", value: v.value, field: "brand.id" } as QueryFilter)),
          );
        }
        if (values.conditions) {
          filters.push(
            ...values.conditions.map(
              (v: any) => ({ operator: "eq", value: v.value, field: "productCondition.id" } as QueryFilter),
            ),
          );
        }
        if (values.users) {
          filters.push(
            ...values.users.map((v: any) => ({ operator: "eq", value: v.value, field: "userId" } as QueryFilter)),
          );
        }
        // if (values.category) {
        //   filters.push(
        //     ...values.category.map((v: any) => ({ operator: "eq", value: v.value, field: "userId" } as QueryFilter)),
        //   );
        // }
        if (values.subCategory) {
          filters.push(
            ...values.subCategory.map(
              (v: any) => ({ operator: "eq", value: v.value, field: "productSubCategory.id" } as QueryFilter),
            ),
          );
        }
        onFiltersChange({ filters, search: values.productName });
      }}
    >
      {({ handleSubmit, isValid, resetForm, submitForm, values }) => (
        <form onSubmit={handleSubmit} style={{ margin: "10px 0" }}>
          <Grid container spacing={1} style={{ margin: "10px 0" }}>
            <Grid item sm={6} md={4}>
              <SelectInputField
                name="conditions"
                queryKey="conditions"
                queryFunction={(qb: RequestQueryBuilder) => services.conditionService.getConditions(qb)}
                queryFilter={val => ({ $or: [{ name: { $cont: val } }] })}
                //@ts-expect-error
                selectProps={{ isMulti: true, placeholder: "Select Conditions..." }}
              />
            </Grid>
            <Grid item sm={6} md={4}>
              <SelectInputField
                name="brands"
                queryKey="brands"
                queryFunction={(qb: RequestQueryBuilder) => services.brandService.getBrands(qb)}
                queryFilter={val => ({ $or: [{ name: { $cont: val } }] })}
                //@ts-expect-error
                selectProps={{ isMulti: true, placeholder: "Select Brands..." }}
              />
            </Grid>
            <Grid item sm={6} md={4}>
              <SelectInputField
                name="users"
                queryKey="users"
                queryFunction={(qb: RequestQueryBuilder) => services.userService.getUsers(qb)}
                queryFilter={val => ({ $or: [{ userName: { $cont: val } }] })}
                selectFunction={data => {
                  const { items = [] } = data || {};
                  const result = items.map((item: any) => ({
                    label: `${item.firstName} ${item.lastName} (${item.userName})`,
                    value: item.id,
                  }));
                  return result;
                }}
                //@ts-expect-error
                selectProps={{ isMulti: true, placeholder: "Select Users..." }}
              />
            </Grid>
            <Grid item sm={6} md={4}>
              <FilterTextField name="productName" textFieldProps={{ placeholder: "Product Name" }} />
            </Grid>
            <Grid item sm={6} md={4}>
              <SelectInputField
                name="category"
                queryKey="category"
                queryFunction={(qb: RequestQueryBuilder) => services.categoryService.getCategories(qb)}
                queryFilter={val => ({ $or: [{ name: { $cont: val } }] })}
                //@ts-expect-error
                selectProps={{ isMulti: true, placeholder: "Select Categories..." }}
              />
            </Grid>
            <Grid item sm={6} md={4}>
              <SelectInputField
                name="subCategory"
                queryKey="subCategory"
                queryFunction={(qb: RequestQueryBuilder) => {
                  return services.subCategoryService.getSubCategories(qb);
                }}
                queryFilter={val => ({ $or: [{ name: { $cont: val } }] })}
                //@ts-expect-error
                selectProps={{ isMulti: true, placeholder: "Select Sub Categories..." }}
              />
            </Grid>
          </Grid>

          <Button color="secondary" variant="contained" style={{ height: "100%" }} type="submit" fullWidth>
            Search
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default Filters;
