import { Typography, Grid, Paper, makeStyles, createStyles, Theme } from "@material-ui/core";
import Categories from "./Categories";
import Users from "./Users";
import Products from "./Products";
import Stream from "./Stream";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexDirection: "column",
    },
    title: {
      padding: `${theme.spacing(1)}px 0;`,
    },
    paper: {
      padding: theme.spacing(2),
    },
  }),
);

const SearchPageCuration = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container spacing={3}>
      <Typography variant="h5" className={classes.title}>
        Search Page Content
      </Typography>

      <Grid md={12} component={Paper} className={classes.paper}>
        <Grid container direction="row" justifyContent="space-evenly" alignItems="flex-start" spacing={2}>
          <Grid item sm={12} md={6}>
            <Categories />
          </Grid>
          <Grid item sm={12} md={6}>
            <Users />
          </Grid>
          <Grid item sm={12} md={12}>
            <Products />
          </Grid>
          <Grid item sm={12} md={12}>
            <Stream />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SearchPageCuration;
