import { ApiClient } from "./api-client.service";
import { LoginRequest, LoginResponse } from "../types";
import { AxiosResponse } from "axios";

export class AuthenticationService extends ApiClient {
  async login(body: LoginRequest): Promise<LoginResponse> {
    const result: AxiosResponse<LoginResponse> = await this.post("/authentication/loginAdmin", body);
    if (result.headers.hasOwnProperty("Authorization")) {
      this.bearerToken = result.headers["Authorization"].split("Bearer")[1];
    }
    if (result.data) {
      localStorage.setItem("token", result.data.accessToken);
      this.bearerToken = result.data.accessToken;
    }
    return result.data;
  }
}
