import React from "react";
import { Typography, Button, ButtonGroup, Box } from "@material-ui/core";
import { QuerySort, RequestQueryBuilder } from "@oktein/crud-request";
import { useMutation, useQuery } from "react-query";
import { useServicesStore } from "../../hooks";
import { Column } from "react-table";
import { FindsTable, ConfirmDialog, FormDialog } from "../../components";
import { toast } from "react-toastify";
import { SubCategory, CreateSubCategory, UpdateSubCategory } from "../../types/Sub-Category";
import AddOrCreateSubCategoryForm from "./form";

const qbSubCategory = RequestQueryBuilder.create({ page: 1, limit: 5 });
const qbCategory = RequestQueryBuilder.create({ page: 1 });
export interface EditValues {
  id: string;
  body: UpdateSubCategory;
}
interface IProps {
  match: {
    params: {
      id: string;
    };
  };
}
const SubCategoriesPage = ({
  match: {
    params: { id },
  },
}: IProps) => {
  const qbSubCategoryById = RequestQueryBuilder.create({ page: 1, limit: 5 });

  const { subCategoryService, categoryService } = useServicesStore();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openCreateOrEditDialog, setOpenCreateOrEditDialog] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<SubCategory>();
  const [dialogMode, setDialogMode] = React.useState("create");
  const qb = id ? qbSubCategoryById : qbSubCategory;
  const dataService = id
    ? () => subCategoryService.getSubCategoriesByCategoryId(qb, id)
    : () => subCategoryService.getSubCategories(qb);
  const queryName = id ? "subCategoriesById" : "subCategories";
  const { data, isLoading, refetch } = useQuery(queryName, dataService, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const { data: categoryData } = useQuery("categoriesData", () => categoryService.getCategories(qbCategory), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const { mutate: createSubCategory } = useMutation(
    (body: CreateSubCategory) => subCategoryService.createSubCategory(body),
    {
      onSuccess: () => {
        toast.success("Sub Category successfully created!");
        refetch();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );
  const { mutate: updateSubCategory } = useMutation(
    (values: EditValues) => subCategoryService.updateSubCategory(values.id, values.body),
    {
      onSuccess: () => {
        toast.success("Sub Category successfully updated!");
        refetch();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );
  const { mutate: deleteSubCategory } = useMutation((id: string) => subCategoryService.deleteSubCategory(id), {
    onSuccess: () => {
      toast.success("Sub Category successfully deleted!");
      refetch();
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });

  const { meta, items } = data || {};

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Sub Category Id",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Category Name",
        accessor: "productCategory.name",
      },
      {
        Header: "Sort Order",
        accessor: "sortOrder",
      },

      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <ButtonGroup color="secondary" aria-label="outlined secondary button group">
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setDialogMode("update");
                setSelectedRow(original as SubCategory);
                setOpenCreateOrEditDialog(true);
              }}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                setSelectedRow(original as SubCategory);
                setOpenDeleteDialog(true);
              }}
            >
              Delete
            </Button>
          </ButtonGroup>
        ),
        disableSortBy: true,
      },
    ],
    [],
  );

  const handlePaginationChange = ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
    qb.setPage(pageIndex).setLimit(pageSize);
    refetch();
  };

  const handleSortChange = (sortOptions: QuerySort[]) => {
    delete qb.queryObject[qb.options.paramNamesMap?.sort as string];
    sortOptions.forEach(value => {
        qb.sortBy(value);
    });
    refetch();
  };

  return (
    <div>
      <ConfirmDialog
        open={openDeleteDialog}
        onSave={() => {
          deleteSubCategory(selectedRow?.id as string);
          setOpenDeleteDialog(false);
        }}
        onClose={() => {
          setSelectedRow({} as SubCategory);
          setOpenDeleteDialog(false);
        }}
      >
        Are you sure to delete this sub category?
      </ConfirmDialog>
      <FormDialog
        open={openCreateOrEditDialog}
        onClose={() => {
          setDialogMode("create");
          setSelectedRow({} as SubCategory);
        }}
      >
        {dialogMode === "create" ? "Add a new Sub Category" : "Update Sub Category"}
        <AddOrCreateSubCategoryForm
          createSubCategory={createSubCategory}
          updateSubCategory={updateSubCategory}
          dialogMode={dialogMode}
          categoryData={categoryData}
          selectedRow={selectedRow as SubCategory}
          openDialog={setOpenCreateOrEditDialog}
        />
      </FormDialog>
      <Box display="flex" justifyContent="space-between" mb={2} onClick={() => setOpenCreateOrEditDialog(true)}>
        <Typography variant="h5">Sub Categories</Typography>
        <Button color="secondary" variant="contained">
          Add New Sub Category
        </Button>
      </Box>

      {!isLoading && (
        <FindsTable
          columns={columns}
          data={items}
          meta={meta}
          onPaginationChange={handlePaginationChange}
          onSortChange={handleSortChange}
        />
      )}
    </div>
  );
};

export default SubCategoriesPage;
