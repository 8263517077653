import { ApiClient } from "./api-client.service";
import { AxiosResponse } from "axios";
import { ProfileComments, ProductComments } from "../types";
import { RequestQueryBuilder } from "@oktein/crud-request";

export class CommentService extends ApiClient {
  async getProfileComments(queryBuilder: RequestQueryBuilder): Promise<ProfileComments> {
    const result: AxiosResponse<ProfileComments> = await this.get(
      `/profile-comments-admin?${queryBuilder.query(false).replace("sort[0]","sort")}`,
    );
    return result.data;
  }

  async getProductComments(queryBuilder: RequestQueryBuilder): Promise<ProductComments> {
    const result: AxiosResponse<ProductComments> = await this.get(
      `/product-comments-admin?${queryBuilder.query(false).replace("sort[0]","sort")}`,
    );
    return result.data;
  }

  async deleteProfileComment(id: string): Promise<void> {
    const result: AxiosResponse<void> = await this.delete(`/profile-comments-admin/${id}`);
    return result.data;
  }
  async deleteProductComment(id: string): Promise<void> {
    const result: AxiosResponse<void> = await this.delete(`/product-comments-admin/${id}`);
    return result.data;
  }
}
