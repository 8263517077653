import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core";
import { store } from "./store";
import * as serviceWorker from "./serviceWorker";
import { theme } from "./constants/theme";
import "./index.css";
import { Dashboard, Login } from "./pages";
import { PublicRoute, PrivateRoute } from "./components";
import { ServicesProvider } from "./hooks";
import { initialize } from "./services/initializeServices";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient();

const services = initialize();

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ServicesProvider data={services}>
          <ThemeProvider theme={theme}>
            <Router>
              <Switch>
                <PublicRoute restricted exact path="/login" component={Login} />
                <PrivateRoute path="/" component={Dashboard} />
              </Switch>
            </Router>
          </ThemeProvider>
        </ServicesProvider>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
      <ToastContainer />
    </QueryClientProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
