import React from "react";
import { Typography, Button, ButtonGroup } from "@material-ui/core";
import { QuerySort, RequestQueryBuilder } from "@oktein/crud-request";
import { useMutation, useQuery } from "react-query";
import { useServicesStore } from "../../hooks";
import { Cell, Column } from "react-table";
import { FindsTable, Search, ConfirmDialog } from "../../components";
import { toast } from "react-toastify";
import { Rating } from "../../types";

const qb = RequestQueryBuilder.create({ page: 1, limit: 5 });

const RatingsPage = () => {
  const { ratingService } = useServicesStore();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");

  const { data, isLoading, refetch } = useQuery("ratings", () => ratingService.getRatings(qb), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const { mutate: deleteRating } = useMutation((id: string) => ratingService.deleteRating(id), {
    onSuccess: () => {
      toast.success("Rating successfully deleted!");
      refetch();
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });

  const { meta, items } = data || {};

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Rating",
        accessor: "givenStar",
      },
      {
        Header: "Order",
        accessor: "orderId",
      },
      {
        Header: "Comment Owner",
        accessor: "rater.firstName",
        maxWidth: 50,
        Cell: ({ row: { original } }: Cell<Rating>) => (
          <div style={{ wordBreak: "break-all" }}>{`${original.rater.firstName} ${original.rater.lastName}`}</div>
        ),
      },
      {
        Header: "Comment",
        accessor: "feedback",
      },
      {
        Header: "Comment Date",
        accessor: "createdAt",
      },
      {
        Header: "Actions",
        Cell: ({
          row: {
            original: { id },
          },
        }: Cell<Rating>) => (
          <>
            <ButtonGroup color="secondary" aria-label="outlined secondary button group">
              <Button
                onClick={() => {
                  setSelectedRowId(id);
                  setOpenDialog(true);
                }}
              >
                Delete
              </Button>
            </ButtonGroup>
          </>
        ),
        disableSortBy: true,
      },
    ],
    [],
  );

  const handleSearch = (value: string) => {
    if (value.length > 0) {
      qb.search({ $or: [{ "rater.email": { $cont: value } }, { "rater.userName": { $cont: value } }] });
    } else {
      delete qb.queryObject[qb.options.paramNamesMap?.search as string];
    }
    qb.setPage(0).setLimit(5);
    refetch();
  };

  const handlePaginationChange = ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
    qb.setPage(pageIndex).setLimit(pageSize);
    refetch();
  };

  const handleSortChange = (sortOptions: QuerySort[]) => {
    delete qb.queryObject[qb.options.paramNamesMap?.sort as string];
    sortOptions.forEach(value => {
        qb.sortBy(value);
    });
    refetch();
  };

  return (
    <div>
      <ConfirmDialog
        open={openDialog}
        onSave={() => {
          deleteRating(selectedRowId);
          setOpenDialog(false);
          refetch();
        }}
        onClose={() => setOpenDialog(false)}
      >
        Are you sure to delete this rating?
      </ConfirmDialog>
      <Typography variant="h5">Ratings</Typography>
      <Search textFieldProps={{ placeholder: "Search by username, email, full name" }} onSubmit={handleSearch} />
      {!isLoading && (
        <FindsTable
          columns={columns}
          data={items}
          meta={meta}
          onPaginationChange={handlePaginationChange}
          onSortChange={handleSortChange}
        />
      )}
    </div>
  );
};

export default RatingsPage;
