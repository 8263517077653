import React from "react";
import { Typography, Button, ButtonGroup, Box } from "@material-ui/core";
import { QuerySort, RequestQueryBuilder } from "@oktein/crud-request";
import { useMutation, useQuery } from "react-query";
import { useServicesStore } from "../../hooks";
import { Column } from "react-table";
import { FindsTable, ConfirmDialog, FormDialog } from "../../components";
import { toast } from "react-toastify";
import { Condition, CreateCondition, UpdateCondition } from "../../types/Condition";
import AddOrCreateConditionForm from "./form";

const qb = RequestQueryBuilder.create({ page: 1, limit: 5 });
export interface EditValues {
  id: string;
  body: UpdateCondition;
}
const ConditionsPage = () => {
  const { conditionService } = useServicesStore();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openCreateOrEditDialog, setOpenCreateOrEditDialog] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<Condition>();
  const [dialogMode, setDialogMode] = React.useState("create");

  const { data, isLoading, refetch } = useQuery("conditions", () => conditionService.getConditions(qb), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const { mutate: createCondition } = useMutation((body: CreateCondition) => conditionService.createCondition(body), {
    onSuccess: () => {
      toast.success("Condition successfully created!");
      refetch();
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });
  const { mutate: updateCondition } = useMutation(
    (values: EditValues) => conditionService.updateCondition(values.id, values.body),
    {
      onSuccess: () => {
        toast.success("Condition successfully updated!");
        refetch();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );
  const { mutate: deleteCondition } = useMutation((id: string) => conditionService.deleteCondition(id), {
    onSuccess: () => {
      toast.success("Condition successfully deleted!");
      refetch();
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });

  const { meta, items } = data || {};

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Condition Id",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Condition Desc.",
        accessor: "description",
      },
      {
        Header: "Sort Order",
        accessor: "sortOrder",
      },

      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <ButtonGroup color="secondary" aria-label="outlined secondary button group">
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setDialogMode("update");
                setSelectedRow(original as Condition);
                setOpenCreateOrEditDialog(true);
              }}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                setSelectedRow(original as Condition);
                setOpenDeleteDialog(true);
              }}
            >
              Delete
            </Button>
          </ButtonGroup>
        ),
        disableSortBy: true,
      },
    ],
    [],
  );

  const handlePaginationChange = ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
    qb.setPage(pageIndex).setLimit(pageSize);
    refetch();
  };

  const handleSortChange = (sortOptions: QuerySort[]) => {
    delete qb.queryObject[qb.options.paramNamesMap?.sort as string];
    sortOptions.forEach(value => {
        qb.sortBy(value);
    });
    refetch();
  };

  return (
    <div>
      <ConfirmDialog
        open={openDeleteDialog}
        onSave={() => {
          deleteCondition(selectedRow?.id as string);
          setOpenDeleteDialog(false);
        }}
        onClose={() => {
          setSelectedRow({} as Condition);
          setOpenDeleteDialog(false);
        }}
      >
        Are you sure to delete this condition?
      </ConfirmDialog>
      <FormDialog
        open={openCreateOrEditDialog}
        onClose={() => {
          setDialogMode("create");
          setSelectedRow({} as Condition);
        }}
      >
        {dialogMode === "create" ? "Add a new Condition" : "Update Condition"}
        <AddOrCreateConditionForm
          createCondition={createCondition}
          updateCondition={updateCondition}
          dialogMode={dialogMode}
          selectedRow={selectedRow as Condition}
          openDialog={setOpenCreateOrEditDialog}
        />
      </FormDialog>
      <Box display="flex" justifyContent="space-between" mb={2} onClick={() => setOpenCreateOrEditDialog(true)}>
        <Typography variant="h5">Conditions</Typography>
        <Button color="secondary" variant="contained">
          Add New Condition
        </Button>
      </Box>

      {!isLoading && (
        <FindsTable
          columns={columns}
          data={items}
          meta={meta}
          onPaginationChange={handlePaginationChange}
          onSortChange={handleSortChange}
        />
      )}
    </div>
  );
};

export default ConditionsPage;
