import {
  AdminUserService,
  UserService,
  AuthenticationService,
  BrandService,
  CategoryService,
  SubCategoryService,
  CityService,
  ColorService,
  ConditionService,
  ProductService,
  CommentService,
  RatingService,
  SearchCurationService,
  FeedCurationService,
  OrderService,
  DiscountService,
  FindsSettingsService,
  StreamCurationService,
} from ".";
import { SellerAnalyticService } from "./sellerAnalytic.service";

export const initialize = () => ({
  adminUserService: new AdminUserService(),
  authService: new AuthenticationService(),
  brandService: new BrandService(),
  categoryService: new CategoryService(),
  commentService: new CommentService(),
  cityService: new CityService(),
  colorService: new ColorService(),
  conditionService: new ConditionService(),
  productService: new ProductService(),
  subCategoryService: new SubCategoryService(),
  userService: new UserService(),
  discountService: new DiscountService(),
  sellerAnalyticService: new SellerAnalyticService(),
  ratingService: new RatingService(),
  searchCurationService: new SearchCurationService(),
  feedCurationService: new FeedCurationService(),
  streamCurationService: new StreamCurationService(),
  orderService: new OrderService(),
  settingsService: new FindsSettingsService(),
});

export type Services = ReturnType<typeof initialize>;
