import { Box, Button, DialogActions, makeStyles, MenuItem } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import React from "react";

import { RequestQueryBuilder } from "@oktein/crud-request";
import { Formik } from "formik";
import { UseMutateFunction } from "react-query/types/react/types";
import { EditValues } from "..";
import { SelectInputField } from "../../../components";
import { useServicesStore } from "../../../hooks";
import moment from "moment";
import { CreateDiscount, Discount, Users } from "../../../types";
import validationSchemaDiscountCode from "../../../utils/validationSchemaDiscountCodes";
import dayjs from "dayjs";

const useStyles = makeStyles(theme => ({
  cancelButton: {
    textTransform: "none",
  },
  saveButton: {
    fontSize: "1.1em",
    paddingLeft: "1.2em",
    paddingRight: "1.2em",
    textTransform: "none",
  },
}));
interface IProps {
  createDiscount: UseMutateFunction<Discount, unknown, CreateDiscount, unknown>;
  updateDiscount: UseMutateFunction<Discount, unknown, EditValues, unknown>;
  dialogMode: string;
  selectedRow: Discount;
  openDialog: React.Dispatch<React.SetStateAction<boolean>>;
  newRow: boolean;
}

const discountTypes = [
  {
    name: "Amount",
    value: "amount",
  },
  {
    name: "Percentage",
    value: "percentage",
  },
];
const AddOrCreateDiscountForm = ({ createDiscount, updateDiscount, dialogMode, selectedRow, openDialog, newRow }: IProps) => {

  const templateValues = {
      discountCode: "",
      startDate: moment().format("DD.MM.YYYY"),
      endDate: moment().add(1, 'day').format("DD.MM.YYYY"),
      discountType: "amount",
      discountAmount: 0,
      perUserUsageLimit: 0,
      overallUsageLimit: 0,
      userId: null
    };
  console.log(selectedRow);
    const initialValues = selectedRow?.id
    ? {
        discountCode: selectedRow.discountCode,
        userId: {
          label: `${selectedRow.user.firstName} ${selectedRow.user.lastName} (${selectedRow.user.userName})`,
          value: selectedRow.user.id,
        },
        discountType: selectedRow.discountType,
        discountAmount: selectedRow.discountAmount,
        perUserUsageLimit: selectedRow.perUserUsageLimit,
        overallUsageLimit: selectedRow.overallUsageLimit,
        startDate: selectedRow.startDate,
        endDate: selectedRow.endDate,
      }
    : templateValues;

    console.log('selectedRow',selectedRow) 
  const cs = useStyles();
  const { userService } = useServicesStore();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaDiscountCode}
      validateOnMount
      onSubmit={(values, actions) => {
        const submitValues = {
          ...values,
          userId: values.userId?.value,
          startDate: values.startDate,
          endDate: values.endDate,
        };
        dialogMode === "create"
          ? createDiscount(submitValues)
          : updateDiscount({ id: selectedRow.id, body: submitValues });
        actions.resetForm({
          values: templateValues,
        });
        openDialog(false);
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Box mt={1}>
            {newRow ? (
              <SelectInputField
                name="userId"
                queryKey="discountUser"
                queryFunction={(qb: RequestQueryBuilder) => userService.getUsers(qb)}
                queryFilter={val => ({ $or: [{ userName: { $cont: val } }] })}
                selectFunction={(data: Users) => {
                  const { items = [] } = data || {};
                  const result = items.map(item => ({
                    label: `${item.firstName} ${item.lastName} (${item.userName})`,
                    value: item.id,
                  }));
                  return result;
                }}
                selectProps={{ isMulti: false, placeholder: "Select Users..." }}
              />
            ) : (
              <div>
                {selectedRow.user.firstName} {selectedRow.user.lastName} ({selectedRow.user.userName})
              </div>
            )}
          </Box>
          <Box mt={2}>
            <TextField
              id="startDate"
              name="startDate"
              type="date"
              label="Start Date"
              value={dayjs(values.startDate).format("YYYY-MM-DD")}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
             
              onChange={event => {
                setFieldValue("startDate", event.target.value);
                //setFieldValue("endDate", null);
              }}
            />
            <TextField
              id="endDate"
              type="date"
              label="End Date"
              variant="outlined"
              value={dayjs(values.endDate).format("YYYY-MM-DD")}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{ inputProps: { min: values.startDate, disabled: !values.startDate } }}
              onChange={event => setFieldValue("endDate", event.target.value)}
            />
          </Box>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Discount Code"
            name="discountCode"
            color="secondary"
            disabled={!newRow}
            value={values.discountCode}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            select
            label="Discount Type"
            disabled={!newRow}
            name="discountType"
            color="secondary"
            value={values.discountType}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            {discountTypes.map(option => (
              <MenuItem key={option.name} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            type="number"
            label="Discount Amount"
            disabled={!newRow}
            name="discountAmount"
            color="secondary"
            value={values.discountAmount}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            type="number"
            label="Per User Usage Limit"
            name="perUserUsageLimit"
            color="secondary"
            value={values.perUserUsageLimit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            type="number"
            label="Overall Usage Amount"
            name="overallUsageLimit"
            color="secondary"
            value={values.overallUsageLimit}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <DialogActions>
            <Button onClick={() => openDialog(false)} color="default" className={cs.cancelButton}>
              Cancel
            </Button>
            <Button
              className={cs.saveButton}
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting || !isValid || !dirty}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      )}
    </Formik>
  );
};

export default AddOrCreateDiscountForm;
