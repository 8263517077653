import React from "react";
import { Button, Grid, InputLabel, TextField } from "@material-ui/core";
import { QueryFilter, RequestQueryBuilder } from "@oktein/crud-request";
import { Formik } from "formik";
import { FilterTextField, SelectInputField } from "../../components";
import { useServicesStore } from "../../hooks";
import { OrderStates } from "../../types";
import moment from "moment";

const Filters = ({ onFiltersChange }: any) => {
  const services = useServicesStore();
  return (
    <Formik
      initialValues={{ orderNumber: "" }}
      onSubmit={(values: any, { setSubmitting }) => {
        setSubmitting(false);

        const filters: QueryFilter[] = [];
        if (values.orderState) {
          filters.push(
            ...values.orderState.map(
              (v: any) => ({ operator: "eq", value: v.value, field: "orderState" } as QueryFilter),
            ),
          );
        }
        if (values.seller) {
          filters.push(
            ...values.seller.map(
              (v: any) => ({ operator: "cont", value: v.value, field: "sellerName" } as QueryFilter),
            ),
          );
        }
        if (values.owner) {
          filters.push(
            ...values.owner.map((v: any) => ({ operator: "cont", value: v.value, field: "buyerName" } as QueryFilter)),
          );
        }
        if (values.startDate && values.endDate) {
          filters.push({ operator: "between", value: [values.startDate, values.endDate], field: "createdAt" });
        }
        onFiltersChange({ filters, search: values.orderNumber });
      }}
    >
      {({ handleSubmit, isValid, resetForm, submitForm, values, setFieldValue }) => (
        <form onSubmit={handleSubmit} style={{ margin: "10px 0" }}>
          <Grid container spacing={1} style={{ margin: "10px 0" }}>
            <Grid item sm={6} md={4}>
              <SelectInputField
                name="orderState"
                queryKey="orderState"
                queryFunction={() => Promise.resolve(Object.entries(OrderStates))}
                selectFunction={data => {
                  return data.map((item: any) => ({
                    label: item[1],
                    value: item[0],
                  }));
                }}
                queryFilter={val => ({ $or: [{ orderState: { $cont: val } }] })}
                //@ts-expect-error
                selectProps={{ isMulti: true, placeholder: "Select Order Status..." }}
              />
            </Grid>
            <Grid item sm={6} md={4}>
              <FilterTextField name="orderNumber" textFieldProps={{ placeholder: "Order Number" }} />
            </Grid>
            <Grid item sm={6} md={4}>
              <SelectInputField
                name="seller"
                queryKey="sellerName"
                queryFunction={(qb: RequestQueryBuilder) => services.userService.getUsers(qb)}
                queryFilter={val => ({ $or: [{ userName: { $cont: val } }] })}
                selectFunction={data => {
                  const { items = [] } = data || {};
                  return items.map((item: any) => ({
                    label: `${item.firstName} ${item.lastName} (${item.userName})`,
                    value: item.userName,
                  }));
                }}
                //@ts-expect-error
                selectProps={{ isMulti: true, placeholder: "Select Seller" }}
              />
            </Grid>
            <InputLabel style={{ marginTop: "15px", marginRight: "10px" }}>Date Between :</InputLabel>
            <Grid item sm={6} md={2}>
              <TextField
                id="startDate"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: moment().format("YYYY-MM-DD") } }}
                onChange={event => {
                  setFieldValue("startDate", event.target.value);
                  setFieldValue("endDate", null);
                }}
              />
            </Grid>
            <Grid item sm={6} md={2}>
              <TextField
                id="endDate"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: values.startDate, disabled: !values.startDate } }}
                onChange={event => setFieldValue("endDate", event.target.value)}
              />
            </Grid>
            <Grid item sm={6} md={2}>
              <SelectInputField
                name="owner"
                queryKey="ownerName"
                queryFunction={(qb: RequestQueryBuilder) => services.userService.getUsers(qb)}
                queryFilter={val => ({ $or: [{ userName: { $cont: val } }] })}
                selectFunction={data => {
                  const { items = [] } = data || {};
                  return items.map((item: any) => ({
                    label: `${item.firstName} ${item.lastName} (${item.userName})`,
                    value: item.userName,
                  }));
                }}
                //@ts-expect-error
                selectProps={{ isMulti: true, placeholder: "Select Owner" }}
              />
            </Grid>
          </Grid>

          <Button color="secondary" variant="contained" style={{ height: "100%" }} type="submit" fullWidth>
            Search
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default Filters;
