import { ApiClient } from "./api-client.service";
import { AxiosResponse } from "axios";
import { RequestQueryBuilder } from "@oktein/crud-request";
import { CurationProduct, CurationProducts, CurationUser, CurationUsers } from "../types";

export class FeedCurationService extends ApiClient {
  async getFeedUserCuration(queryBuilder: RequestQueryBuilder): Promise<CurationUsers> {
    const result: AxiosResponse<CurationUsers> = await this.get(`/feed-user-curation?${queryBuilder.query(false).replace("sort[0]","sort")}`);
    return result.data;
  }

  async saveFeedUserCuration(body: { userId: string; sortOrder: number }): Promise<CurationUser> {
    const result: AxiosResponse<CurationUser> = await this.post("/feed-user-curation", body);
    return result.data;
  }

  async updateUserSortOrder(id: string, body: { sortOrder: number }): Promise<CurationUser> {
    const result: AxiosResponse<CurationUser> = await this.post(`/feed-user-curation/sortOrder/${id}`, body);
    return result.data;
  }

  async deleteFeedUserCuration(id: string): Promise<CurationUser> {
    const result: AxiosResponse<CurationUser> = await this.delete(`/feed-user-curation/${id}`);
    return result.data;
  }

  async getFeedProductCuration(queryBuilder: RequestQueryBuilder): Promise<CurationProducts | any> {
    let prm =  queryBuilder.query(false);
    const result: AxiosResponse<CurationProducts | any> = await this.get(
      `/feed-product-curation?` + prm,
    );
    const curationData = result.data;
    return curationData;
  }

  async saveFeedProductCuration(body: { productId: string; sortOrder: number,scheduleDate: string }): Promise<CurationProduct> {
    const result: AxiosResponse<CurationProduct> = await this.post("/feed-product-curation", body);
    return result.data;
  }
  async saveAgainFeedProductCuration(body: any): Promise<CurationProduct> {
    const result: AxiosResponse<CurationProduct> = await this.post("/feed-product-curation/addProduct", body);
    return result.data;
  }
  async deleteAllFeedProductCuration(feedId: any): Promise<any> {
    const body = { feedIds:feedId  };
    const result: AxiosResponse<any> = await this.post("/feed-product-curation/delete-with-id", body);
    return result.data;
  }

  async deleteFeedProductCuration(id: string): Promise<CurationProduct> {
    const result: AxiosResponse<CurationProduct> = await this.delete(`/feed-product-curation/${id}`);
    //const result: AxiosResponse<CurationProduct> = await this.post(`/feed-product-curation/delete/${id}`);
    return result.data;
  }

  async updateProductSortOrder(id: string, body: { sortOrder: number }): Promise<CurationProducts> {
    const result: AxiosResponse<CurationProducts> = await this.post(`/feed-product-curation/sortOrder/${id}`, body);
    return result.data;
  }
}
