/* eslint-disable react-hooks/exhaustive-deps */
import {
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { QuerySort } from "@oktein/crud-request";
import React from "react";
import { useTable, Column, usePagination, Row, Cell, useSortBy } from "react-table";
import { PaginationMeta } from "../../types";

interface ITablePaginationOptions {
  pageIndex: number;
  pageSize: number;
}
interface ITableProps {
  columns: Column[];
  data?: any[];
  meta?: PaginationMeta;
  defaultPageSize?: number;
  onPaginationChange: ({ pageIndex, pageSize }: ITablePaginationOptions) => void;
  onSortChange: (sortOptions: QuerySort[]) => void;
  onCellValueChange?: (row: Row<any>, column: Column, value: any) => void;
}

const Table = ({
  columns,
  data = [],
  meta = { currentPage: 1, totalItems: 0, totalPages: 0, itemCount: 0, itemsPerPage: 5 },
  defaultPageSize,
  onPaginationChange,
  onSortChange,
  onCellValueChange,
}: ITableProps) => {
  if(!defaultPageSize){
      defaultPageSize = 5;
  }
  const tableInstance = useTable(
    {
      columns,
      initialState: { pageSize: defaultPageSize, pageIndex: meta.currentPage - 1, sortBy: [] },
      data,
      pageCount: meta.totalPages,
      manualPagination: true,
      disableSortRemove: true,
      manualSortBy: true,
      disableMultiSort: true,
      onCellValueChange,
    },
    useSortBy,
    usePagination,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = tableInstance;

  const firstUpdate = React.useRef(true);

  React.useEffect(() => {
    // To fix initial render call for sort.
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      const sortOptions = sortBy.map(sort => ({
        field: sort.id,
        order: sort.desc ? "DESC" : "ASC",
      }));

      onSortChange(sortOptions as QuerySort[]);
    }
  }, [sortBy]);

  React.useEffect(() => {
    const { currentPage } = meta;
    gotoPage(currentPage - 1);
  }, [meta]);
  return (
    <>
      <TableContainer component={Paper}>
        <MUITable {...getTableProps()}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.canSort ? (
                      <TableSortLabel
                        active={column.isSorted}
                        direction={column.isSorted ? (column.isSortedDesc ? "desc" : "asc") : undefined}
                      >
                        {column.render("Header")}
                      </TableSortLabel>
                    ) : (
                      column.render("Header")
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row: Row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell: Cell) => (
                    <TableCell {...cell.getCellProps()}>{cell.render("Cell")}</TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </MUITable>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 15,30,60,100]}
          count={meta.totalItems}
          page={pageIndex}
          rowsPerPage={pageSize}
          onPageChange={(_, page: number) => {
            gotoPage(page);
            onPaginationChange({ pageSize, pageIndex: page + 1 });
          }}
          onRowsPerPageChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPageSize(+e.target.value);
            gotoPage(0);
            onPaginationChange({ pageSize: +e.target.value, pageIndex: 1 });
          }}
        />
      </TableContainer>
    </>
  );
};

export default Table;
