import { ApiClient } from "./api-client.service";
import { UpdateOrder, Order, OrderDetail, Orders, OrderState } from "../types";
import { AxiosResponse } from "axios";
import { RequestQueryBuilder } from "@oktein/crud-request";

export class OrderService extends ApiClient {
  async getOrders(queryBuilder: RequestQueryBuilder): Promise<Orders> {
    const result: AxiosResponse<Orders> = await this.get(`/orders-admin?${queryBuilder.query(false).replace("sort[0]","sort")}`);
    return result.data;
  }

  async getOrderById(id: string): Promise<OrderDetail> {
    const result: AxiosResponse<OrderDetail> = await this.get(`/orders-admin/${id}`);
    return result.data;
  }

  async changeOrderState(orderId: string, orderState: OrderState, body?: {}): Promise<Order> {
    const result: AxiosResponse<Order> = await this.post(`/orders-admin/changeOrderState/${orderId}`, {
      orderState: orderState,
      ...body,
    });
    return result.data;
  }

  async updateOrder(id: string, body: UpdateOrder): Promise<Order> {
    const result: AxiosResponse<Order> = await this.post(`/orders-admin/changeOrderState/${id}`, body);
    return result.data;
  }
}
