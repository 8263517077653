import React, { useState } from "react";
import { Button, Grid, InputLabel, TextField } from "@material-ui/core";
import { Formik } from "formik";
import { RequestQueryBuilder } from "@oktein/crud-request";
import { SelectInputField } from "../../../../components";
import { useServicesStore } from "../../../../hooks";
import { useQuery } from "react-query";
import moment from "moment";
import { format } from "date-fns";

interface IProps {
  refetchStreamCuration: () => void;
  streamFormLoading: boolean;
}
const StreamForm = ({ refetchStreamCuration }: IProps) => {
  const { streamCurationService } = useServicesStore();
  const [isLoading, setIsLoading] = useState(true);

  const qb = React.useMemo(() => RequestQueryBuilder.create({ page: 1, limit: 25 }), []);
  const { refetch } = useQuery("stream", () => streamCurationService.getStreams(qb), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
  });  
 
  return (
    <Formik
      initialValues={{ stream: null, scheduleDate: null }}
      onSubmit={(values: any, { setSubmitting, resetForm }) => {
        setSubmitting(false);
        setIsLoading(false);
        streamCurationService
          .saveFeedStreamCuration({
            streamId: values.stream.value,
            sortOrder: 1,
            scheduleDate: new Date(values.scheduleDate).toISOString(),
          })
          .then(() => refetchStreamCuration())
          .then(() => refetch())
          .then(() => setIsLoading(true))
        resetForm();

      }}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <form onSubmit={handleSubmit} style={{ marginBottom: "8px" }}>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
            {!isLoading && (
              <div>Loading...</div>
                )}
              {isLoading && (
                <SelectInputField
                  name="stream"
                  queryKey="stream"
                  queryFunction={(qb: RequestQueryBuilder) => streamCurationService.getStreams(qb)}
                  queryFilter={val => ({ filter: val })}
                  selectProps={{ isMulti: false, placeholder: "Select Stream" }}
                  selectFunction={(data: any) => {
                    const result = data.map((item: any) => ({
                      label:
                        item.itemName +
                        " [ Schedule Date:" +
                        format(new Date(item.streamDate), "MMM d, y, h:mm:ss a") +
                        "]",
                      value: item.id,
                      item,
                    }));
                    return result;
                  }}
                />
              )}
            </Grid>
            <InputLabel style={{ marginTop: "15px" }}>Schedule Date :</InputLabel>
            <Grid item sm={6} md={2}>
              <TextField
                id="scheduleDate"
                name="scheduleDate"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: moment().format("YYYY-MM-DD") } }}
                onChange={event => {
                  values.scheduleDate = event.target.value;
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={!values.stream && !values.scheduleDate}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default StreamForm;
