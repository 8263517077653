import React from "react";
import { Button, Grid } from "@material-ui/core";
import { Formik } from "formik";
import { RequestQueryBuilder } from "@oktein/crud-request";
import { SelectInputField } from "../../../../components";
import { useServicesStore } from "../../../../hooks";
import { Categories } from "../../../../types";

const CategoryForm = ({
  onSubmit,
}: {
  onSubmit: ({
    productCategoryId,
    categoryImage,
    sortOrder,
  }: {
    productCategoryId: string;
    categoryImage: File;
    sortOrder: string;
  }) => void;
}) => {
  const { categoryService } = useServicesStore();
  return (
    <Formik
      initialValues={{ category: null, categoryImage: null }}
      onSubmit={(values: any, { setSubmitting, resetForm, setValues }) => {
        setSubmitting(false);
        onSubmit({ productCategoryId: values.category.value, sortOrder: "1", categoryImage: values.categoryImage });
        resetForm();
      }}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <form onSubmit={handleSubmit} style={{ marginBottom: "8px" }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SelectInputField
                name="category"
                queryKey="category"
                queryFunction={(qb: RequestQueryBuilder) => categoryService.getCategories(qb)}
                queryFilter={val => ({ $or: [{ name: { $cont: val } }] })}
                selectProps={{ isMulti: false, placeholder: "Select Category" }}
                selectFunction={(data: Categories) => {
                  const { items = [] } = data || {};
                  const result = items.map(item => ({
                    label: item.name,
                    value: item.id,
                    item,
                  }));
                  return result;
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
              <input
                id="categoryImage"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  //@ts-expect-error
                  setFieldValue("categoryImage", event.currentTarget.files[0]);
                }}
                disabled={!values.category}
              />
              <label htmlFor="categoryImage">
                <Button variant="outlined" color="secondary" component="span" disabled={!values.category}>
                  Upload Category Image
                </Button>
                {values.categoryImage ? <span style={{ margin: "0 8px" }}>{values.categoryImage.name}</span> : null}
              </label>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={!values.category || !values.categoryImage}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default CategoryForm;
