import React from "react";
import { UserForm } from "./Forms";
import { UserTable } from "./Tables";
import { RequestQueryBuilder } from "@oktein/crud-request";
import { useMutation, useQuery } from "react-query";
import { useServicesStore } from "../../../hooks";
import { toast } from "react-toastify";

const useSearchUserCuration = () => {
  const qb = React.useMemo(() => RequestQueryBuilder.create({ page: 1, limit: 5 }), []);
  const { searchCurationService } = useServicesStore();

  const query = useQuery("search-user-curation", () => searchCurationService.getSearchUserCuration(qb), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const { mutate: deleteSearchUserCuration } = useMutation(
    (id: string) => searchCurationService.deleteSearchUserCuration(id),
    {
      onSuccess: () => {
        toast.success("Search user curation deleted!");
        query.refetch();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );

  const { mutate: updateSearchUserCurationOrder } = useMutation(
    (payload: { userId: string; sortOrder: number }) =>
      searchCurationService.updateUserSortOrder(payload.userId, { sortOrder: payload.sortOrder }),
    {
      onSuccess: () => {
        toast.success("Search user curation order updated!");
        query.refetch();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );

  const { mutate: saveSearchUserCuration } = useMutation(
    (payload: { userId: string; sortOrder: number }) => searchCurationService.saveSearchUserCuration(payload),
    {
      onSuccess: () => {
        query.refetch();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );

  return { query, queryBuilder: qb, saveSearchUserCuration, deleteSearchUserCuration, updateSearchUserCurationOrder };
};

const Users = () => {
  const { query, queryBuilder, deleteSearchUserCuration, updateSearchUserCurationOrder, saveSearchUserCuration } =
    useSearchUserCuration();
  return (
    <>
      <UserForm
        onSubmit={payload => {
          saveSearchUserCuration(payload);
        }}
      />
      <UserTable
        data={query.data}
        isLoading={query.isLoading}
        onRowDelete={(id: string) => deleteSearchUserCuration(id)}
        onRowUpdate={payload => updateSearchUserCurationOrder(payload)}
        onPaginationChange={({ pageSize, pageIndex }) => {
          queryBuilder.setPage(pageIndex).setLimit(pageSize);
          query.refetch();
        }}
        onSortChange={sortOptions => {
          delete queryBuilder.queryObject[queryBuilder.options.paramNamesMap?.sort as string];
          queryBuilder.sortBy(sortOptions);
          query.refetch();
        }}
      />
    </>
  );
};

export default Users;
