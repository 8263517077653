import React from "react";
import { Typography, Button, ButtonGroup, Box } from "@material-ui/core";
import { QuerySort, RequestQueryBuilder } from "@oktein/crud-request";
import { useMutation, useQuery } from "react-query";
import { useServicesStore } from "../../hooks";
import { Cell, Column } from "react-table";
import { FindsTable, ConfirmDialog, FormDialog } from "../../components";
import { toast } from "react-toastify";
import { Category, CreateCategory, UpdateCategory } from "../../types/Category";
import AddOrCreateCategoryForm from "./form";
import { useHistory } from "react-router-dom";

const qb = RequestQueryBuilder.create({ page: 1, limit: 5 });
export interface EditValues {
  id: string;
  body: UpdateCategory;
}
const CategoriesPage = () => {
  const { categoryService } = useServicesStore();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openCreateOrEditDialog, setOpenCreateOrEditDialog] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<Category>();
  const [dialogMode, setDialogMode] = React.useState("create");
  let history = useHistory();

  const { data, isLoading, refetch } = useQuery("categories", () => categoryService.getCategories(qb), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const { mutate: createCategory } = useMutation((body: CreateCategory) => categoryService.createCategory(body), {
    onSuccess: () => {
      toast.success("Category successfully created!");
      refetch();
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });
  const { mutate: updateCategory } = useMutation(
    (values: EditValues) => categoryService.updateCategory(values.id, values.body),
    {
      onSuccess: () => {
        toast.success("Category successfully updated!");
        refetch();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );
  const { mutate: deleteCategory } = useMutation((id: string) => categoryService.deleteCategory(id), {
    onSuccess: () => {
      toast.success("Category successfully deleted!");
      refetch();
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });

  const { meta, items } = data || {};

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Category Id",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Sort Order",
        accessor: "sortOrder",
      },

      {
        Header: "Actions",
        Cell: ({ row: { original } }: Cell<Category>) => (
          <ButtonGroup color="secondary" aria-label="outlined secondary button group">
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setDialogMode("update");
                setSelectedRow(original);
                setOpenCreateOrEditDialog(true);
              }}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                setSelectedRow(original);
                setOpenDeleteDialog(true);
              }}
            >
              Delete
            </Button>
            <Button
              onClick={() => {
                history.push(`/sub-categories/${original.id}`);
              }}
            >
              Sub Categories
            </Button>
          </ButtonGroup>
        ),
        disableSortBy: true,
      },
    ],
    [history],
  );

  const handlePaginationChange = ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
    qb.setPage(pageIndex).setLimit(pageSize);
    refetch();
  };

  const handleSortChange = (sortOptions: QuerySort[]) => {
    delete qb.queryObject[qb.options.paramNamesMap?.sort as string];
    sortOptions.forEach(value => {
        qb.sortBy(value);
    });
    refetch();
  };

  return (
    <div>
      <ConfirmDialog
        open={openDeleteDialog}
        onSave={() => {
          deleteCategory(selectedRow?.id as string);
          setOpenDeleteDialog(false);
        }}
        onClose={() => {
          setSelectedRow({} as Category);
          setOpenDeleteDialog(false);
        }}
      >
        Are you sure to delete this category?
      </ConfirmDialog>
      <FormDialog
        open={openCreateOrEditDialog}
        onClose={() => {
          setDialogMode("create");
          setSelectedRow({} as Category);
        }}
      >
        {dialogMode === "create" ? "Add a new Category" : "Update Category"}
        <AddOrCreateCategoryForm
          createCategory={createCategory}
          updateCategory={updateCategory}
          dialogMode={dialogMode}
          selectedRow={selectedRow as Category}
          openDialog={setOpenCreateOrEditDialog}
        />
      </FormDialog>
      <Box display="flex" justifyContent="space-between" mb={2} onClick={() => setOpenCreateOrEditDialog(true)}>
        <Typography variant="h5">Categories</Typography>
        <Button color="secondary" variant="contained">
          Add New Category
        </Button>
      </Box>

      {!isLoading && (
        <FindsTable
          columns={columns}
          data={items}
          meta={meta}
          onPaginationChange={handlePaginationChange}
          onSortChange={handleSortChange}
        />
      )}
    </div>
  );
};

export default CategoriesPage;
