import React from "react";
import { Typography, Grid, Paper, makeStyles, createStyles, Theme } from "@material-ui/core";
import { UsersRoleTable } from "./Tables";
import { useMutation, useQuery } from "react-query";
import { RequestQueryBuilder } from "@oktein/crud-request";
import { toast } from "react-toastify";
import { useServicesStore } from "../../hooks";
import UsersRoleForm from "./Forms/UsersRoleForm";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100%",
      alignItems: "center",
    },
    root: {
      flexDirection: "column",
    },
    title: {
      padding: `${theme.spacing(1)}px 0;`,
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
  }),
);
const qb = RequestQueryBuilder.create({ page: 1, limit: 25 });

const UsersRole = () => {
  const classes = useStyles();
  const { feedCurationService,adminUserService } = useServicesStore();
  const {
    data: userRoleData,
    isLoading: productIsLoading,
    refetch: refetchUserRole,
  } = useQuery("user-role", () => adminUserService.getUserRole(qb), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const { mutate: updateFeedProductSortOrder } = useMutation(
    (payload: { productId: string; sortOrder: number }) =>
      feedCurationService.updateProductSortOrder(payload.productId, { sortOrder: payload.sortOrder }),
    {
      onSuccess: () => {
        toast.success("Feed product curation order updated!");
        refetchUserRole();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );
  return (
    <Grid container >
      <Typography variant="h5" className={classes.title}>
        Users Role
      </Typography>
      <Grid style={{ width: "130%" }}>
        <Grid item md="auto" component={Paper} className={classes.paper}>
          <UsersRoleForm refetchUserRole={refetchUserRole} />
          <UsersRoleTable
            qb={qb}
            data={userRoleData}
            isLoading={productIsLoading}
            refetchProductCuration={refetchUserRole}
            updateFeedProductSortOrder={updateFeedProductSortOrder}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UsersRole;
