import { Button, Grid } from "@material-ui/core";
import { RequestQueryBuilder } from "@oktein/crud-request";
import React, { useEffect } from "react";
import { UseMutateFunction, useMutation } from "react-query";
import { toast } from "react-toastify";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import { ConfirmDialog } from "../../../components";
import { CurationProducts } from "../../../types";
import { useServicesStore } from "../../../hooks";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckIcon from "@mui/icons-material/Check";
import { pink } from "@material-ui/core/colors";

interface IProps {
  data?: CurationProducts | any | any[];
  isLoading: boolean;
  refetchProductCuration: () => void;
  updateFeedProductSortOrder: UseMutateFunction<
    CurationProducts,
    unknown,
    { productId: string; sortOrder: number },
    unknown
  >;
  qb: RequestQueryBuilder;
}
const UsersRoleTable = ({ data, qb, isLoading, refetchProductCuration, updateFeedProductSortOrder }: IProps) => {
  const { adminUserService } = useServicesStore();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogApprove, setOpenDialogApprove] = React.useState(false);
  const [openDialogReject, setOpenDialogReject] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const { meta, items } = data || {};
  const columns: GridColDef[] = [
    {
      field: "role",
      headerName: "Role",
      minWidth: 150,
      renderCell: (prm: any) => <span>{prm.value ? prm.value.name : ""}</span>,
    },
    {
      field: "user",
      headerName: "User",
      minWidth: 150,
      renderCell: (prm: any) => <span>{prm.value ? prm.value.firstName + " " + prm.value.firstName : ""}</span>,
    },
    {
      field: "email",
      headerName: "Mail",
      minWidth: 200,
      renderCell: (prm: any) => {
        return <span>{prm.row.user?.email}</span>;
      },
    },
    {
      field: "username",
      headerName: "Username",
      minWidth: 150,
      renderCell: (prm: any) => {
        return <span>{prm.row.user?.userName}</span>;
      },
    },
    {
      field: "isApprove",
      headerName: "Approve",
      minWidth: 120,
      renderCell: (prm: any) => {
        return (
          <span>
            {prm.row.isApprove ? (
              <CheckIcon
                color="success"
                onClick={() => {
                  setSelectedRowId(prm.row.id);
                  setOpenDialogReject(true);
                }}
              ></CheckIcon>
            ) : prm.row.approveDate ? (
              <CheckCircleIcon
                onClick={() => {
                  setSelectedRowId(prm.row.id);
                  setOpenDialogReject(true);
                }}
                color="success"
              ></CheckCircleIcon>
            ) : prm.row.rejectDate ? (
              <HighlightOffIcon
                onClick={() => {
                  setSelectedRowId(prm.row.id);
                  setOpenDialogApprove(true);
                }}
                sx={{ color: pink[500] }}
              ></HighlightOffIcon>
            ) : (
              <div></div>
            )}
          </span>
        );
      },
    },
    {
      field: "id",
      headerName: "Actions",
      minWidth: 250,
      renderCell: (prm: any) => (
        <div className="blog-comments__avatar mr-3">
          {!prm.row.approveDate && !prm.row.rejectDate ? (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setSelectedRowId(prm.value);
                setOpenDialogApprove(true);
              }}
            >
              <CheckCircleIcon></CheckCircleIcon>
            </Button>
          ) : (
            <div></div>
          )}

          {!prm.row.approveDate && !prm.row.rejectDate ? (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setSelectedRowId(prm.value);
                setOpenDialogReject(true);
              }}
            >
              <HighlightOffIcon></HighlightOffIcon>
            </Button>
          ) : (
            <div></div>
          )}

          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setSelectedRowId(prm.value);
              setOpenDialog(true);
            }}
          >
            Remove
          </Button>
        </div>
      ),
    },
  ];
  const { mutate: deleteUseRole } = useMutation((id: string) => adminUserService.deleteIdUserRole(id), {
    onSuccess: () => {
      toast.success("UserRole deleted!");
      refetchProductCuration();
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });
  const { mutate: approveUserRole } = useMutation((id: string) => adminUserService.approveUserRole(id), {
    onSuccess: () => {
      toast.success("Role approved!");
      refetchProductCuration();
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });
  const { mutate: rejectUserRole } = useMutation((id: string) => adminUserService.rejectUserRole(id), {
    onSuccess: () => {
      toast.success("Role reject!");
      refetchProductCuration();
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);

  const [selectionModel, setSelectionModel] = React.useState<any[]>([]);

  useEffect(() => {
    qb.setPage(page).setLimit(pageSize);
    refetchProductCuration();
  }, [pageSize, page, qb, refetchProductCuration, items]);

  const [rowCountState, setRowCountState] = React.useState(meta?.totalItems || 0);
  React.useEffect(() => {
    setRowCountState((prevRowCountState: any) =>
      meta?.totalItems !== undefined ? meta?.totalItems : prevRowCountState,
    );
  }, [meta?.totalItems, setRowCountState]);
  React.useEffect(() => {
    setRowCountState((prevRowCountState: any) =>
      meta?.totalItems !== undefined ? meta?.totalItems : prevRowCountState,
    );
  }, [meta?.totalItems, setRowCountState]);
  return (
    <>
      <ConfirmDialog
        open={openDialog}
        onSave={() => {
          deleteUseRole(selectedRowId);
          setOpenDialog(false);
          refetchProductCuration();
        }}
        onClose={() => setOpenDialog(false)}
        confirmText="Yes"
      >
        Are you sure you want to delete the user role?
      </ConfirmDialog>
      <ConfirmDialog
        open={openDialogReject}
        onSave={() => {
          rejectUserRole(selectedRowId);
          setOpenDialogReject(false);
          refetchProductCuration();
        }}
        onClose={() => setOpenDialogReject(false)}
        confirmText="Yes"
      >
        Are you sure you want to reject the user role?
      </ConfirmDialog>
      <ConfirmDialog
        open={openDialogApprove}
        onSave={() => {
          approveUserRole(selectedRowId);
          setOpenDialogApprove(false);
          refetchProductCuration();
        }}
        onClose={() => setOpenDialogApprove(false)}
        confirmText="Yes"
      >
        Are you sure you want to approve the user role?
      </ConfirmDialog>
      {!isLoading ? (
        <Grid>
          <Grid>
            <div style={{ height: "1000px", width: "auto" }}>
              <DataGrid
                rows={items}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[5, 25, 50, 100, 200]}
                onSelectionModelChange={setSelectionModel}
                selectionModel={selectionModel}
                rowCount={rowCountState}
                loading={isLoading}
                pagination
                page={page}
                paginationMode="server"
                onPageChange={newPage => setPage(newPage)}
                onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                rowHeight={50}
              />
            </div>
          </Grid>
        </Grid>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default UsersRoleTable;
