import React from "react";
import { Typography, Button, ButtonGroup, Box } from "@material-ui/core";
import { QuerySort, RequestQueryBuilder } from "@oktein/crud-request";
import { useMutation, useQuery } from "react-query";
import { useServicesStore } from "../../hooks";
import { Column } from "react-table";
import { FindsTable, ConfirmDialog, FormDialog } from "../../components";
import { toast } from "react-toastify";
import { Brand, CreateBrand, UpdateBrand } from "../../types/Brand";
import AddOrCreateBrandForm from "./form";

const qb = RequestQueryBuilder.create({ page: 1, limit: 5 });
export interface EditValues {
  id: string;
  body: UpdateBrand;
}
const BrandsPage = () => {
  const { brandService } = useServicesStore();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openCreateOrEditDialog, setOpenCreateOrEditDialog] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<Brand>();
  const [dialogMode, setDialogMode] = React.useState("create");

  const { data, isLoading, refetch } = useQuery("brands", () => brandService.getBrands(qb), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const { mutate: createBrand } = useMutation((body: CreateBrand) => brandService.createBrand(body), {
    onSuccess: () => {
      toast.success("Brand successfully created!");
      refetch();
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });
  const { mutate: updateBrand } = useMutation(
    (values: EditValues) => brandService.updateBrand(values.id, values.body),
    {
      onSuccess: () => {
        toast.success("Brand successfully updated!");
        refetch();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );
  const { mutate: deleteBrand } = useMutation((id: string) => brandService.deleteBrand(id), {
    onSuccess: () => {
      toast.success("Brand successfully deleted!");
      refetch();
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });

  const { meta, items } = data || {};

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Brand Id",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Sort Order",
        accessor: "sortOrder",
      },

      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <ButtonGroup color="secondary" aria-label="outlined secondary button group">
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setDialogMode("update");
                setSelectedRow(original as Brand);
                setOpenCreateOrEditDialog(true);
              }}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                setSelectedRow(original as Brand);
                setOpenDeleteDialog(true);
              }}
            >
              Delete
            </Button>
          </ButtonGroup>
        ),
        disableSortBy: true,
      },
    ],
    [],
  );

  const handlePaginationChange = ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
    qb.setPage(pageIndex).setLimit(pageSize);
    refetch();
  };

  const handleSortChange = (sortOptions: QuerySort[]) => {
    delete qb.queryObject[qb.options.paramNamesMap?.sort as string];
    sortOptions.forEach(value => {
        qb.sortBy(value);
    });
    refetch();
  };

  return (
    <div>
      <ConfirmDialog
        open={openDeleteDialog}
        onSave={() => {
          deleteBrand(selectedRow?.id as string);
          setOpenDeleteDialog(false);
        }}
        onClose={() => {
          setSelectedRow({} as Brand);
          setOpenDeleteDialog(false);
        }}
      >
        Are you sure to delete this brand?
      </ConfirmDialog>
      <FormDialog
        open={openCreateOrEditDialog}
        onClose={() => {
          setDialogMode("create");
          setSelectedRow({} as Brand);
        }}
      >
        {dialogMode === "create" ? "Add a new Brand" : "Update Brand"}
        <AddOrCreateBrandForm
          createBrand={createBrand}
          updateBrand={updateBrand}
          dialogMode={dialogMode}
          selectedRow={selectedRow as Brand}
          openDialog={setOpenCreateOrEditDialog}
        />
      </FormDialog>

      <Box display="flex" justifyContent="space-between" mb={2} onClick={() => setOpenCreateOrEditDialog(true)}>
        <Typography variant="h5">Brands</Typography>
        <Button color="secondary" variant="contained">
          Add New Brand
        </Button>
      </Box>

      {!isLoading && (
        <FindsTable
          columns={columns}
          data={items}
          meta={meta}
          onPaginationChange={handlePaginationChange}
          onSortChange={handleSortChange}
        />
      )}
    </div>
  );
};

export default BrandsPage;
