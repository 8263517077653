import { Route, Switch } from "react-router-dom";
import {
  AdminRoles,
  Brands,
  Categories,
  Cities,
  Colours,
  ProfileComments,
  ProductComments,
  Conditions,
  DiscountCodes,
  Discovery,
  Home,
  NotFound,
  Orders,
  Products,
  ProductDetail,
  ProductSearch,
  Ratings,
  Refunds,
  SubCategories,
  UserDetail,
  OrderDetail,
  Users,
  FeedPageCuration,
  SearchPageCuration,
  FindsSettings,
} from "..";
import { Layout } from "../../components";
import StreamPageCuration from "../Curation/StreamPage";
import UsersRoles from "../UsersRole";
import SellerAnalytics from "../SellerAnalytic";
import SearchProductFeed from "../Curation/SearchProductFeed/List";

const Dashboard = () => {
  return (
    <Layout>
      <Switch>
        <Route exact path="/users" component={Users} />
        <Route exact path="/users/:id" component={UserDetail} />
        <Route exact path="/orders/:id" component={OrderDetail} />
        <Route exact path="/orders" component={Orders} />
        <Route exact path="/refunds" component={Refunds} />
        <Route path="/products/:id" component={ProductDetail} />
        <Route exact path="/products" component={Products} />
        <Route exact path="/ratings" component={Ratings} />
        <Route exact path="/profile-comments" component={ProfileComments} />
        <Route exact path="/product-comments" component={ProductComments} />
        <Route exact path="/product-search" component={ProductSearch} />
        <Route exact path="/discovery-page" component={Discovery} />
        <Route exact path="/feed-page-curation" component={FeedPageCuration} />
        <Route exact path="/feed-search-curation" component={SearchProductFeed} />
        <Route exact path="/stream-page-curation" component={StreamPageCuration} />
        <Route exact path="/search-page-curation" component={SearchPageCuration} />
        <Route exact path="/brands" component={Brands} />
        <Route exact path="/cities" component={Cities} />
        <Route exact path="/colours" component={Colours} />
        <Route exact path="/conditions" component={Conditions} />
        <Route exact path="/categories" component={Categories} />
        <Route path="/sub-categories/:id?" component={SubCategories} />
        <Route exact path="/discount-codes" component={DiscountCodes} />
        <Route exact path="/seller-analytics" component={SellerAnalytics} />
        <Route exact path="/admin-users" component={AdminRoles} />
        <Route exact path="/users-role" component={UsersRoles} />
        <Route exact path="/settings" component={FindsSettings} />
        <Route exact path="/" component={Home} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Layout>
  );
};

export default Dashboard;
