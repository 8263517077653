import { ApiClient } from "./api-client.service";
import { AxiosResponse } from "axios";
import { RequestQueryBuilder } from "@oktein/crud-request"; 
import { SellerAnalytic, UpdateSellerAnalytic, CreateSellerAnalytic } from '../types/SellerAnalytic';
import { UserEntity } from "../types";

export class SellerAnalyticService extends ApiClient {
  async getSellerAvailableUser(queryBuilder: RequestQueryBuilder): Promise<SellerAnalytic | any> {
    const result: AxiosResponse<SellerAnalytic | any> = await this.get(`/seller-analytic?${queryBuilder.query(false).replace("sort[0]","sort")}`);
    const sellerData = {items:[], meta:undefined};
    sellerData.items = result.data.data;
    sellerData.items.map((item: { user: UserEntity; }) => {
      return item;
    })
    return sellerData;
  }

  async create(body: CreateSellerAnalytic) {
    const result: AxiosResponse<CreateSellerAnalytic> = await this.post("/seller-analytic/create", body);
    return result.data;
  }
  async multiSendEmail(body: any) {
    const result: AxiosResponse<CreateSellerAnalytic> = await this.post("/seller-analytic/create", body);
    return result.data;
  }

  async update(id: string, body: UpdateSellerAnalytic) {
    const result: AxiosResponse<UpdateSellerAnalytic> = await this.post(`/seller-analytic/update`, body);
    return result.data;
  }

  async deleteById(id: string): Promise<void> {
    const result: AxiosResponse<void> = await this.delete(`/seller-analytic/${id}`);
    return result.data;
  }
  async deleteAll(dicsountId: any): Promise<any> {
    const body = { discountIds:dicsountId  };
    const result: AxiosResponse<any> = await this.post("/user-discount-code/delete-with-id", body);
    return result.data;
  }
}
