import { ApiClient } from "./api-client.service";
import { Color, Colors, CreateColor, UpdateColor } from "../types";
import { AxiosResponse } from "axios";
import { RequestQueryBuilder } from "@oktein/crud-request";

export class ColorService extends ApiClient {
  async getColors(queryBuilder: RequestQueryBuilder): Promise<Colors> {
    const result: AxiosResponse<Colors> = await this.get(`/colors?${queryBuilder.query(false).replace("sort[0]","sort")}`);
    return result.data;
  }

  async getColorById(id: string): Promise<Color> {
    const result: AxiosResponse<Color> = await this.get(`/colors/${id}`);
    return result.data;
  }

  async createColor(body: CreateColor): Promise<Color> {
    const result: AxiosResponse<Color> = await this.post("/colors", body);
    return result.data;
  }

  async updateColor(id: string, body: UpdateColor): Promise<Color> {
    const result: AxiosResponse<Color> = await this.patch(`/colors/${id}`, body);
    return result.data;
  }

  async deleteColor(id: string): Promise<void> {
    const result: AxiosResponse<void> = await this.delete(`/colors/${id}`);
    return result.data;
  }
}
