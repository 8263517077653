import { ApiClient } from "./api-client.service";
import { Condition, Conditions, CreateCondition, UpdateCondition } from "../types/Condition";
import { AxiosResponse } from "axios";
import { RequestQueryBuilder } from "@oktein/crud-request";

export class ConditionService extends ApiClient {
  async getConditions(queryBuilder: RequestQueryBuilder): Promise<Conditions> {
    const result: AxiosResponse<Conditions> = await this.get(`/conditions?${queryBuilder.query(false).replace("sort[0]","sort")}`);
    return result.data;
  }

  async createCondition(body: CreateCondition): Promise<Condition> {
    const result: AxiosResponse<Condition> = await this.post("/conditions", body);
    return result.data;
  }

  async updateCondition(id: string, body: UpdateCondition): Promise<Condition> {
    const result: AxiosResponse<Condition> = await this.patch(`/conditions/${id}`, body);
    return result.data;
  }

  async deleteCondition(id: string): Promise<void> {
    const result: AxiosResponse<void> = await this.delete(`/conditions/${id}`);
    return result.data;
  }
}
