import React from "react";
import { Typography, Button, Box, ButtonGroup } from "@material-ui/core";
import { QuerySort, RequestQueryBuilder } from "@oktein/crud-request";
import { useMutation, useQuery } from "react-query";
import { Column } from "react-table";
import { toast } from "react-toastify";
import { AdminUser, CreateAdminUser, UpdateAdminUser } from "../../types";
import { useServicesStore } from "../../hooks";
import { FindsTable, ConfirmDialog, FormDialog } from "../../components";
import CreateOrEditForm from "./CreateOrEditForm";

enum DialogMode {
  Create = "create",
  Edit = "edit",
}

const useAdminUsers = () => {
  const qb = React.useMemo(() => RequestQueryBuilder.create({ page: 1, limit: 10 }), []);
  const { adminUserService } = useServicesStore();

  const query = useQuery("admin-users", () => adminUserService.getAdminUsers(qb), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const adminRoles = useQuery(
    "admin-roles",
    () => adminUserService.getAdminRoles(RequestQueryBuilder.create({ page: 1, limit: 100 })),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );

  const { mutate: deleteAdminUser } = useMutation((id: string) => adminUserService.deleteAdminUser(id), {
    onSuccess: () => {
      toast.success("Admin user deleted!");
      query.refetch();
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });

  const { mutate: createAdminUser } = useMutation((body: CreateAdminUser) => adminUserService.createAdminUser(body), {
    onSuccess: () => {
      toast.success("Admin user created!");
      query.refetch();
    },
    onError: () => {
      toast.error("Something went wrong, please try again!");
    },
  });

  const { mutate: editAdminUser } = useMutation(
    (payload: { id: string; body: UpdateAdminUser }) => adminUserService.updateAdminUser(payload.id, payload.body),
    {
      onSuccess: () => {
        toast.success("Admin user edited!");
        query.refetch();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  );

  return { queryBuilder: qb, query, adminRoles, createAdminUser, editAdminUser, deleteAdminUser };
};

const AdminRoles = () => {
  const { query, queryBuilder, createAdminUser, editAdminUser, deleteAdminUser, adminRoles } = useAdminUsers();
  const { data: { meta, items } = {}, isLoading, refetch } = query;
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [formDialogOpen, setFormDialogOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<AdminUser>();
  const [dialogMode, setDialogMode] = React.useState(DialogMode.Create);

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "User Id",
        accessor: "id",
      },
      {
        Header: "Full Name",
        accessor: "fullName",
      },
      {
        Header: "Username",
        accessor: "username",
      },
      {
        Header: "Role",
        accessor: "adminUserRole.roleName",
      },
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <ButtonGroup color="secondary" aria-label="outlined secondary button group">
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setDialogMode(DialogMode.Edit);
                setSelectedRow(original as AdminUser);
                setFormDialogOpen(true);
              }}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                setSelectedRow(original as AdminUser);
                setConfirmDialogOpen(true);
              }}
            >
              Delete
            </Button>
          </ButtonGroup>
        ),
        disableSortBy: true,
      },
    ],
    [],
  );

  const handlePaginationChange = ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
    queryBuilder.setPage(pageIndex).setLimit(pageSize);
    refetch();
  };

  const handleSortChange = (sortOptions: QuerySort[]) => {
    delete queryBuilder.queryObject[queryBuilder.options.paramNamesMap?.sort as string];
    queryBuilder.sortBy(sortOptions);
    refetch();
  };

  return (
    <>
      <ConfirmDialog
        open={confirmDialogOpen}
        confirmText="Yes"
        onSave={() => {
          deleteAdminUser(selectedRow?.id as string);
          setConfirmDialogOpen(false);
        }}
        onClose={() => {
          setSelectedRow(undefined);
          setConfirmDialogOpen(false);
        }}
      >
        Are you sure to delete this admin user?
      </ConfirmDialog>

      <FormDialog
        open={formDialogOpen}
        onClose={() => {
          setDialogMode(DialogMode.Create);
        }}
        title={dialogMode === DialogMode.Create ? "Add New Admin User" : "Edit New Admin User"}
      >
        <CreateOrEditForm
          initialValues={selectedRow as UpdateAdminUser}
          roles={adminRoles.data?.items || []}
          onClose={() => setFormDialogOpen(false)}
          onSubmit={values => {
            if (dialogMode === DialogMode.Create) {
              createAdminUser(values as CreateAdminUser);
            } else {
              selectedRow && editAdminUser({ id: selectedRow.id, body: values });
            }
            setFormDialogOpen(false);
          }}
        />
      </FormDialog>

      <Box
        display="flex"
        justifyContent="space-between"
        mb={2}
        onClick={() => {
          setDialogMode(DialogMode.Create);
          setSelectedRow(undefined);
          setFormDialogOpen(true);
        }}
      >
        <Typography variant="h5">Admin Users and Roles</Typography>
        <Button color="secondary" variant="contained">
          Add New Admin User
        </Button>
      </Box>
      {!isLoading ? (
        <FindsTable
          columns={columns}
          data={items}
          meta={meta}
          onPaginationChange={handlePaginationChange}
          onSortChange={handleSortChange}
        />
      ) : (
        <span>Loading...</span>
      )}
    </>
  );
};

export default AdminRoles;
