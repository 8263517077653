import { Button, DialogActions, makeStyles } from "@material-ui/core";
import React from "react";

import { Formik } from "formik";
import { UseMutateFunction } from "react-query/types/react/types";
import { EditValues } from "..";
import { CreateSellerAnalytic, MultiSend, SellerAnalytic } from "../../../types/SellerAnalytic";


const useStyles = makeStyles(theme => ({
  cancelButton: {
    textTransform: "none",
  },
  saveButton: {
    fontSize: "1.1em",
    paddingLeft: "1.2em",
    paddingRight: "1.2em",
    textTransform: "none",
  },
}));
interface IProps {
  createSeller: UseMutateFunction<any, unknown, CreateSellerAnalytic, unknown>;
  multiSendFunc: UseMutateFunction<any, unknown, MultiSend, unknown>;
  updateSeller: UseMutateFunction<any, unknown, EditValues, unknown>;
  dialogMode: string;
  selectedRows: any[];
  selectedRow: SellerAnalytic;
  openDialog: React.Dispatch<React.SetStateAction<boolean>>;
  newRow: boolean;
}
 
const SellerAnalyticsForm = ({ createSeller, updateSeller, multiSendFunc,dialogMode, selectedRow, openDialog, newRow, selectedRows }: IProps) => {

    const templateValues = { 
      id: "",
      userId: "",
      email:"",
      firstName:"",
      lastName: "",
      userName: "",
      info:"",
      followerCount:"",
      monthly:"",
      weekly:""
    }; 
    const initialValues = selectedRow?.id
    ? {
        userId: selectedRow.userId,
        id: selectedRow.id,
        email: selectedRow.email,
        firstName: selectedRow.firstName,
        lastName: selectedRow.lastName,
        userName: selectedRow.userName,
        info: selectedRow.info,
        followerCount: selectedRow.followerCount,
        monthly: selectedRow.monthly,
        weekly: selectedRow.weekly,
     
      }
    : templateValues;

  const cs = useStyles();
  const calculate7 = selectedRows.filter(item => (item.pastEmailDayAgo !== undefined && item.pastEmailDayAgo <= 7)).length;
  

  return (
    <Formik
      initialValues={initialValues}  
      validateOnMount
      onSubmit={(values, actions) => {
        const submitValues = {
          ...values,
         };
        dialogMode === "create"
          ? multiSendFunc(selectedRows)
          : updateSeller({ id: selectedRow.id, body: submitValues });
        actions.resetForm({
          values: templateValues,
        });
        openDialog(false);
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          {dialogMode === "create" ? (
            <div>
              {selectedRows.length > 0
                ? <p>
                  A total of {selectedRows.length} users will be emailed. <br />
                  {calculate7>0?<>
                    You have sent emails to {calculate7} users within a week.</>:<></>}
                 
                  {selectedRows.length - calculate7 > 0 ?<>
                    <br />{selectedRows.length - calculate7} Users who will be sent new emails.
                  </>:<></>
                  }
              
                </p>
                : <p>No items were selected.</p>
              }
            </div>
          ) : (
            <div>
                <p key={values.firstName}><h1>{values.firstName} {values.lastName}</h1></p>
                <p>{values.info}</p>
                
            </div>
          )}
          
          <DialogActions>
            <Button onClick={() => openDialog(false)} color="default" className={cs.cancelButton}>
              Cancel
            </Button>
            <Button
              className={cs.saveButton}
              type="submit"
              variant="contained"
              color="primary"
            >
              Send!
            </Button>
          </DialogActions>
        </form>
      )}
    </Formik>
  );
};

export default SellerAnalyticsForm;
