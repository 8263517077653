import { Button, DialogActions, MenuItem, TextField } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AdminRole, CreateAdminUser, UpdateAdminUser } from "../../types";

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required("Required"),
  username: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  adminUserRoleId: Yup.string().required("Required"),
});

interface IProps {
  initialValues: UpdateAdminUser;
  roles: AdminRole[];
  onSubmit: (values: CreateAdminUser | UpdateAdminUser) => void;
  onClose: () => void;
}


const CreateOrEditForm = ({ initialValues, roles, onSubmit, onClose }: IProps) => {
  const _initialValues = { 
    ...initialValues,
    adminUserRoleId: (initialValues?.adminUserRole as AdminRole)?.id
  }
  const { handleSubmit, isSubmitting, isValid, dirty, values, handleBlur, handleChange, errors, touched } =
    useFormik({
      initialValues: _initialValues || {},
      validationSchema: validationSchema,
      onSubmit: values => {
        onSubmit({
          fullName: values.fullName,
          password: values.password,
          username: values.username,
          email: values.email,
          adminUserRoleId: values.adminUserRoleId,
        });
      },
    });

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        size="small"
        label="Full Name"
        name="fullName"
        color="secondary"
        value={values.fullName}
        onChange={handleChange}
        onBlur={handleBlur}
        error={!!(errors.fullName && touched.fullName)}
        helperText={!!(errors.fullName && touched.fullName) ? errors.fullName : null}
      />
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        size="small"
        label="Username"
        name="username"
        color="secondary"
        value={values.username}
        onChange={handleChange}
        onBlur={handleBlur}
        error={!!(errors.username && touched.username)}
        helperText={!!(errors.username && touched.username) ? errors.username : null}
      />
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        size="small"
        label="Password"
        name="password"
        type="password"
        color="secondary"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        error={!!(errors.password && touched.password)}
        helperText={!!(errors.password && touched.password) ? errors.password : null}
      />
      <TextField
        variant="outlined"
        margin="normal"
        size="small"
        fullWidth
        select
        label="Role"
        name="adminUserRoleId"
        color="secondary"
        value={values.adminUserRoleId}
        onChange={handleChange}
        onBlur={handleBlur}
        error={!!(errors.adminUserRoleId && touched.adminUserRoleId)}
        helperText={!!(errors.adminUserRoleId && touched.adminUserRoleId) ? errors.adminUserRoleId : null}
      >
        {roles.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {option.roleName}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        size="small"
        label="E-mail"
        name="email"
        color="secondary"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        error={!!(errors.email && touched.email)}
        helperText={!!(errors.email && touched.email) ? errors.email : null}
      />
      <DialogActions>
        <Button onClick={onClose} color="default">
          Cancel
        </Button>
        <Button type="submit" variant="contained" color="primary" disabled={isSubmitting || !isValid || !dirty}>
          Save
        </Button>
      </DialogActions>
    </form>
  );
};

export default CreateOrEditForm;
