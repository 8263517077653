import React from "react";
import { Typography, Button, ButtonGroup, Box } from "@material-ui/core";
import { QuerySort, RequestQueryBuilder } from "@oktein/crud-request";
import { useMutation, useQuery } from "react-query";
import { useServicesStore } from "../../hooks";
import { Column } from "react-table";
import { FindsTable, FormDialog } from "../../components";
import { toast } from "react-toastify";
import SettingsForm from "./form";
import { FindsSetting, UpdateFindsSetting } from "../../types/Setting";

const qb = RequestQueryBuilder.create({ page: 1, limit: 5 });
export interface EditValues {
  id: string;
  body: UpdateFindsSetting;
}
const SettingsPage = () => {
  const { settingsService } = useServicesStore();
  const [openCreateOrEditDialog, setOpenCreateOrEditDialog] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<FindsSetting>();
  const [dialogMode, setDialogMode] = React.useState("create");

  const { data, isLoading, refetch } = useQuery("settings", () => settingsService.getSettings(qb), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
   
  const { mutate: updateSettings } = useMutation(
    (values: EditValues) => settingsService.updateSettings(values.id, values.body),
    {
      onSuccess: () => {
        toast.success("Setting successfully updated!");
        refetch();
      },
      onError: () => {
        toast.error("Something went wrong, please try again!");
      },
    },
  ); 
  const { meta, items } = data || {};

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Value",
        accessor: "value",
      },
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <ButtonGroup color="secondary" aria-label="outlined secondary button group">
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setDialogMode("update");
                console.log(original);
                setSelectedRow(original as FindsSetting);
                setOpenCreateOrEditDialog(true);
              }}
            >
              Edit
            </Button>
          </ButtonGroup>
        ),
        disableSortBy: true,
      },
    ],
    [],
  );

  const handlePaginationChange = ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
    qb.setPage(pageIndex).setLimit(pageSize);
    refetch();
  };

  const handleSortChange = (sortOptions: QuerySort[]) => {
    delete qb.queryObject[qb.options.paramNamesMap?.sort as string];
    sortOptions.forEach(value => {
        qb.sortBy(value);
    });
    refetch();
  };

  return (
    <div>
      <FormDialog
        open={openCreateOrEditDialog}
        onClose={() => {
          setDialogMode("create");
          setSelectedRow({} as FindsSetting);
        }}
      >
        {dialogMode === "create" ? "Add a new Setting" : "Update Setting"}
        <SettingsForm
          updateFindsSettings={updateSettings}
          dialogMode={dialogMode}
          selectedRow={selectedRow as FindsSetting}
          openDialog={setOpenCreateOrEditDialog}
        />
      </FormDialog>

      <Box display="flex" justifyContent="space-between" mb={2} onClick={() => setOpenCreateOrEditDialog(true)}>
        <Typography variant="h5">Settings</Typography>
      </Box>

      {!isLoading && (
        <FindsTable
          columns={columns}
          data={items}
          meta={meta}
          onPaginationChange={handlePaginationChange}
          onSortChange={handleSortChange}
        />
      )}
    </div>
  );
};

export default SettingsPage;
