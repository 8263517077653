import * as Yup from "yup";

const validationSchemaDiscountCode = Yup.object().shape({
  discountAmount: Yup.number().when(['discountType'], {
    is: (discountType: string) => discountType === 'percentage',
    then: Yup.number().max(100).min(1).required()
  }).when(['discountType'], {
    is: (discountType: string) => discountType === 'amount',
    then: Yup.number().max(100000000).min(1).required()
  }),
  discountType: Yup.string().required(),
  discountCode: Yup.string().required(),
  overallUsageLimit: Yup.number().min(1).required()
});

export default validationSchemaDiscountCode;
