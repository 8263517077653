import React from "react";
import { Button, ButtonGroup, Typography } from "@material-ui/core";
import { RequestQueryBuilder, QuerySort, QueryFilter } from "@oktein/crud-request";
import { Column, Cell } from "react-table";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import moment from "moment";
import { useServicesStore } from "../../../hooks";
import { Product } from "../../../types";
import { FindsTable } from "../../../components";
import Filters from "./Filters";

const qb = RequestQueryBuilder.create({ page: 1, limit: 5 });

const Products = () => {
  const { productService } = useServicesStore();

  const { data, isLoading, refetch } = useQuery("products", () => productService.getProducts(qb), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const { meta, items } = data || {};

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Product Number",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "itemName",
      },
      {
        Header: "Price",
        accessor: "price",
        maxWidth: 50,
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        maxWidth: 50,
      },
      {
        Header: "State",
        accessor: "productState",
        maxWidth: 50,
      },
      {
        Header: "Owner",
        accessor: "userId",
      },
      {
        Header: "Creation Date",
        accessor: "createdAt",
        Cell: ({ row: { original } }: Cell<Product>) => moment(original.createdAt).format("DD.MM.YYYY"),
      },
      {
        Header: "Actions",
        Cell: ({
          row: {
            original: { id },
          },
        }: Cell<Product>) => (
          <>
            <ButtonGroup color="secondary" aria-label="outlined secondary button group">
              <Link to={`/products/${id}`}>
                <Button variant="outlined" color="secondary">
                  Details
                </Button>
              </Link>
            </ButtonGroup>
          </>
        ),
        disableSortBy: true,
      },
    ],
    [],
  );

  const handlePaginationChange = ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
    qb.setPage(pageIndex).setLimit(pageSize);
    refetch();
  };

  const handleSortChange = (sortOptions: QuerySort[]) => {
    delete qb.queryObject[qb.options.paramNamesMap?.sort as string];
    sortOptions.forEach(value => {
        qb.sortBy(value);
    });
    refetch();
  };

  const handleFiltersChange = ({ filters, search }: { filters: QueryFilter[]; search: string }) => {
    // Reset all
    delete qb.queryObject[qb.options.paramNamesMap?.or as string];
    delete qb.queryObject[qb.options.paramNamesMap?.search as string];
    qb.setPage(1);

    if (filters.length !== 0) {
      qb.setOr(filters);
    }

    if (search) {
      qb.search({
        $and: [
          { itemName: { $cont: search } },
          ...filters.map(filter => {
            return { [filter.field]: { [filter.operator]: filter.value } };
          }),
        ],
      });
    }
    else {
        qb.search({
            $and: [
                ...filters.map(filter => {
                    return { [filter.field]: { [filter.operator]: filter.value } };
                }),
            ],
        });
    }
    refetch();
  };

  return (
    <>
      <Typography variant="h5">Products</Typography>
      <Filters onFiltersChange={handleFiltersChange} />
      {!isLoading && (
        <FindsTable
          columns={columns}
          data={items}
          meta={meta}
          onPaginationChange={handlePaginationChange}
          onSortChange={handleSortChange}
        />
      )}
    </>
  );
};

export default Products;
