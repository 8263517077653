import { Detail, Paginated } from "./Pagination";
import { BaseModel, DefaultExclusions, Without } from "./Utils";

export class UserEntity {
  id?: string;
  bio?: string;
  birthDate?: string;
  description?: string;
  email?: string;
  firstName?: string;
  fee?: string;
  gender?: string;
  isActive?: boolean;
  isSocialUser?: boolean;
  isVerified?: boolean;
  isVerifiedAccount?: boolean;
  lastName?: string;
  linkedAccountInstagram?: string;
  linkedAccountTiktok?: string;
  merchendisingFee?: string;
  name?: string;
  profileImage?: string;
  profileStats?: {
    lastActiveTime: string;
    rating: number;
  };
  phoneNumber?: string;
  registrationType?: string;
  pronouns?: string;
  timeZone?: string;
  userName?: string;
  verified?: boolean;
}

export type User = BaseModel & {
  bio?: string;
  birthDate?: string;
  description?: string;
  email?: string;
  firstName?: string;
  fee?: string;
  gender: string;
  isActive: boolean;
  isSocialUser: boolean;
  isVerified: boolean;
  isVerifiedAccount: boolean;
  lastName?: string;
  linkedAccountInstagram?: string;
  linkedAccountTiktok?: string;
  merchendisingFee?: number;
  name?: string;
  profileImage?: string;
  profileStats?: {
    lastActiveTime: string;
    rating: number;
  };
  phoneNumber?: string;
  registrationType?: string;
  pronouns: string;
  timeZone?: string;
  userName?: string;
  verified?: boolean;
  fullName: string;
  commissionRate: number;

};

export type Users = Paginated<User>;

export type UserDetail = Detail<{ user: User }>;

export type CreateUser = Without<User, DefaultExclusions | "isActive" | "isVerified" | "isVerifiedAccount">;

export type UpdateUser = Without<Partial<User>, DefaultExclusions>;
