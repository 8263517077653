import axios, { AxiosRequestConfig } from "axios";

export class ApiClient {
  private readonly baseURL = process.env.REACT_APP_API_URL;
  private readonly axiosInstance = axios;

  constructor() {
    this.configureAxios();
  }

  private configureAxios() {
    this.axiosInstance.defaults.baseURL = this.baseURL;
    this.axiosInstance.defaults.responseType = "json";
    if (localStorage.getItem("token")) {
      this.bearerToken = localStorage.getItem("token") as string;
    }
  }

  // TODO: Make it protected
  set bearerToken(value: string) {
    this.axiosInstance.defaults.headers = {
      Authorization: `Bearer ${value}`,
    };
  }

  protected get(path: string, options?: AxiosRequestConfig) {
    return this.axiosInstance.get(path, options);
  }

  protected post(path: string, body?: any, options?: AxiosRequestConfig) {
    return this.axiosInstance.post(path, body, options);
  }

  protected patch(path: string, body: any, options?: AxiosRequestConfig) {
    return this.axiosInstance.patch(path, body, options);
  }

  protected put(path: string, body: any, options?: AxiosRequestConfig) {
    return this.axiosInstance.put(path, body, options);
  }

  protected delete(path: string, options?: AxiosRequestConfig) {
    return this.axiosInstance.delete(path, options);
  }
}
