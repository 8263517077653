import { ApiClient } from "./api-client.service";
import { AxiosResponse } from "axios";
import { RequestQueryBuilder } from "@oktein/crud-request";
import { FindsSetting, FindsSettings, UpdateFindsSetting } from '../types/Setting';

export class FindsSettingsService extends ApiClient {
  async getSettings(queryBuilder: RequestQueryBuilder): Promise<FindsSettings> {
    const result: AxiosResponse<FindsSettings> = await this.get(`/settings-admin?${queryBuilder.query(false).replace("sort[0]","sort")}`);
    result.data.items = result.data.items.filter(item=>item.type === 'locale')
    return result.data;
  }

  async updateSettings(id: string, body: UpdateFindsSetting) {
    const result: AxiosResponse<FindsSetting> = await this.patch(`/settings-admin/${id}`, body);
    return result.data;
  } 
 
}
