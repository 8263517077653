import { createTheme } from "@material-ui/core";
import MaisonNeueBookWoff2 from "./fonts/MaisonNeue-Book.woff2";

const maisonNeueBook = {
  fontFamily: "MaisonNeue-Book",
  fontStyle: "normal",
  fontWeight: 400,
  src: `
    local('MaisonNeue-Book'),
    url(${MaisonNeueBookWoff2}) format('woff2')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
export const theme = createTheme({
  palette: {
    primary: {
      main: "#d4f970",
      light: "#d8f97e",
      dark: "#bee064",
    },
    secondary: {
      main: "#755ef3",
      dark: "#6954da",
      light: "#826ef4",
    },
  },
  typography: {
    fontFamily: "MaisonNeue-Book, Arial",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [maisonNeueBook],
      },
    },
  },
});
