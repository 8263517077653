import { ApiClient } from "./api-client.service";
import { CreateSubCategory, SubCategories, SubCategory, UpdateSubCategory } from "../types/Sub-Category";
import { AxiosResponse } from "axios";
import { RequestQueryBuilder } from "@oktein/crud-request";

export class SubCategoryService extends ApiClient {
  async getSubCategories(queryBuilder: RequestQueryBuilder): Promise<SubCategories> {
    const result: AxiosResponse<SubCategories> = await this.get(`/subcategories?${queryBuilder.query(false).replace("sort[0]","sort")}`);
    return result.data;
  }
  async getSubCategoriesByCategoryId(queryBuilder: RequestQueryBuilder, categoryId: string): Promise<SubCategories> {
    const result: AxiosResponse<SubCategories> = await this.get(
      `/categories/${categoryId}/subcategories?${queryBuilder.query(false).replace("sort[0]","sort")}`,
    );
    return result.data;
  }

  async createSubCategory(body: CreateSubCategory): Promise<SubCategory> {
    const result: AxiosResponse<SubCategory> = await this.post("/subcategories", body);
    return result.data;
  }

  async updateSubCategory(id: string, body: UpdateSubCategory): Promise<SubCategory> {
    const result: AxiosResponse<SubCategory> = await this.patch(`/subcategories/${id}`, body);
    return result.data;
  }

  async deleteSubCategory(id: string): Promise<void> {
    const result: AxiosResponse<void> = await this.delete(`/subcategories/${id}`);
    return result.data;
  }
}
