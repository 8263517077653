import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { isAuthenticated } from "../../utils/auth.utils";

interface PublicRouteProps {
  restricted?: boolean;
}

export type IProps = Omit<RouteProps, "component"> & {
  component: React.ElementType;
};

const PublicRoute = (props: PublicRouteProps & IProps): React.ReactElement => {
  const { component: Component, restricted = false, ...rest } = props;

  const render = (props: any) => {
    if (isAuthenticated() && restricted) {
      return <Redirect to={"/"} />;
    }

    return <Component {...props} />;
  };

  return <Route {...rest} render={render} />;
};

export default PublicRoute;
