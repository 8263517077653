import { ApiClient } from "./api-client.service";
import { AxiosResponse } from "axios";
import { Brand, Brands, CreateBrand, UpdateBrand } from "../types";
import { RequestQueryBuilder } from "@oktein/crud-request";

export class BrandService extends ApiClient {
  async getBrands(queryBuilder: RequestQueryBuilder): Promise<Brands> {
    const result: AxiosResponse<Brands> = await this.get(`/brands?${queryBuilder.query(false).replace("sort[0]","sort")}`);
    return result.data;
  }

  async createBrand(body: CreateBrand) {
    const result: AxiosResponse<Brand> = await this.post("/brands", body);
    return result.data;
  }

  async updateBrand(id: string, body: UpdateBrand) {
    const result: AxiosResponse<Brand> = await this.patch(`/brands/${id}`, body);
    return result.data;
  }

  async deleteBrand(id: string): Promise<void> {
    const result: AxiosResponse<void> = await this.delete(`/brands/${id}`);
    return result.data;
  }
}
