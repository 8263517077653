/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { CreateQueryParams, RequestQueryBuilder, SCondition } from "@oktein/crud-request";
import { useQuery } from "react-query";
import { debounce } from "lodash";
import Select, { ValueType, Props as ReactSelectProps } from "react-select";
import { Field, FieldProps } from "formik";

type OptionType = { label: string; value: string };

interface ISelectInputProps {
  queryKey: string;
  selectFunction: (data: any) => any;
  queryFilter: (val: any) => SCondition;
  queryFunction: (qb: RequestQueryBuilder) => Promise<any>;
  queryDefault?: CreateQueryParams;
  onFilterChange?: any;
  selectProps?: ReactSelectProps;
  value: ValueType<OptionType, true>;
  captureMenuScroll?: boolean;
  limitDefault?:number;
}

function SelectInput({
  queryKey,
  queryDefault = {},
  queryFunction,
  queryFilter,
  selectFunction,
  selectProps = {},
  onFilterChange,
  value,
  captureMenuScroll,
  limitDefault,
}: ISelectInputProps) {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchText, setSearchText] = React.useState("");
  if (!limitDefault) {
    limitDefault = 5;
  }
  const qb = React.useMemo(() => RequestQueryBuilder.create({ page: 1, limit: limitDefault, ...queryDefault }), []);
  const { data, isLoading } = useQuery([queryKey, searchQuery], () => queryFunction(qb), {
    refetchOnWindowFocus: false,
    select: selectFunction,
  });

  const handleChange = (selectedValue: ValueType<OptionType, true>, triggeredAction: string) => {
    onFilterChange(selectedValue,triggeredAction);
  };

  const handleInputChange = (query: string) => {
    if (query) {
      qb.search(queryFilter(query));
    } else {
      delete qb.queryObject[qb.options.paramNamesMap?.search as string];
    }
    setSearchQuery(query);
  };

  const debouncedInputChangeHandler = React.useMemo(() => debounce(handleInputChange, 700), []);

  const handleScroll = () => {
    console.log('Scroll yapıldı!');
  };
  return (
    <>
      <div id="myDiv"  onScroll={handleScroll}>
      <Select
        value={value}
        captureMenuScroll={captureMenuScroll}
        //@ts-expect-error
        onChange={handleChange}
        onInputChange={(v, e) => {
          if (e.action !== "input-blur" && e.action !== "menu-close") {
            setSearchText(v);
            debouncedInputChangeHandler(v);
          }
        }}
        isLoading={isLoading}
        inputValue={searchText}
        options={data}
        isClearable
        styles={{
          option: (styles, p) => {
            return { ...styles, textOverflow: "ellipsis", wordBreak: "break-all" };
          },
          menu: (provided, state) => ({
            ...provided,
            zIndex: 100,
          }),
        }}
        {...selectProps}
      />
      </div>
    </>
  );
}

interface ISelectInputFieldProps {
  name: string;
  queryKey: string;
  selectFunction: (data: any) => any;
  queryFunction: (qb: RequestQueryBuilder) => Promise<any>;
  queryFilter: (val: any) => SCondition;
  queryDefault?: CreateQueryParams;
  selectProps?: ReactSelectProps;
  onChange?: any;
  captureMenuScroll?: boolean;
  limitDefault?: number;
}

function defaultSelectFunction(data: any) {
  const { items = [] } = data || {};
  const result = items.map((item: any) => ({
    label: item.name,
    value: item.id,
  }));
  return result;
}

export function SelectInputField({
  name,
  selectProps,
  onChange,
  captureMenuScroll,
  selectFunction = defaultSelectFunction,
  limitDefault,
  ...rest
}: ISelectInputFieldProps) {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        return (
          <SelectInput
            onFilterChange={(val: any,action:any) => {
              if(action.action === "clear") {
                form.setFieldValue(name, undefined);
                onChange && onChange(undefined);
              }
              else {
                form.setFieldValue(name, val);
                onChange && onChange(val);
              }
            }}
            selectProps={selectProps}
            selectFunction={selectFunction}
            captureMenuScroll={captureMenuScroll}
            limitDefault={limitDefault}
            {...field}
            {...rest}
          />
        );
      }}
    </Field>
  );
}

export default SelectInput;
