import React from "react";
import { Button, Grid, InputLabel } from "@material-ui/core";
import { Formik } from "formik";
import { RequestQueryBuilder } from "@oktein/crud-request";
import { SelectInputField } from "../../../components";
import { useServicesStore } from "../../../hooks";
import { useQuery } from "react-query";
import { Users } from "../../../types";
import { toast } from "react-toastify";

interface IProps {
  refetchUserRole: () => void;
}
const UsersRoleForm = ({ refetchUserRole }: IProps) => {
  const { adminUserService } = useServicesStore();
  const qb = React.useMemo(() => RequestQueryBuilder.create({ page: 1, limit: 25 }), []);
  const { refetch } = useQuery("user-role", () => adminUserService.getUserRole(qb), {
    refetchOnWindowFocus: false,
  });
  return (
    <Formik
      initialValues={{ user: null, role: null }}
      onSubmit={(values: any, { setSubmitting, resetForm }) => {
        setSubmitting(false);
        adminUserService
          .saveUserRole({ userId: values.user.value, roleId: values.role.value})
          .then(() => refetchUserRole())
          .then(() => refetch()).catch((e)=>{
            toast.error("Something wrong!");
          });
        resetForm();
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <form onSubmit={handleSubmit} style={{ marginBottom: "8px" }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SelectInputField
                name="user"
                queryKey="user"
                queryFunction={(qb: RequestQueryBuilder) => adminUserService.getUsers(qb)}
                queryFilter={val => ({ filter: val })}
                selectProps={{ isMulti: false, placeholder: "Select User ." }}
                selectFunction={(data: Users) => {
                  const { items = [] } = data || {};
                  const result = items.map(item => ({
                    label: item.firstName +' '+ item.lastName+'('+item.email+')',
                    value: item.id,
                    item,
                  }));
                  return result;
                }}
              />
            </Grid>
            <InputLabel style={{ marginTop: "15px" }}>Role :</InputLabel>
            <Grid item sm={6} md={2}>
            <SelectInputField
                name="role"
                queryKey="role"
                queryFunction={(qb: RequestQueryBuilder) => adminUserService.getRoles(qb)}
                queryFilter={val => ({ $or: [{ itemName: { $cont: val } }] })}
                selectProps={{ isMulti: false, placeholder: "Select Role" }}
                selectFunction={(data: any) => {
                  const { items = [] } = data || {};
                  const result = items.map((item: { name: any; id: any; }) => ({
                    label: item.name,
                    value: item.id,
                    item,
                  }));
                  return result;
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" type="submit" color="primary" disabled={!values.role}>
                Add
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default UsersRoleForm;
