import { Button, DialogActions, makeStyles, TextField } from "@material-ui/core";
import { Formik } from "formik";
import { UseMutateFunction } from "react-query/types/react/types";
import { EditValues } from "..";
import { FindsSetting } from "../../../types/Setting";
import validationSchemaMasterData from "../../../utils/validationSchemaMasterData";

const useStyles = makeStyles(theme => ({
  cancelButton: {
    textTransform: "none",
  },
  text: {
    weight: "100px",
  },
  saveButton: {
    fontSize: "1.1em",
    paddingLeft: "1.2em",
    paddingRight: "1.2em",
    textTransform: "none",
  },
}));
interface IProps {
  updateFindsSettings: UseMutateFunction<FindsSetting, unknown, EditValues, unknown>;
  dialogMode: string;
  selectedRow: FindsSetting;
  openDialog: React.Dispatch<React.SetStateAction<boolean>>;
}
const SettingsForm = ({ updateFindsSettings, dialogMode, selectedRow, openDialog }: IProps) => {
  const initialValues = selectedRow?.id
    ? { name: selectedRow.name, value: selectedRow.value }
    : { name: "", value: "" };
  const cs = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaMasterData}
      validateOnMount
      onSubmit={values => {
        updateFindsSettings({ id: selectedRow.id, body: values });
        openDialog(false);
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit,isSubmitting, isValid, dirty }) => (
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Name"
            name="name"
            color="secondary"
            value={values.name}
            disabled={true}
            style = {{width: 450}} 
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Value"
            name="value"
            color="secondary"
            value={values.value}
            onChange={handleChange}
            onBlur={handleBlur}
            style = {{width: 450}} 
          />
          <DialogActions>
            <Button onClick={() => openDialog(false)} color="default" className={cs.cancelButton}>
              Cancel
            </Button>
            <Button
              className={cs.saveButton}
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting || !isValid || !dirty}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      )}
    </Formik>
  );
};

export default SettingsForm;
