import React from "react";
import { Button, Grid } from "@material-ui/core";
import { QueryFilter, RequestQueryBuilder } from "@oktein/crud-request";
import { Formik } from "formik";
import { FilterTextField, SelectInputField } from "../../../../components";
import { useServicesStore } from "../../../../hooks";
import { ValueType } from "react-select";
type OptionType = { label: string; value: string };


const Filters = ({ onFiltersChange }: any) => {
  const services = useServicesStore();
  const [, setCategory] = React.useState([]);
  const [subCategory, setSubCategory] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [sizes, setSizes] = React.useState([]);
  const [subCategories, seSubCategories] = React.useState([]);



  const categoryHandleChange = async (selectedValue: any, triggeredAction: string) => {
    const sizes = await services.productService.getSizesWithCat(selectedValue)
    setSizes(sizes)
    setCategory(selectedValue);
    if(isLoading){
      console.log(subCategory)
    }

    const subCat = await services.productService.getSubCategories(selectedValue)
    seSubCategories(subCat)
    setIsLoading(true);
  };

  const subCategoryHandleChange = (selectedValue: any, triggeredAction: string) => {
    setSubCategory(selectedValue);
  };
  const handleChange = (selectedValue: ValueType<OptionType, true>, triggeredAction: string) => {
    console.log(selectedValue, triggeredAction);
  };
  return (
    <Formik
      initialValues={{ 
        category: [],
        subCategory: [], 
        productSizes: [], 
        sellers: [], 
        sizes: [], 
        priceMin: 0, 
        priceMax: 100, 
        productInterestedCategories:[] }}
      onSubmit={(values: any, { setSubmitting }) => {
        setSubmitting(false);
        const newSearchForm={
          productSizes:[],
          sellers:[],
          subCategory:[],
          category:[],
          priceMin:0,priceMax:0,
          productInterestedCategories:[]};

        const filters: QueryFilter[] = [];
        
        if (values.category) {
         
          newSearchForm.category = values.category.map((v: any) => v.value);
        }
        if (values.productSize) {
         
          newSearchForm.productSizes = values.productSize.map((v: any) => v.value);
        }
       
        if (values.users) {
         
          newSearchForm.sellers = values.users.map((v: any) => v.value);
        }
        
        if (values.subCategory) {
          newSearchForm.subCategory = values.subCategory.map((v: any) => v.value);

        }
       
        newSearchForm.priceMin = Number(values.priceMin);
        newSearchForm.priceMax = Number(values.priceMax);

        filters.push(
          { operator: "eq", value: 'SALEABLE', field: "productState" }
        );
        let filterSearch;
        if(values.productInterestedCategories){
           newSearchForm.productInterestedCategories = values.productInterestedCategories.map((v: any) => v.value);

        }
        onFiltersChange({ filters ,search:filterSearch,newSearchForm});
        setIsLoading(false);
      }}
    >
      {({ handleSubmit, isValid, resetForm, submitForm, values }) => (
        <form onSubmit={handleSubmit} style={{ margin: "10px 0" }}>
          <Grid container spacing={1} style={{ margin: "10px 0" }}>
            <Grid item sm={6} md={4}>
              <SelectInputField
                name="category"
                queryKey="category"
                onChange={categoryHandleChange}
                queryFunction={(qb: RequestQueryBuilder) => {
                  const qbNew = RequestQueryBuilder.create({ page: 1, limit: 50 });
                  return services.categoryService.getCategories(qbNew)
                }}
                queryFilter={val => ({ $or: [{ name: { $cont: val } }] })}
                //@ts-expect-error
                selectProps={{ isMulti: true, placeholder: "Select Categories..." }}
              />
            </Grid>
            <Grid item sm={6} md={4}>
              <SelectInputField
                name="subCategory"
                queryKey="subCategory"
                queryFunction={(qb: RequestQueryBuilder) => {
                  const qbNew = RequestQueryBuilder.create({ page: 1, limit: 150 });
                  return services.subCategoryService.getSubCategories(qbNew);
                }}
                selectFunction={data => {
                  const result = subCategories.map((item: any) => ({
                    label: `${item.name} - (${item.productCategory.name})`,
                    value: item.id,
                  }));
                  return result;
                }}
                onChange={subCategoryHandleChange}
                queryFilter={val => ({ $or: [{ name: { $cont: val } }] })}
                //@ts-expect-error
                selectProps={{ isMulti: true, placeholder: "Select Sub Categories..." }}
              />
            </Grid>
            <Grid item sm={6} md={4}>
              <SelectInputField
                name="productSize"
                queryKey="productSize"
                queryFunction={(qb: RequestQueryBuilder) => services.productService.getSizes(qb,values.category)}
                onChange={handleChange}
                queryFilter={val => ({ $or: [{ name: { $cont: val } }] })}
                selectFunction={data => {
                  const result = sizes.map((item: any) => ({
                    label: `${item.name}`,
                    value: item.name,
                  }));
                  return result;
                }}
                //@ts-expect-error
                selectProps={{ isMulti: true, placeholder: "Select Sizes..." }}
              />
            </Grid>
            <Grid item sm={6} md={4}>
              <SelectInputField
                name="productInterestedCategories"
                queryKey="productInterestedCategories"
                queryFunction={(qb: RequestQueryBuilder) => services.productService.getTags(qb)}
                queryFilter={val => ({ $or: [{ name: { $cont: val } }] })}
                selectFunction={data => {
                  const result = data.map((item: any) => ({
                    label: `${item.categoryName}`,
                    value: item.id,
                  }));
                  return result;
                }}
                //@ts-expect-error
                selectProps={{ isMulti: true, placeholder: "Select Tags..." }}
              />
            </Grid>
            <Grid item sm={6} md={4}>
              <SelectInputField
                name="users"
                queryKey="users"
                queryFunction={(qb: RequestQueryBuilder) => services.userService.getUsers(qb)}
                queryFilter={val => ({ $or: [{ userName: { $cont: val } }] })}
                selectFunction={data => {
                  const { items = [] } = data || {};
                  const result = items.map((item: any) => ({
                    label: `${item.firstName} ${item.lastName} (${item.userName})`,
                    value: item.id,
                  }));
                  return result;
                }}
                //@ts-expect-error
                selectProps={{ isMulti: true, placeholder: "Select Sellers..." }}
              />
            </Grid>
            <Grid item sm={6} md={4}>

            </Grid>
              <Grid sm={6} md={4}>
                <FilterTextField name="priceMin" textFieldProps={{ placeholder: "Price Min", defaultValue: "0" }} />
              </Grid>
              <Grid sm={6} md={4}>
                <FilterTextField name="priceMax" textFieldProps={{ placeholder: "Price Max", defaultValue: "1000" }} />
              </Grid>
          </Grid>

          <Button color="secondary" variant="contained" style={{ height: "100%" }} type="submit" fullWidth>
            Search
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default Filters;
