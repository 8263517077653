import { ApiClient } from "./api-client.service";
import { CreateUser, UpdateUser, User, UserDetail, Users } from "../types";
import { AxiosResponse } from "axios";
import { RequestQueryBuilder } from "@oktein/crud-request";

export class UserService extends ApiClient {
  async getUsers(queryBuilder: RequestQueryBuilder): Promise<Users> {
    const result: AxiosResponse<Users> = await this.get(`/users-admin?${queryBuilder.query(false).replace("sort[0]", "sort")}`);
    return result.data;
  }

  async createUser(body: CreateUser): Promise<User> {
    const result: AxiosResponse<User> = await this.post("/users", body);
    return result.data;
  }

  async getUserById(id: string): Promise<UserDetail> {
    const result: AxiosResponse<UserDetail> = await this.get(`/users-admin/${id}`);
    return result.data;
  }

  async updateUser(id: string, body: UpdateUser): Promise<User> {
    const result: AxiosResponse<User> = await this.patch(`/users/${id}`, body);
    return result.data;
  }
  async updateUserMerchFee(id: string, body: UpdateUser): Promise<User> {
    const result: AxiosResponse<User> = await this.post(`/users-admin/updatemerchfee/${id}`, body);
    return result.data;
  }
  async updatecommissionRate(id: string, body: UpdateUser): Promise<User> {
    const result: AxiosResponse<User> = await this.post(`/users-admin/updateCommissionRate/${id}`, body);
    return result.data;
  }

  async toggleVerified(id: string, body: UpdateUser): Promise<User> {
    const result: AxiosResponse<User> = await this.post(`/users-admin/toggleverified/${id}`, body);
    return result.data;
  }
  async deactivateUser(id: string): Promise<User> {
    const result: AxiosResponse<User> = await this.post(`/users-admin/deactivate/${id}`);
    return result.data;
  }
  async stripeAccountUser(id: string): Promise<User> {
    const result: AxiosResponse<User> = await this.post(`/users-admin/stripeAccount/${id}`);
    return result.data;
  }


  async activateUser(id: string): Promise<User> {
    const result: AxiosResponse<User> = await this.post(`/users-admin/activate/${id}`);
    return result.data;
  }

  async deleteUser(id: string): Promise<void> {
    const result: AxiosResponse<void> = await this.delete(`/users/${id}`);
    return result.data;
  }
}
