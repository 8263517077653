import * as Yup from "yup";

const validationSchemaSubCategory = Yup.object().shape({
  name: Yup.string(),
  categoryId: Yup.string().required("Required"),
  sortOrder: Yup.string().required("Required"),
  id: Yup.string(),
});

export default validationSchemaSubCategory;
