import { AppBar, Box, IconButton, Toolbar, Typography } from "@material-ui/core";
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import { clearJwtToken } from "../../utils/auth.utils";
import packageJson from '../../../package.json';

// import LogoIcon from "public/logo.svg";
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: theme.palette.primary.main,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  logoImage: {
    width: "50px",
    marginRight: theme.spacing(2),
  },
}));

interface IProps {
  open: boolean;
  handleDrawerOpen: () => void;
}

const Header = ({ open, handleDrawerOpen }: IProps) => {
  const classes = useStyles();
  let history = useHistory();

  const handleLogout = () => {
    clearJwtToken();
    history.push("/login");
  };
  return (
    <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <Box display="flex" justifyContent="space-between" width="100%" alignItems="baseline">
          <Link to="/">
            <img src="/logo.svg" alt="company logo" className={classes.logoImage} />
          </Link>
          {packageJson.version}
          <Box display="flex" alignItems="center">
            <PersonIcon />
            <Typography component="h1" variant="h6" color="inherit" noWrap>
              Admin User
            </Typography>
            <Box ml={2}>
              <IconButton onClick={handleLogout}>
                <ExitToAppIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
