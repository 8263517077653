import { Detail, Paginated } from "./Pagination";
import { BaseModel, DefaultExclusions, Without } from "./Utils";

export type Order = BaseModel & {
  buyerName?: string;
  buyerUserId?: string;
  createdAt?: string;
  deliveryPrice?: number;
  discountAmount?: string;
  discountCode?: string;
  itemCount: number;
  orderAmountBrutto?: string;
  orderBillingAddressId: string;
  orderNumber: string;
  orderShippingAddressId: string;
  orderState: string;
  paymentMethodId: string;
  paymentType: string;
  productDescription?: string;
  productImage?: string;
  productName?: string;
  sellerName?: string;
  sellerUserId: string;
  taxAmount: string;
  trackingCompany?: string;
  trackingId?: string;
};

export type Orders = Paginated<Order>;

export type OrderDetail = Detail<Order>;

export type UpdateOrder = Without<Partial<Order>, DefaultExclusions>;

export const OrderStates: { [key: string]: string } = {
  waiting_for_dispatch: "Waiting for Dispatch",
  payment_failed: "Payment Failed",
  dispatched: "Dispatched",
  delivered: "Delivered",
  completed: "Completed",
  refund: "Refund",
  created: "Created",
  cancelled: "Cancelled",
};

export enum OrderState {
  WaitingForDispatch = "waiting_for_dispatch",
  PaymentFailed = "payment_failed",
  Dispatched = "dispatched",
  Delivered = "delivered",
  Completed = "completed",
  Refund = "refund",
  Created = "created",
  Cancelled = "cancelled",
}
