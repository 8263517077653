import React, { useEffect } from "react";
import {Box, Button, ButtonGroup, Grid, Typography} from "@material-ui/core";
import {RequestQueryBuilder} from "@oktein/crud-request";
import {useMutation, useQuery} from "react-query";
import {FormDialog } from "../../components";
import {useServicesStore} from "../../hooks"; 
import  SellerAnalyticsForm from "./form";
import {toast} from "react-toastify";
import { DataGrid, GridColDef } from '@material-ui/data-grid'; 
import { SellerAnalytic, UpdateSellerAnalytic, CreateSellerAnalytic, MultiSend } from '../../types/SellerAnalytic';
 
const qb = RequestQueryBuilder.create({page: 1, limit: 1000});

export interface EditValues {
    id: string;
    body: UpdateSellerAnalytic;
}

const SellerAnalytics = () => {
    const { sellerAnalyticService } = useServicesStore();
    const [openCreateOrEditDialog, setOpenCreateOrEditDialog] = React.useState(false);
    const [dialogMode, setDialogMode] = React.useState("create");
    const [selectedRow, setSelectedRow] = React.useState<SellerAnalytic>(); 

    const { data, isLoading, refetch } = useQuery("sellerAnalytics", () => sellerAnalyticService.getSellerAvailableUser(qb), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
    const {meta, items} = data || {};
    const [page] = React.useState(1);
    const [pageSize] = React.useState(1000);

    
    const [rowCountState, setRowCountState] = React.useState(meta?.totalItems || 0);
    React.useEffect(() => {
      setRowCountState((prevRowCountState: any) =>
        meta?.totalItems !== undefined ? meta?.totalItems : prevRowCountState,
      );
    }, [meta?.totalItems, setRowCountState]);
  

    const { mutate: multiSend } = useMutation(
        (body: MultiSend) => sellerAnalyticService.multiSendEmail(body), {
        onSuccess: () => {
            console.log(selectionModel);
            toast.success("Email successfully schuled!");
            refetch();
        },
        onError: () => {
            toast.error("Something went wrong, please try again!");
        },
    });
    const { mutate: createSeller } = useMutation(
        (body: CreateSellerAnalytic) => sellerAnalyticService.create(body), {
        onSuccess: () => {
                console.log(selectionModel);
            toast.success("Email successfully schuled!");
            refetch();
        },
        onError: () => {
            toast.error("Something went wrong, please try again!");
        },
    });

    const { mutate: updateSeller } = useMutation(
        (values: EditValues) => sellerAnalyticService.update(values.id, values.body),
        {
            onSuccess: () => {
                toast.success("Email successfully updated!");
                refetch();
            },
            onError: () => {
                toast.error("Something went wrong, please try again!");
            },
        },
    ); 
 
    const columns: GridColDef[] = [
        {
            field: "firstName",
            headerName: "First Name",
            width: 150,
            renderCell: prm => {
                return <div style={{ wordBreak: "break-all" }}>{prm.row.firstName}</div>;
            },
        },
        {
            field: "lastName",
            headerName: "Last Name",
            width: 130,
            renderCell: prm => {
                return <div style={{ wordBreak: "break-all" }}>{prm.row.lastName}</div>
            },
        },
        {
            field: "email",
            headerName: "Email",
            width: 130,
            renderCell: prm => {
                return <div style={{ wordBreak: "break-all" }}>{prm.row.email}</div>
            },
        },
        {
            field: "userName",
            headerName: "User Name",
            width: 160,
            renderCell: prm => {
                return <div style={{ wordBreak: "break-all" }}>{prm.row.userName}</div>
            },
        },
        {
            field: "weekly",
            headerName: "Weekly",
            width: 130,
            renderCell: prm => {
                return <div style={{ wordBreak: "break-all" }}>{prm.row.weekly}</div>
            },
        },
        {
            field: "monthly",
            headerName: "Monthly",
            width: 130,
            renderCell: prm => {
                return <div style={{ wordBreak: "break-all" }}>{prm.row.monthly}</div>
            },
        },
        {
            field: "followerCount",
            headerName: "FollowerCount",
            width: 130,
            renderCell: prm => {
                return <div style={{ wordBreak: "break-all" }}>{prm.row.followerCount}</div>
            },
        },


         
        {
            field: "actions",
            headerName: "Actions",
            width: 150,
            renderCell: prm => {
              return <div style={{wordBreak: "break-all"}}>
                  <ButtonGroup color="secondary" aria-label="outlined secondary button group">
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                                setDialogMode("update");
                                setSelectedRow(prm.row as SellerAnalytic);
                                setOpenCreateOrEditDialog(true);
                            }}
                        >
                            Send 
                        </Button>
                        
                    </ButtonGroup>
                    </div>
            },
        },
        {
            field: "info",
            headerName: "Info",
            width: 230,
            renderCell: prm => {
                return <div style={{ wordBreak: "break-all" }}>{prm.row.info}</div>
            },
        },
    ]; 

    const [selectionModel, setSelectionModel] = React.useState<any[]>([]);
    const [selectedItem, setSelectionItem] = React.useState<any[]>([]);
    useEffect(() => {
        qb.setPage(page).setLimit(pageSize);
        refetch();
      }, [pageSize, page, refetch]);

    const handleSelectionModelChange=(newSelection: any[]) => {
        const find = items.filter((item: any) => newSelection.includes(item.id));
        setSelectionItem(find);
        setSelectionModel(newSelection);
    }
    
    return (
        <div>
            <FormDialog
                open={openCreateOrEditDialog}
                onClose={() => {
                    setDialogMode("create");
                    setSelectedRow({} as SellerAnalytic);
                }}
            >
                {dialogMode === "create"
                    ? "Are you sure you want to send this email to the selected recipients?"
                    : "Are you sure you want to send this email?"
                }

                <SellerAnalyticsForm
                    createSeller={createSeller}
                    multiSendFunc={multiSend}
                    updateSeller={updateSeller}
                    dialogMode={dialogMode}
                    selectedRows={selectedItem}
                    selectedRow={selectedRow as SellerAnalytic}
                    openDialog={setOpenCreateOrEditDialog}
                    newRow={dialogMode === "create"}
                />
            </FormDialog>
            <Box display="flex" justifyContent="space-between" mb={2} onClick={() => setOpenCreateOrEditDialog(true)}>
                <Typography variant="h5">Seller analytics email</Typography>
                <Button color="secondary" variant="contained">
                    Send Email
                </Button>
            </Box>
            
            {!isLoading && (
                <div style={{ height: "2600px", width: "auto" }}>
                     <Grid>
                <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                </Grid>
            </Grid>
                <DataGrid
                    onSelectionModelChange={handleSelectionModelChange}
                    selectionModel={selectionModel}
                    checkboxSelection
                    rows={items}
                    rowCount={rowCountState}
                    loading={isLoading}
                    rowsPerPageOptions={[2000]}
                    columns={columns}
                  />
                  </div>
            )}
        </div>
    );
};

export default SellerAnalytics;
