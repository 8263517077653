import { Button, DialogActions, makeStyles, MenuItem, TextField } from "@material-ui/core";
import { Formik } from "formik";
import { UseMutateFunction } from "react-query/types/react/types";
import { EditValues } from "..";
import { Categories } from "../../../types";
import { SubCategory, CreateSubCategory } from "../../../types";
import validationSchemaSubCategory from "../../../utils/validationSchemaSubCategory";

const useStyles = makeStyles(theme => ({
  cancelButton: {
    textTransform: "none",
  },
  saveButton: {
    fontSize: "1.1em",
    paddingLeft: "1.2em",
    paddingRight: "1.2em",
    textTransform: "none",
  },
}));
interface IProps {
  createSubCategory: UseMutateFunction<SubCategory, unknown, CreateSubCategory, unknown>;
  updateSubCategory: UseMutateFunction<SubCategory, unknown, EditValues, unknown>;
  dialogMode: string;
  categoryData?: Categories;
  selectedRow: SubCategory;
  openDialog: React.Dispatch<React.SetStateAction<boolean>>;
}
const AddOrCreateSubCategoryForm = ({
  createSubCategory,
  updateSubCategory,
  dialogMode,
  selectedRow,
  categoryData,
  openDialog,
}: IProps) => {
  const initialValues = selectedRow?.id
    ? {
        name: selectedRow.name,
        sortOrder: selectedRow.sortOrder,
        categoryId: selectedRow?.productCategory?.id,
      }
    : { name: "", sortOrder: 1, categoryId: "" };
  const cs = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaSubCategory}
      validateOnMount
      onSubmit={values => {
        dialogMode === "create" ? createSubCategory(values) : updateSubCategory({ id: selectedRow.id, body: values });
        openDialog(false);
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Sub Category Name"
            name="name"
            color="secondary"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            select
            label="Category Name"
            name="categoryId"
            color="secondary"
            value={values.categoryId}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            {categoryData?.items.map(option => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Sort Order"
            name="sortOrder"
            color="secondary"
            type="number"
            value={values.sortOrder}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <DialogActions>
            <Button onClick={() => openDialog(false)} color="default" className={cs.cancelButton}>
              Cancel
            </Button>
            <Button
              className={cs.saveButton}
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting || !isValid || !dirty}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      )}
    </Formik>
  );
};

export default AddOrCreateSubCategoryForm;
