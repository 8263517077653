import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

interface IProps {
  children: any;
  title?: string;
  open: boolean;
  onClose?: () => void;
}
const FormDialog = ({ open, children, title, onClose }: IProps) => {
  return (
    <Dialog TransitionProps={{ onExit: onClose }} open={open}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default FormDialog;
