import { Button, DialogActions, makeStyles, TextField } from "@material-ui/core";
import { Formik } from "formik";
import { UseMutateFunction } from "react-query/types/react/types";
import { EditValues } from "..";
import { City, CreateCity } from "../../../types/City";
import validationSchemaMasterData from "../../../utils/validationSchemaMasterData";

const useStyles = makeStyles(theme => ({
  cancelButton: {
    textTransform: "none",
  },
  saveButton: {
    fontSize: "1.1em",
    paddingLeft: "1.2em",
    paddingRight: "1.2em",
    textTransform: "none",
  },
}));
interface IProps {
  createCity: UseMutateFunction<City, unknown, CreateCity, unknown>;
  updateCity: UseMutateFunction<City, unknown, EditValues, unknown>;
  dialogMode: string;
  selectedRow: City;
  openDialog: React.Dispatch<React.SetStateAction<boolean>>;
}
const AddOrCreateCityForm = ({ createCity, updateCity, dialogMode, selectedRow, openDialog }: IProps) => {
  const initialValues = selectedRow?.id
    ? { name: selectedRow.name, sortOrder: selectedRow.sortOrder }
    : { name: "", sortOrder: 1 };
  const cs = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaMasterData}
      validateOnMount
      onSubmit={values => {
        dialogMode === "create" ? createCity(values) : updateCity({ id: selectedRow.id, body: values });
        openDialog(false);
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="City Name"
            name="name"
            color="secondary"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Sort Order"
            name="sortOrder"
            color="secondary"
            type="number"
            value={values.sortOrder}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <DialogActions>
            <Button onClick={() => openDialog(false)} color="default" className={cs.cancelButton}>
              Cancel
            </Button>
            <Button
              className={cs.saveButton}
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting || !isValid || !dirty}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      )}
    </Formik>
  );
};

export default AddOrCreateCityForm;
