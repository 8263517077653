import React from "react";
import { Button, Grid } from "@material-ui/core";
import { Formik } from "formik";
import { RequestQueryBuilder } from "@oktein/crud-request";
import { SelectInputField } from "../../../../components";
import { useServicesStore } from "../../../../hooks";
import { Users } from "../../../../types";

const UserForm = ({
  onSubmit,
}: {
  onSubmit: ({ userId, sortOrder }: { userId: string; sortOrder: number }) => void;
}) => {
  const { userService } = useServicesStore();
  return (
    <Formik
      initialValues={{ user: null }}
      onSubmit={(values: any, { setSubmitting, resetForm }) => {
        setSubmitting(false);
        onSubmit({ userId: values.user.value, sortOrder: 1 });
        resetForm();
      }}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} style={{ marginBottom: "8px" }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SelectInputField
                name="user"
                queryKey="user"
                queryFunction={(qb: RequestQueryBuilder) => userService.getUsers(qb)}
                //queryFilter={val => ({ $or: [{ userName: { $cont: val } }, { email: { $cont: val } }] })}
                queryFilter={val => ({ $or: [{ userName: { $cont: val } }] }) }
                selectProps={{ isMulti: false, placeholder: "Select User" }}
                selectFunction={(data: Users) => {
                  const { items = [] } = data || {};
                  const result = items.map(item => ({
                    label: `${item.firstName} ${item.lastName} (${item.userName})`,
                    value: item.id,
                    item,
                  }));
                  return result;
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" type="submit" color="primary" disabled={!values.user}>
                Add
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default UserForm;
