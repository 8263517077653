import { ApiClient } from "./api-client.service";
import { RequestQueryBuilder } from "@oktein/crud-request";
import { Rating,Ratings } from "../types";
import { AxiosResponse } from "axios";

export class RatingService extends ApiClient {
    async getRatings(queryBuilder: RequestQueryBuilder): Promise<Ratings> {
        const result: AxiosResponse<Ratings> = await this.get(`/ratings-admin?${queryBuilder.query(false).replace("sort[0]","sort")}`);
        const ratingsData = result.data;
        ratingsData.items = ratingsData.items.filter(item => (item.rated && item.rater))
        return result.data;
    }
    
    async createRating(body: unknown): Promise<Rating> {
        const result: AxiosResponse<Rating> = await this.post('/ratings-admin', body);
        return result.data;
    }
    
    async updateRating(id: string, body: unknown): Promise<Rating> {
        const result: AxiosResponse<Rating> = await this.patch(`/ratings-admin/${id}`, body);
        return result.data;
    }
    
    async replaceRating(id: string, body: unknown): Promise<Rating> {
        const result: AxiosResponse<Rating> = await this.put(`/ratings-admin${id}`, body);
        return result.data;
    }
    
    async deleteRating(id: string): Promise<void> {
        const result: AxiosResponse<void> = await this.delete(`/ratings-admin/${id}`);
        return result.data;
    }
}
