import { ApiClient } from "./api-client.service";
import { AxiosResponse } from "axios";
import { AdminUser, AdminUsers, CreateAdminUser, UpdateAdminUser, AdminRoles } from "../types";
import { RequestQueryBuilder } from "@oktein/crud-request";
import { Role, UserRole } from "../types/Role";

export class AdminUserService extends ApiClient {
  async getAdminUsers(queryBuilder: RequestQueryBuilder): Promise<AdminUsers> {
    const result: AxiosResponse = await this.get(`/adminusers?${queryBuilder.query(false).replace("sort[0]","sort")}`);
    return result.data;
  }

  async createAdminUser(body: CreateAdminUser): Promise<AdminUser> {
    const result: AxiosResponse = await this.post("/adminusers", body);
    return result.data;
  }

  async updateAdminUser(id: string, body: UpdateAdminUser): Promise<AdminUser> {
    const result: AxiosResponse<AdminUser> = await this.patch(`/adminusers/${id}`, body);
    return result.data;
  }

  async deleteAdminUser(id: string): Promise<void> {
    const result: AxiosResponse<void> = await this.delete(`/adminusers/${id}`);
    return result.data;
  }

  async getAdminRoles(qb: RequestQueryBuilder): Promise<AdminRoles> {
    const result: AxiosResponse<AdminRoles> = await this.get(`/adminusers/userRoles?${qb.query(false)}`);
    return result.data;
  }
  async getUsers(qb: RequestQueryBuilder): Promise<AdminRoles> {
    const result: AxiosResponse<AdminRoles> = await this.get(`/adminusers/users?${qb.query(false)}`);
    return result.data;
  }
  async getRoles(qb: RequestQueryBuilder): Promise<Role> {
    const result: AxiosResponse<Role> = await this.get(`/adminusers/roles?${qb.query(false)}`);
    return result.data;
  }

  async saveUserRole(body: { userId: string; roleId: string }): Promise<UserRole> {
    const result: AxiosResponse<UserRole> = await this.post("/adminusers/userRole", body);
    return result.data;
  }

  async getUserRole(qb: RequestQueryBuilder): Promise<UserRole> {
    if(qb.queryObject.page === 0){
      qb.setPage(1);
    }
    const result: AxiosResponse<UserRole> = await this.get(`/adminusers/rolesUsers?${qb.query(false)}`);
    return result.data;
  }
  async deleteIdUserRole(id: string): Promise<UserRole> {
    const result: AxiosResponse<UserRole> = await this.delete(`/adminusers/rolesUsers/${id}`);
    return result.data;
  }
  
  async approveUserRole(id: string): Promise<UserRole> {
    const result: AxiosResponse<UserRole> = await this.get(`/adminusers/rolesUsers/approve/${id}`);
    return result.data;
  }
  async rejectUserRole(id: string): Promise<UserRole> {
    const result: AxiosResponse<UserRole> = await this.get(`/adminusers/rolesUsers/reject/${id}`);
    return result.data;
  }
  
}
