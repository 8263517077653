// /products-admin
import { ApiClient } from "./api-client.service";
import { AxiosResponse } from "axios";
import { RequestQueryBuilder } from "@oktein/crud-request";
import { ProductDetail, Products } from "../types";

export class ProductService extends ApiClient {
  async getTags(qb: RequestQueryBuilder): Promise<any> {
    const result: AxiosResponse<Products> = await this.get(`/feed-product-curation/tags?${qb.query(false).replace("sort[0]","sort")}`);
    return result.data;
  }
  async getSizesWithCat(category: any[]): Promise<any> {
    const result: AxiosResponse<Products> = await this.post(`/feed-product-curation/sizes`, {categoryIds:category.map(item=>item.value)});
    return result.data;
  }

  async getSubCategories(category: any[]): Promise<any> {
    const result: AxiosResponse<Products> = await this.post(`/feed-product-curation/subCategories`, {categoryIds:category.map(item=>item.value)});
    return result.data;
  }
  
  async getSizes(queryBuilder: RequestQueryBuilder,filter: any): Promise<any> {
    const result: AxiosResponse<Products> = await this.get(`/feed-product-curation/sizes?${queryBuilder.query(false).replace("sort[0]","sort")}`);
    return {items:result.data};
  }
  async getProducts(queryBuilder: RequestQueryBuilder): Promise<Products> {
    const result: AxiosResponse<Products> = await this.get(`/products-admin?${queryBuilder.query(false).replace("sort[0]","sort")}`);
    return result.data;
  }
  async getProductsFeed(queryBuilder: RequestQueryBuilder,filterForm: any): Promise<Products> {
    const result: AxiosResponse<Products> = await this.post(`/products-feed-admin/newFilter?${queryBuilder.query(false).replace("sort[0]","sort")}`,filterForm);
    return result.data;
  }
  
  async getProduct(id: string): Promise<ProductDetail> {
    const result: AxiosResponse<ProductDetail> = await this.get(`/products-admin/${id}`);
    return result.data;
  }

  async deactivateProduct(id: string): Promise<ProductDetail> {
    const result: AxiosResponse<ProductDetail> = await this.post(`/products-admin/deactivate/${id}`);
    return result.data;
  }
}
