import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
  },
  cancelButton: {
    textTransform: "none",
  },
  saveButton: {
    fontSize: "1.1em",
    paddingLeft: "1.2em",
    paddingRight: "1.2em",
    textTransform: "none",
  },
}));

interface IProps {
  children: any;
  title?: string;
  confirmText?: string;
  cancelText?: string;
  open: boolean;
  onClose: () => void;
  onSave: () => void;
}

const ConfirmDialog = ({
  onSave,
  onClose,
  children,
  title,
  confirmText = "Save",
  cancelText = "Cancel",
  open,
}: IProps) => {
  const cs = useStyles();
  return (
    <Dialog open={open}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      <DialogActions classes={{ root: cs.root }}>
        <Button
          className={cs.cancelButton}
          onClick={() => {
            onClose();
          }}
          color="default"
        >
          {cancelText}
        </Button>
        <Button className={cs.saveButton} onClick={onSave} variant="contained" color="primary">
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
